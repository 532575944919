.cb-widget .container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 768px) {
  .cb-widget .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (min-width: 1024px) {
  .cb-widget .container {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (min-width: 1250px) {
  .cb-widget .container {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (min-width: 768px) {
  .cb-widget .container {
    width: 752px;
  }
}
@media (min-width: 1024px) {
  .cb-widget .container {
    width: 990px;
  }
}
@media (min-width: 1250px) {
  .cb-widget .container {
    width: 1206px;
  }
}
.cb-widget .container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 768px) {
  .cb-widget .container-fluid {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (min-width: 1024px) {
  .cb-widget .container-fluid {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (min-width: 1250px) {
  .cb-widget .container-fluid {
    padding-left: 30px;
    padding-right: 30px;
  }
}
.cb-widget .row {
  margin-left: -7.5px;
  margin-right: -7.5px;
}
@media (min-width: 768px) {
  .cb-widget .row {
    margin-left: -10px;
    margin-right: -10px;
  }
}
@media (min-width: 1024px) {
  .cb-widget .row {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media (min-width: 1250px) {
  .cb-widget .row {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.cb-widget .col-xs-1,
.cb-widget .col-sm-1,
.cb-widget .col-md-1,
.cb-widget .col-lg-1,
.cb-widget .col-xs-2,
.cb-widget .col-sm-2,
.cb-widget .col-md-2,
.cb-widget .col-lg-2,
.cb-widget .col-xs-3,
.cb-widget .col-sm-3,
.cb-widget .col-md-3,
.cb-widget .col-lg-3,
.cb-widget .col-xs-4,
.cb-widget .col-sm-4,
.cb-widget .col-md-4,
.cb-widget .col-lg-4,
.cb-widget .col-xs-5,
.cb-widget .col-sm-5,
.cb-widget .col-md-5,
.cb-widget .col-lg-5,
.cb-widget .col-xs-6,
.cb-widget .col-sm-6,
.cb-widget .col-md-6,
.cb-widget .col-lg-6,
.cb-widget .col-xs-7,
.cb-widget .col-sm-7,
.cb-widget .col-md-7,
.cb-widget .col-lg-7,
.cb-widget .col-xs-8,
.cb-widget .col-sm-8,
.cb-widget .col-md-8,
.cb-widget .col-lg-8,
.cb-widget .col-xs-9,
.cb-widget .col-sm-9,
.cb-widget .col-md-9,
.cb-widget .col-lg-9,
.cb-widget .col-xs-10,
.cb-widget .col-sm-10,
.cb-widget .col-md-10,
.cb-widget .col-lg-10,
.cb-widget .col-xs-11,
.cb-widget .col-sm-11,
.cb-widget .col-md-11,
.cb-widget .col-lg-11,
.cb-widget .col-xs-12,
.cb-widget .col-sm-12,
.cb-widget .col-md-12,
.cb-widget .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 7.5px;
  padding-right: 7.5px;
}
@media (min-width: 768px) {
  .cb-widget .col-xs-1,
  .cb-widget .col-sm-1,
  .cb-widget .col-md-1,
  .cb-widget .col-lg-1,
  .cb-widget .col-xs-2,
  .cb-widget .col-sm-2,
  .cb-widget .col-md-2,
  .cb-widget .col-lg-2,
  .cb-widget .col-xs-3,
  .cb-widget .col-sm-3,
  .cb-widget .col-md-3,
  .cb-widget .col-lg-3,
  .cb-widget .col-xs-4,
  .cb-widget .col-sm-4,
  .cb-widget .col-md-4,
  .cb-widget .col-lg-4,
  .cb-widget .col-xs-5,
  .cb-widget .col-sm-5,
  .cb-widget .col-md-5,
  .cb-widget .col-lg-5,
  .cb-widget .col-xs-6,
  .cb-widget .col-sm-6,
  .cb-widget .col-md-6,
  .cb-widget .col-lg-6,
  .cb-widget .col-xs-7,
  .cb-widget .col-sm-7,
  .cb-widget .col-md-7,
  .cb-widget .col-lg-7,
  .cb-widget .col-xs-8,
  .cb-widget .col-sm-8,
  .cb-widget .col-md-8,
  .cb-widget .col-lg-8,
  .cb-widget .col-xs-9,
  .cb-widget .col-sm-9,
  .cb-widget .col-md-9,
  .cb-widget .col-lg-9,
  .cb-widget .col-xs-10,
  .cb-widget .col-sm-10,
  .cb-widget .col-md-10,
  .cb-widget .col-lg-10,
  .cb-widget .col-xs-11,
  .cb-widget .col-sm-11,
  .cb-widget .col-md-11,
  .cb-widget .col-lg-11,
  .cb-widget .col-xs-12,
  .cb-widget .col-sm-12,
  .cb-widget .col-md-12,
  .cb-widget .col-lg-12 {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 1024px) {
  .cb-widget .col-xs-1,
  .cb-widget .col-sm-1,
  .cb-widget .col-md-1,
  .cb-widget .col-lg-1,
  .cb-widget .col-xs-2,
  .cb-widget .col-sm-2,
  .cb-widget .col-md-2,
  .cb-widget .col-lg-2,
  .cb-widget .col-xs-3,
  .cb-widget .col-sm-3,
  .cb-widget .col-md-3,
  .cb-widget .col-lg-3,
  .cb-widget .col-xs-4,
  .cb-widget .col-sm-4,
  .cb-widget .col-md-4,
  .cb-widget .col-lg-4,
  .cb-widget .col-xs-5,
  .cb-widget .col-sm-5,
  .cb-widget .col-md-5,
  .cb-widget .col-lg-5,
  .cb-widget .col-xs-6,
  .cb-widget .col-sm-6,
  .cb-widget .col-md-6,
  .cb-widget .col-lg-6,
  .cb-widget .col-xs-7,
  .cb-widget .col-sm-7,
  .cb-widget .col-md-7,
  .cb-widget .col-lg-7,
  .cb-widget .col-xs-8,
  .cb-widget .col-sm-8,
  .cb-widget .col-md-8,
  .cb-widget .col-lg-8,
  .cb-widget .col-xs-9,
  .cb-widget .col-sm-9,
  .cb-widget .col-md-9,
  .cb-widget .col-lg-9,
  .cb-widget .col-xs-10,
  .cb-widget .col-sm-10,
  .cb-widget .col-md-10,
  .cb-widget .col-lg-10,
  .cb-widget .col-xs-11,
  .cb-widget .col-sm-11,
  .cb-widget .col-md-11,
  .cb-widget .col-lg-11,
  .cb-widget .col-xs-12,
  .cb-widget .col-sm-12,
  .cb-widget .col-md-12,
  .cb-widget .col-lg-12 {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (min-width: 1250px) {
  .cb-widget .col-xs-1,
  .cb-widget .col-sm-1,
  .cb-widget .col-md-1,
  .cb-widget .col-lg-1,
  .cb-widget .col-xs-2,
  .cb-widget .col-sm-2,
  .cb-widget .col-md-2,
  .cb-widget .col-lg-2,
  .cb-widget .col-xs-3,
  .cb-widget .col-sm-3,
  .cb-widget .col-md-3,
  .cb-widget .col-lg-3,
  .cb-widget .col-xs-4,
  .cb-widget .col-sm-4,
  .cb-widget .col-md-4,
  .cb-widget .col-lg-4,
  .cb-widget .col-xs-5,
  .cb-widget .col-sm-5,
  .cb-widget .col-md-5,
  .cb-widget .col-lg-5,
  .cb-widget .col-xs-6,
  .cb-widget .col-sm-6,
  .cb-widget .col-md-6,
  .cb-widget .col-lg-6,
  .cb-widget .col-xs-7,
  .cb-widget .col-sm-7,
  .cb-widget .col-md-7,
  .cb-widget .col-lg-7,
  .cb-widget .col-xs-8,
  .cb-widget .col-sm-8,
  .cb-widget .col-md-8,
  .cb-widget .col-lg-8,
  .cb-widget .col-xs-9,
  .cb-widget .col-sm-9,
  .cb-widget .col-md-9,
  .cb-widget .col-lg-9,
  .cb-widget .col-xs-10,
  .cb-widget .col-sm-10,
  .cb-widget .col-md-10,
  .cb-widget .col-lg-10,
  .cb-widget .col-xs-11,
  .cb-widget .col-sm-11,
  .cb-widget .col-md-11,
  .cb-widget .col-lg-11,
  .cb-widget .col-xs-12,
  .cb-widget .col-sm-12,
  .cb-widget .col-md-12,
  .cb-widget .col-lg-12 {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.cb-widget .col-xs-1,
.cb-widget .col-xs-2,
.cb-widget .col-xs-3,
.cb-widget .col-xs-4,
.cb-widget .col-xs-5,
.cb-widget .col-xs-6,
.cb-widget .col-xs-7,
.cb-widget .col-xs-8,
.cb-widget .col-xs-9,
.cb-widget .col-xs-10,
.cb-widget .col-xs-11,
.cb-widget .col-xs-12 {
  float: left;
}
.cb-widget .col-xs-12 {
  width: 100%;
}
.cb-widget .col-xs-11 {
  width: 91.66666667%;
}
.cb-widget .col-xs-10 {
  width: 83.33333333%;
}
.cb-widget .col-xs-9 {
  width: 75%;
}
.cb-widget .col-xs-8 {
  width: 66.66666667%;
}
.cb-widget .col-xs-7 {
  width: 58.33333333%;
}
.cb-widget .col-xs-6 {
  width: 50%;
}
.cb-widget .col-xs-5 {
  width: 41.66666667%;
}
.cb-widget .col-xs-4 {
  width: 33.33333333%;
}
.cb-widget .col-xs-3 {
  width: 25%;
}
.cb-widget .col-xs-2 {
  width: 16.66666667%;
}
.cb-widget .col-xs-1 {
  width: 8.33333333%;
}
.cb-widget .col-xs-pull-12 {
  right: 100%;
}
.cb-widget .col-xs-pull-11 {
  right: 91.66666667%;
}
.cb-widget .col-xs-pull-10 {
  right: 83.33333333%;
}
.cb-widget .col-xs-pull-9 {
  right: 75%;
}
.cb-widget .col-xs-pull-8 {
  right: 66.66666667%;
}
.cb-widget .col-xs-pull-7 {
  right: 58.33333333%;
}
.cb-widget .col-xs-pull-6 {
  right: 50%;
}
.cb-widget .col-xs-pull-5 {
  right: 41.66666667%;
}
.cb-widget .col-xs-pull-4 {
  right: 33.33333333%;
}
.cb-widget .col-xs-pull-3 {
  right: 25%;
}
.cb-widget .col-xs-pull-2 {
  right: 16.66666667%;
}
.cb-widget .col-xs-pull-1 {
  right: 8.33333333%;
}
.cb-widget .col-xs-pull-0 {
  right: auto;
}
.cb-widget .col-xs-push-12 {
  left: 100%;
}
.cb-widget .col-xs-push-11 {
  left: 91.66666667%;
}
.cb-widget .col-xs-push-10 {
  left: 83.33333333%;
}
.cb-widget .col-xs-push-9 {
  left: 75%;
}
.cb-widget .col-xs-push-8 {
  left: 66.66666667%;
}
.cb-widget .col-xs-push-7 {
  left: 58.33333333%;
}
.cb-widget .col-xs-push-6 {
  left: 50%;
}
.cb-widget .col-xs-push-5 {
  left: 41.66666667%;
}
.cb-widget .col-xs-push-4 {
  left: 33.33333333%;
}
.cb-widget .col-xs-push-3 {
  left: 25%;
}
.cb-widget .col-xs-push-2 {
  left: 16.66666667%;
}
.cb-widget .col-xs-push-1 {
  left: 8.33333333%;
}
.cb-widget .col-xs-push-0 {
  left: auto;
}
.cb-widget .col-xs-offset-12 {
  margin-left: 100%;
}
.cb-widget .col-xs-offset-11 {
  margin-left: 91.66666667%;
}
.cb-widget .col-xs-offset-10 {
  margin-left: 83.33333333%;
}
.cb-widget .col-xs-offset-9 {
  margin-left: 75%;
}
.cb-widget .col-xs-offset-8 {
  margin-left: 66.66666667%;
}
.cb-widget .col-xs-offset-7 {
  margin-left: 58.33333333%;
}
.cb-widget .col-xs-offset-6 {
  margin-left: 50%;
}
.cb-widget .col-xs-offset-5 {
  margin-left: 41.66666667%;
}
.cb-widget .col-xs-offset-4 {
  margin-left: 33.33333333%;
}
.cb-widget .col-xs-offset-3 {
  margin-left: 25%;
}
.cb-widget .col-xs-offset-2 {
  margin-left: 16.66666667%;
}
.cb-widget .col-xs-offset-1 {
  margin-left: 8.33333333%;
}
.cb-widget .col-xs-offset-0 {
  margin-left: 0%;
}
@media (min-width: 768px) {
  .cb-widget .col-sm-1,
  .cb-widget .col-sm-2,
  .cb-widget .col-sm-3,
  .cb-widget .col-sm-4,
  .cb-widget .col-sm-5,
  .cb-widget .col-sm-6,
  .cb-widget .col-sm-7,
  .cb-widget .col-sm-8,
  .cb-widget .col-sm-9,
  .cb-widget .col-sm-10,
  .cb-widget .col-sm-11,
  .cb-widget .col-sm-12 {
    float: left;
  }
  .cb-widget .col-sm-12 {
    width: 100%;
  }
  .cb-widget .col-sm-11 {
    width: 91.66666667%;
  }
  .cb-widget .col-sm-10 {
    width: 83.33333333%;
  }
  .cb-widget .col-sm-9 {
    width: 75%;
  }
  .cb-widget .col-sm-8 {
    width: 66.66666667%;
  }
  .cb-widget .col-sm-7 {
    width: 58.33333333%;
  }
  .cb-widget .col-sm-6 {
    width: 50%;
  }
  .cb-widget .col-sm-5 {
    width: 41.66666667%;
  }
  .cb-widget .col-sm-4 {
    width: 33.33333333%;
  }
  .cb-widget .col-sm-3 {
    width: 25%;
  }
  .cb-widget .col-sm-2 {
    width: 16.66666667%;
  }
  .cb-widget .col-sm-1 {
    width: 8.33333333%;
  }
  .cb-widget .col-sm-pull-12 {
    right: 100%;
  }
  .cb-widget .col-sm-pull-11 {
    right: 91.66666667%;
  }
  .cb-widget .col-sm-pull-10 {
    right: 83.33333333%;
  }
  .cb-widget .col-sm-pull-9 {
    right: 75%;
  }
  .cb-widget .col-sm-pull-8 {
    right: 66.66666667%;
  }
  .cb-widget .col-sm-pull-7 {
    right: 58.33333333%;
  }
  .cb-widget .col-sm-pull-6 {
    right: 50%;
  }
  .cb-widget .col-sm-pull-5 {
    right: 41.66666667%;
  }
  .cb-widget .col-sm-pull-4 {
    right: 33.33333333%;
  }
  .cb-widget .col-sm-pull-3 {
    right: 25%;
  }
  .cb-widget .col-sm-pull-2 {
    right: 16.66666667%;
  }
  .cb-widget .col-sm-pull-1 {
    right: 8.33333333%;
  }
  .cb-widget .col-sm-pull-0 {
    right: auto;
  }
  .cb-widget .col-sm-push-12 {
    left: 100%;
  }
  .cb-widget .col-sm-push-11 {
    left: 91.66666667%;
  }
  .cb-widget .col-sm-push-10 {
    left: 83.33333333%;
  }
  .cb-widget .col-sm-push-9 {
    left: 75%;
  }
  .cb-widget .col-sm-push-8 {
    left: 66.66666667%;
  }
  .cb-widget .col-sm-push-7 {
    left: 58.33333333%;
  }
  .cb-widget .col-sm-push-6 {
    left: 50%;
  }
  .cb-widget .col-sm-push-5 {
    left: 41.66666667%;
  }
  .cb-widget .col-sm-push-4 {
    left: 33.33333333%;
  }
  .cb-widget .col-sm-push-3 {
    left: 25%;
  }
  .cb-widget .col-sm-push-2 {
    left: 16.66666667%;
  }
  .cb-widget .col-sm-push-1 {
    left: 8.33333333%;
  }
  .cb-widget .col-sm-push-0 {
    left: auto;
  }
  .cb-widget .col-sm-offset-12 {
    margin-left: 100%;
  }
  .cb-widget .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .cb-widget .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .cb-widget .col-sm-offset-9 {
    margin-left: 75%;
  }
  .cb-widget .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .cb-widget .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .cb-widget .col-sm-offset-6 {
    margin-left: 50%;
  }
  .cb-widget .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .cb-widget .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .cb-widget .col-sm-offset-3 {
    margin-left: 25%;
  }
  .cb-widget .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .cb-widget .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .cb-widget .col-sm-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 1024px) {
  .cb-widget .col-md-1,
  .cb-widget .col-md-2,
  .cb-widget .col-md-3,
  .cb-widget .col-md-4,
  .cb-widget .col-md-5,
  .cb-widget .col-md-6,
  .cb-widget .col-md-7,
  .cb-widget .col-md-8,
  .cb-widget .col-md-9,
  .cb-widget .col-md-10,
  .cb-widget .col-md-11,
  .cb-widget .col-md-12 {
    float: left;
  }
  .cb-widget .col-md-12 {
    width: 100%;
  }
  .cb-widget .col-md-11 {
    width: 91.66666667%;
  }
  .cb-widget .col-md-10 {
    width: 83.33333333%;
  }
  .cb-widget .col-md-9 {
    width: 75%;
  }
  .cb-widget .col-md-8 {
    width: 66.66666667%;
  }
  .cb-widget .col-md-7 {
    width: 58.33333333%;
  }
  .cb-widget .col-md-6 {
    width: 50%;
  }
  .cb-widget .col-md-5 {
    width: 41.66666667%;
  }
  .cb-widget .col-md-4 {
    width: 33.33333333%;
  }
  .cb-widget .col-md-3 {
    width: 25%;
  }
  .cb-widget .col-md-2 {
    width: 16.66666667%;
  }
  .cb-widget .col-md-1 {
    width: 8.33333333%;
  }
  .cb-widget .col-md-pull-12 {
    right: 100%;
  }
  .cb-widget .col-md-pull-11 {
    right: 91.66666667%;
  }
  .cb-widget .col-md-pull-10 {
    right: 83.33333333%;
  }
  .cb-widget .col-md-pull-9 {
    right: 75%;
  }
  .cb-widget .col-md-pull-8 {
    right: 66.66666667%;
  }
  .cb-widget .col-md-pull-7 {
    right: 58.33333333%;
  }
  .cb-widget .col-md-pull-6 {
    right: 50%;
  }
  .cb-widget .col-md-pull-5 {
    right: 41.66666667%;
  }
  .cb-widget .col-md-pull-4 {
    right: 33.33333333%;
  }
  .cb-widget .col-md-pull-3 {
    right: 25%;
  }
  .cb-widget .col-md-pull-2 {
    right: 16.66666667%;
  }
  .cb-widget .col-md-pull-1 {
    right: 8.33333333%;
  }
  .cb-widget .col-md-pull-0 {
    right: auto;
  }
  .cb-widget .col-md-push-12 {
    left: 100%;
  }
  .cb-widget .col-md-push-11 {
    left: 91.66666667%;
  }
  .cb-widget .col-md-push-10 {
    left: 83.33333333%;
  }
  .cb-widget .col-md-push-9 {
    left: 75%;
  }
  .cb-widget .col-md-push-8 {
    left: 66.66666667%;
  }
  .cb-widget .col-md-push-7 {
    left: 58.33333333%;
  }
  .cb-widget .col-md-push-6 {
    left: 50%;
  }
  .cb-widget .col-md-push-5 {
    left: 41.66666667%;
  }
  .cb-widget .col-md-push-4 {
    left: 33.33333333%;
  }
  .cb-widget .col-md-push-3 {
    left: 25%;
  }
  .cb-widget .col-md-push-2 {
    left: 16.66666667%;
  }
  .cb-widget .col-md-push-1 {
    left: 8.33333333%;
  }
  .cb-widget .col-md-push-0 {
    left: auto;
  }
  .cb-widget .col-md-offset-12 {
    margin-left: 100%;
  }
  .cb-widget .col-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .cb-widget .col-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .cb-widget .col-md-offset-9 {
    margin-left: 75%;
  }
  .cb-widget .col-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .cb-widget .col-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .cb-widget .col-md-offset-6 {
    margin-left: 50%;
  }
  .cb-widget .col-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .cb-widget .col-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .cb-widget .col-md-offset-3 {
    margin-left: 25%;
  }
  .cb-widget .col-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .cb-widget .col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .cb-widget .col-md-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 1250px) {
  .cb-widget .col-lg-1,
  .cb-widget .col-lg-2,
  .cb-widget .col-lg-3,
  .cb-widget .col-lg-4,
  .cb-widget .col-lg-5,
  .cb-widget .col-lg-6,
  .cb-widget .col-lg-7,
  .cb-widget .col-lg-8,
  .cb-widget .col-lg-9,
  .cb-widget .col-lg-10,
  .cb-widget .col-lg-11,
  .cb-widget .col-lg-12 {
    float: left;
  }
  .cb-widget .col-lg-12 {
    width: 100%;
  }
  .cb-widget .col-lg-11 {
    width: 91.66666667%;
  }
  .cb-widget .col-lg-10 {
    width: 83.33333333%;
  }
  .cb-widget .col-lg-9 {
    width: 75%;
  }
  .cb-widget .col-lg-8 {
    width: 66.66666667%;
  }
  .cb-widget .col-lg-7 {
    width: 58.33333333%;
  }
  .cb-widget .col-lg-6 {
    width: 50%;
  }
  .cb-widget .col-lg-5 {
    width: 41.66666667%;
  }
  .cb-widget .col-lg-4 {
    width: 33.33333333%;
  }
  .cb-widget .col-lg-3 {
    width: 25%;
  }
  .cb-widget .col-lg-2 {
    width: 16.66666667%;
  }
  .cb-widget .col-lg-1 {
    width: 8.33333333%;
  }
  .cb-widget .col-lg-pull-12 {
    right: 100%;
  }
  .cb-widget .col-lg-pull-11 {
    right: 91.66666667%;
  }
  .cb-widget .col-lg-pull-10 {
    right: 83.33333333%;
  }
  .cb-widget .col-lg-pull-9 {
    right: 75%;
  }
  .cb-widget .col-lg-pull-8 {
    right: 66.66666667%;
  }
  .cb-widget .col-lg-pull-7 {
    right: 58.33333333%;
  }
  .cb-widget .col-lg-pull-6 {
    right: 50%;
  }
  .cb-widget .col-lg-pull-5 {
    right: 41.66666667%;
  }
  .cb-widget .col-lg-pull-4 {
    right: 33.33333333%;
  }
  .cb-widget .col-lg-pull-3 {
    right: 25%;
  }
  .cb-widget .col-lg-pull-2 {
    right: 16.66666667%;
  }
  .cb-widget .col-lg-pull-1 {
    right: 8.33333333%;
  }
  .cb-widget .col-lg-pull-0 {
    right: auto;
  }
  .cb-widget .col-lg-push-12 {
    left: 100%;
  }
  .cb-widget .col-lg-push-11 {
    left: 91.66666667%;
  }
  .cb-widget .col-lg-push-10 {
    left: 83.33333333%;
  }
  .cb-widget .col-lg-push-9 {
    left: 75%;
  }
  .cb-widget .col-lg-push-8 {
    left: 66.66666667%;
  }
  .cb-widget .col-lg-push-7 {
    left: 58.33333333%;
  }
  .cb-widget .col-lg-push-6 {
    left: 50%;
  }
  .cb-widget .col-lg-push-5 {
    left: 41.66666667%;
  }
  .cb-widget .col-lg-push-4 {
    left: 33.33333333%;
  }
  .cb-widget .col-lg-push-3 {
    left: 25%;
  }
  .cb-widget .col-lg-push-2 {
    left: 16.66666667%;
  }
  .cb-widget .col-lg-push-1 {
    left: 8.33333333%;
  }
  .cb-widget .col-lg-push-0 {
    left: auto;
  }
  .cb-widget .col-lg-offset-12 {
    margin-left: 100%;
  }
  .cb-widget .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .cb-widget .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .cb-widget .col-lg-offset-9 {
    margin-left: 75%;
  }
  .cb-widget .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .cb-widget .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .cb-widget .col-lg-offset-6 {
    margin-left: 50%;
  }
  .cb-widget .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .cb-widget .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .cb-widget .col-lg-offset-3 {
    margin-left: 25%;
  }
  .cb-widget .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .cb-widget .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .cb-widget .col-lg-offset-0 {
    margin-left: 0%;
  }
}
.cb-widget .clearfix:before,
.cb-widget .clearfix:after,
.cb-widget .container:before,
.cb-widget .container:after,
.cb-widget .container-fluid:before,
.cb-widget .container-fluid:after,
.cb-widget .row:before,
.cb-widget .row:after,
.cb-widget .dl-horizontal dd:before,
.cb-widget .dl-horizontal dd:after,
.cb-widget .form-horizontal .form-group:before,
.cb-widget .form-horizontal .form-group:after,
.cb-widget .btn-toolbar:before,
.cb-widget .btn-toolbar:after,
.cb-widget .btn-group-vertical > .btn-group:before,
.cb-widget .btn-group-vertical > .btn-group:after {
  content: " ";
  display: table;
}
.cb-widget .clearfix:after,
.cb-widget .container:after,
.cb-widget .container-fluid:after,
.cb-widget .row:after,
.cb-widget .dl-horizontal dd:after,
.cb-widget .form-horizontal .form-group:after,
.cb-widget .btn-toolbar:after,
.cb-widget .btn-group-vertical > .btn-group:after {
  clear: both;
}
.cb-widget .center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.cb-widget .pull-right {
  float: right !important;
}
.cb-widget .pull-left {
  float: left !important;
}
.cb-widget .hide {
  display: none !important;
}
.cb-widget .show {
  display: block !important;
}
.cb-widget .invisible {
  visibility: hidden;
}
.cb-widget .text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.cb-widget .hidden {
  display: none !important;
}
.cb-widget .affix {
  position: fixed;
}
@-ms-viewport {
  width: device-width;
}
.cb-widget .visible-xs,
.cb-widget .visible-sm,
.cb-widget .visible-md,
.cb-widget .visible-lg {
  display: none !important;
}
.cb-widget .visible-xs-block,
.cb-widget .visible-xs-inline,
.cb-widget .visible-xs-inline-block,
.cb-widget .visible-sm-block,
.cb-widget .visible-sm-inline,
.cb-widget .visible-sm-inline-block,
.cb-widget .visible-md-block,
.cb-widget .visible-md-inline,
.cb-widget .visible-md-inline-block,
.cb-widget .visible-lg-block,
.cb-widget .visible-lg-inline,
.cb-widget .visible-lg-inline-block {
  display: none !important;
}
@media (max-width: 767px) {
  .cb-widget .visible-xs {
    display: block !important;
  }
  .cb-widget table.visible-xs {
    display: table !important;
  }
  .cb-widget tr.visible-xs {
    display: table-row !important;
  }
  .cb-widget th.visible-xs,
  .cb-widget td.visible-xs {
    display: table-cell !important;
  }
}
@media (max-width: 767px) {
  .cb-widget .visible-xs-block {
    display: block !important;
  }
}
@media (max-width: 767px) {
  .cb-widget .visible-xs-inline {
    display: inline !important;
  }
}
@media (max-width: 767px) {
  .cb-widget .visible-xs-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .cb-widget .visible-sm {
    display: block !important;
  }
  .cb-widget table.visible-sm {
    display: table !important;
  }
  .cb-widget tr.visible-sm {
    display: table-row !important;
  }
  .cb-widget th.visible-sm,
  .cb-widget td.visible-sm {
    display: table-cell !important;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .cb-widget .visible-sm-block {
    display: block !important;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .cb-widget .visible-sm-inline {
    display: inline !important;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .cb-widget .visible-sm-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 1024px) and (max-width: 1249px) {
  .cb-widget .visible-md {
    display: block !important;
  }
  .cb-widget table.visible-md {
    display: table !important;
  }
  .cb-widget tr.visible-md {
    display: table-row !important;
  }
  .cb-widget th.visible-md,
  .cb-widget td.visible-md {
    display: table-cell !important;
  }
}
@media (min-width: 1024px) and (max-width: 1249px) {
  .cb-widget .visible-md-block {
    display: block !important;
  }
}
@media (min-width: 1024px) and (max-width: 1249px) {
  .cb-widget .visible-md-inline {
    display: inline !important;
  }
}
@media (min-width: 1024px) and (max-width: 1249px) {
  .cb-widget .visible-md-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 1250px) {
  .cb-widget .visible-lg {
    display: block !important;
  }
  .cb-widget table.visible-lg {
    display: table !important;
  }
  .cb-widget tr.visible-lg {
    display: table-row !important;
  }
  .cb-widget th.visible-lg,
  .cb-widget td.visible-lg {
    display: table-cell !important;
  }
}
@media (min-width: 1250px) {
  .cb-widget .visible-lg-block {
    display: block !important;
  }
}
@media (min-width: 1250px) {
  .cb-widget .visible-lg-inline {
    display: inline !important;
  }
}
@media (min-width: 1250px) {
  .cb-widget .visible-lg-inline-block {
    display: inline-block !important;
  }
}
@media (max-width: 767px) {
  .cb-widget .hidden-xs {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .cb-widget .hidden-sm {
    display: none !important;
  }
}
@media (min-width: 1024px) and (max-width: 1249px) {
  .cb-widget .hidden-md {
    display: none !important;
  }
}
@media (min-width: 1250px) {
  .cb-widget .hidden-lg {
    display: none !important;
  }
}
.cb-widget .visible-print {
  display: none !important;
}
@media print {
  .cb-widget .visible-print {
    display: block !important;
  }
  .cb-widget table.visible-print {
    display: table !important;
  }
  .cb-widget tr.visible-print {
    display: table-row !important;
  }
  .cb-widget th.visible-print,
  .cb-widget td.visible-print {
    display: table-cell !important;
  }
}
.cb-widget .visible-print-block {
  display: none !important;
}
@media print {
  .cb-widget .visible-print-block {
    display: block !important;
  }
}
.cb-widget .visible-print-inline {
  display: none !important;
}
@media print {
  .cb-widget .visible-print-inline {
    display: inline !important;
  }
}
.cb-widget .visible-print-inline-block {
  display: none !important;
}
@media print {
  .cb-widget .visible-print-inline-block {
    display: inline-block !important;
  }
}
@media print {
  .cb-widget .hidden-print {
    display: none !important;
  }
}
.cb-widget spacer,
.cb-widget spacervxs,
.cb-widget .cb-spacer,
.cb-widget .cb-spacerv-xs {
  display: block;
  height: 24px;
}
.cb-widget .cb-spacer-top,
.cb-widget .cb-spacerv-xs-top {
  margin-top: 24px;
}
.cb-widget .cb-spacer-bottom,
.cb-widget .cb-spacerv-xs-bottom {
  margin-bottom: 24px;
}
.cb-widget spacerdouble,
.cb-widget spacervsm,
.cb-widget .cb-spacer-double,
.cb-widget .cb-spacerv-sm {
  display: block;
  height: 48px;
}
.cb-widget .cb-spacer-double-top,
.cb-widget .cb-spacerv-sm-top {
  margin-top: 48px;
}
.cb-widget .cb-spacer-double-bottom,
.cb-widget .cb-spacerv-sm-bottom {
  margin-bottom: 48px;
}
.cb-widget spacerhxs,
.cb-widget .cb-spacerh-xs {
  display: inline-block;
  width: 8px;
}
.cb-widget .cb-spacerh-xs-left {
  margin-left: 8px;
}
.cb-widget .cb-spacerh-xs-right {
  margin-right: 8px;
}
.cb-widget spacerhsm,
.cb-widget .cb-spacerh-sm {
  display: inline-block;
  width: 16px;
}
.cb-widget .cb-spacerh-sm-left {
  margin-left: 16px;
}
.cb-widget .cb-spacerh-sm-right {
  margin-right: 16px;
}
.cb-widget spacerhmd,
.cb-widget .cb-spacerh-md {
  display: inline-block;
  width: 24px;
}
.cb-widget .cb-spacerh-md-left {
  margin-left: 24px;
}
.cb-widget .cb-spacerh-md-right {
  margin-right: 24px;
}
.cb-widget h1,
.cb-widget h2,
.cb-widget h3,
.cb-widget h4,
.cb-widget h5,
.cb-widget h6,
.cb-widget .h1,
.cb-widget .h2,
.cb-widget .h3,
.cb-widget .h4,
.cb-widget .h5,
.cb-widget .h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}
.cb-widget h1 small,
.cb-widget h2 small,
.cb-widget h3 small,
.cb-widget h4 small,
.cb-widget h5 small,
.cb-widget h6 small,
.cb-widget .h1 small,
.cb-widget .h2 small,
.cb-widget .h3 small,
.cb-widget .h4 small,
.cb-widget .h5 small,
.cb-widget .h6 small,
.cb-widget h1 .small,
.cb-widget h2 .small,
.cb-widget h3 .small,
.cb-widget h4 .small,
.cb-widget h5 .small,
.cb-widget h6 .small,
.cb-widget .h1 .small,
.cb-widget .h2 .small,
.cb-widget .h3 .small,
.cb-widget .h4 .small,
.cb-widget .h5 .small,
.cb-widget .h6 .small {
  font-weight: normal;
  line-height: 1;
  color: #777777;
}
.cb-widget h1,
.cb-widget .h1,
.cb-widget h2,
.cb-widget .h2,
.cb-widget h3,
.cb-widget .h3 {
  margin-top: 1.5em;
  margin-bottom: 0.75em;
}
.cb-widget h1 small,
.cb-widget .h1 small,
.cb-widget h2 small,
.cb-widget .h2 small,
.cb-widget h3 small,
.cb-widget .h3 small,
.cb-widget h1 .small,
.cb-widget .h1 .small,
.cb-widget h2 .small,
.cb-widget .h2 .small,
.cb-widget h3 .small,
.cb-widget .h3 .small {
  font-size: 65%;
}
.cb-widget h4,
.cb-widget .h4,
.cb-widget h5,
.cb-widget .h5,
.cb-widget h6,
.cb-widget .h6 {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}
.cb-widget h4 small,
.cb-widget .h4 small,
.cb-widget h5 small,
.cb-widget .h5 small,
.cb-widget h6 small,
.cb-widget .h6 small,
.cb-widget h4 .small,
.cb-widget .h4 .small,
.cb-widget h5 .small,
.cb-widget .h5 .small,
.cb-widget h6 .small,
.cb-widget .h6 .small {
  font-size: 75%;
}
.cb-widget h1,
.cb-widget .h1 {
  font-size: 1.75rem;
}
.cb-widget h2,
.cb-widget .h2 {
  font-size: 1.4375rem;
}
.cb-widget h3,
.cb-widget .h3 {
  font-size: 1.1875rem;
}
.cb-widget h4,
.cb-widget .h4 {
  font-size: 1rem;
}
.cb-widget h5,
.cb-widget .h5 {
  font-size: 1rem;
}
.cb-widget h6,
.cb-widget .h6 {
  font-size: 0.875rem;
}
.cb-widget p {
  margin: 0 0 0.75em;
}
.cb-widget .lead {
  margin-bottom: 1.5em;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.4;
}
@media (min-width: 768px) {
  .cb-widget .lead {
    font-size: 1.5rem;
  }
}
.cb-widget small,
.cb-widget .small {
  font-size: 87%;
}
.cb-widget mark,
.cb-widget .mark {
  background-color: #f0f0f0;
  padding: 0.2em;
}
.cb-widget .text-left {
  text-align: left;
}
.cb-widget .text-right {
  text-align: right;
}
.cb-widget .text-center {
  text-align: center;
}
.cb-widget .text-justify {
  text-align: justify;
}
.cb-widget .text-nowrap {
  white-space: nowrap;
}
.cb-widget .text-lowercase {
  text-transform: lowercase;
}
.cb-widget .text-uppercase {
  text-transform: uppercase;
}
.cb-widget .text-capitalize {
  text-transform: capitalize;
}
.cb-widget .text-muted {
  color: #777777;
}
.cb-widget .text-primary {
  color: #337ab7;
}
.cb-widget a.text-primary:hover,
.cb-widget a.text-primary:focus {
  color: #286090;
}
.cb-widget .text-success {
  color: #3c763d;
}
.cb-widget a.text-success:hover,
.cb-widget a.text-success:focus {
  color: #2b542c;
}
.cb-widget .text-info {
  color: #31708f;
}
.cb-widget a.text-info:hover,
.cb-widget a.text-info:focus {
  color: #245269;
}
.cb-widget .text-warning {
  color: #ff9900;
}
.cb-widget a.text-warning:hover,
.cb-widget a.text-warning:focus {
  color: #cc7a00;
}
.cb-widget .text-danger {
  color: #c13145;
}
.cb-widget a.text-danger:hover,
.cb-widget a.text-danger:focus {
  color: #982736;
}
.cb-widget .bg-primary {
  color: #fff;
  background-color: #337ab7;
}
.cb-widget a.bg-primary:hover,
.cb-widget a.bg-primary:focus {
  background-color: #286090;
}
.cb-widget .bg-success {
  background-color: #dff0d8;
}
.cb-widget a.bg-success:hover,
.cb-widget a.bg-success:focus {
  background-color: #c1e2b3;
}
.cb-widget .bg-info {
  background-color: #d9edf7;
}
.cb-widget a.bg-info:hover,
.cb-widget a.bg-info:focus {
  background-color: #afd9ee;
}
.cb-widget .bg-warning {
  background-color: #f0f0f0;
}
.cb-widget a.bg-warning:hover,
.cb-widget a.bg-warning:focus {
  background-color: #d7d7d7;
}
.cb-widget .bg-danger {
  background-color: #f0f0f0;
}
.cb-widget a.bg-danger:hover,
.cb-widget a.bg-danger:focus {
  background-color: #d7d7d7;
}
.cb-widget .page-header {
  padding-bottom: -0.25em;
  margin: 3em 0 1.5em;
  border-bottom: 1px solid #eeeeee;
}
.cb-widget ul,
.cb-widget ol {
  margin-top: 0;
  margin-bottom: 0.75em;
}
.cb-widget ul ul,
.cb-widget ol ul,
.cb-widget ul ol,
.cb-widget ol ol {
  margin-bottom: 0;
}
.cb-widget .list-unstyled {
  padding-left: 0;
  list-style: none;
}
.cb-widget .list-inline {
  padding-left: 0;
  list-style: none;
  margin-left: -5px;
}
.cb-widget .list-inline > li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}
.cb-widget dl {
  margin-top: 0;
  margin-bottom: 1.5em;
}
.cb-widget dt,
.cb-widget dd {
  line-height: 1.5em;
}
.cb-widget dt {
  font-weight: bold;
}
.cb-widget dd {
  margin-left: 0;
}
@media (min-width: 768px) {
  .cb-widget .dl-horizontal dt {
    float: left;
    width: 160px;
    clear: left;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .cb-widget .dl-horizontal dd {
    margin-left: 180px;
  }
}
.cb-widget abbr[title],
.cb-widget abbr[data-original-title] {
  cursor: help;
  border-bottom: 1px dotted #777777;
}
.cb-widget .initialism {
  font-size: 90%;
  text-transform: uppercase;
}
.cb-widget blockquote {
  padding: 0.75em 1.5em;
  margin: 0 0 1.5em;
  font-size: 1.25rem;
  border-left: 5px solid #eeeeee;
}
.cb-widget blockquote p:last-child,
.cb-widget blockquote ul:last-child,
.cb-widget blockquote ol:last-child {
  margin-bottom: 0;
}
.cb-widget blockquote footer,
.cb-widget blockquote small,
.cb-widget blockquote .small {
  display: block;
  font-size: 80%;
  line-height: 1.5em;
  color: #777777;
}
.cb-widget blockquote footer:before,
.cb-widget blockquote small:before,
.cb-widget blockquote .small:before {
  content: '\2014   \A0';
}
.cb-widget .blockquote-reverse,
.cb-widget blockquote.pull-right {
  padding-right: 15px;
  padding-left: 0;
  border-right: 5px solid #eeeeee;
  border-left: 0;
  text-align: right;
}
.cb-widget .blockquote-reverse footer:before,
.cb-widget blockquote.pull-right footer:before,
.cb-widget .blockquote-reverse small:before,
.cb-widget blockquote.pull-right small:before,
.cb-widget .blockquote-reverse .small:before,
.cb-widget blockquote.pull-right .small:before {
  content: '';
}
.cb-widget .blockquote-reverse footer:after,
.cb-widget blockquote.pull-right footer:after,
.cb-widget .blockquote-reverse small:after,
.cb-widget blockquote.pull-right small:after,
.cb-widget .blockquote-reverse .small:after,
.cb-widget blockquote.pull-right .small:after {
  content: '\A0   \2014';
}
.cb-widget address {
  margin-bottom: 1.5em;
  font-style: normal;
  line-height: 1.5em;
}
.cb-widget h1,
.cb-widget .h1 {
  font-family: "Roboto Slab Bold", sans-serif;
  font-size: 1.75rem;
  line-height: 1.71428571em;
  margin: 0 0 24px 0;
}
.cb-widget h2,
.cb-widget .h2 {
  font-family: "Roboto Slab Bold", sans-serif;
  font-size: 1.4375rem;
  line-height: 1.04347826em;
  margin: 0 0 24px 0;
}
.cb-widget h3,
.cb-widget .h3 {
  font-family: "Roboto Slab Bold", sans-serif;
  font-size: 1.1875rem;
  line-height: 1.26315789em;
  margin: 0 0 24px 0;
}
.cb-widget h4,
.cb-widget .h4,
.cb-widget h5,
.cb-widget .h5 {
  font-family: "Roboto Slab Bold", sans-serif;
  font-size: 1rem;
  line-height: 1.5em;
  margin: 0;
}
.cb-widget h6,
.cb-widget .h6 {
  font-family: inherit;
  font-size: 0.875rem;
  line-height: 1.71428571em;
  margin: 0;
}
.cb-widget .cb-heading-border-four {
  line-height: 24px;
  padding-bottom: 12px;
  border-bottom: 4px solid #505050;
  margin: 0 0 20px 0;
}
.cb-widget legend {
  font-family: "Roboto Slab", sans-serif;
  font-size: 1.1875rem;
  line-height: 1.26315789em;
}
.cb-widget dt {
  margin-left: 24px;
  font-family: "Roboto Bold", sans-serif;
}
.cb-widget dd {
  margin-left: 24px;
}
.cb-widget ul,
.cb-widget ol {
  padding-left: 48px;
}
.cb-widget li ul,
.cb-widget li ol {
  padding-left: 24px;
}
.cb-widget li ul {
  list-style-type: disc;
}
.cb-widget .cb-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cb-widget blockquote:not(.cb-testimonial):not(.cb-testimonial-half):not(.cb-testimonial-full) {
  font-family: "Roboto Slab", sans-serif;
  border: 0;
  border-top: 1px solid #666564;
  border-bottom: 1px solid #666564;
  margin: 23px 0;
  padding: 30px 0;
  position: relative;
}
.cb-widget blockquote:not(.cb-testimonial):not(.cb-testimonial-half):not(.cb-testimonial-full)::before {
  font-family: 'CB Icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #d9d9d9;
  font-size: 1.5rem;
  position: absolute;
  content: "\E93D";
  left: 0;
  top: 33px;
}
.cb-widget blockquote:not(.cb-testimonial):not(.cb-testimonial-half):not(.cb-testimonial-full)::after {
  font-family: 'CB Icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #d9d9d9;
  font-size: 1.5rem;
  position: absolute;
  content: "\E93E";
  right: 0;
  bottom: 33px;
}
.cb-widget blockquote:not(.cb-testimonial):not(.cb-testimonial-half):not(.cb-testimonial-full) p {
  text-align: center;
  color: #000000;
  font-size: 1.5rem;
  line-height: 1.25em;
  padding: 0 30px;
  margin: 0 25px;
}
@media screen and (max-width: 767px) {
  .cb-widget blockquote:not(.cb-testimonial):not(.cb-testimonial-half):not(.cb-testimonial-full) p {
    font-size: 1.125rem;
    line-height: 1.333em;
    padding: 0 15px;
    margin: 0 25px;
  }
}
.cb-widget .serifaStd-light {
  font-family: "SerifaStd-Light", Georgia, "Times New Roman", Times, serif;
}
.cb-widget .serifaStd-roman {
  font-family: "SerifaStd-Roman", Georgia, "Times New Roman", Times, serif;
}
.cb-widget .serifaStd-bold {
  font-family: "SerifaStd-bold", Georgia, "Times New Roman", Times, serif;
}
.cb-widget .helvetica-bold {
  font-family: "Helvetica Bold", Helvetica, Arial, sans-serif;
  font-weight: 700;
}
.cb-widget .helvetica-regular {
  font-family: "Helvetica Regular", Helvetica, Arial, sans-serif;
}
.cb-widget .helveticaNeue-bold {
  font-family: HelveticaNeueBold, "Helvetica Neue Bold", Helvetica, Arial, sans-serif;
  font-weight: 700;
}
.cb-widget .helveticaNeue-regular {
  font-family: HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.cb-widget .roboto,
.cb-widget .cb-roboto {
  font-family: "Roboto", sans-serif;
}
.cb-widget .roboto-bold,
.cb-widget .cb-roboto-bold {
  font-family: "Roboto Bold", sans-serif;
}
.cb-widget .roboto-italic,
.cb-widget .cb-roboto-italic {
  font-family: "Roboto Italic", sans-serif;
}
.cb-widget .roboto-slab,
.cb-widget .cb-roboto-slab {
  font-family: "Roboto Slab", sans-serif;
}
.cb-widget .roboto-slab-bold,
.cb-widget .cb-roboto-slab-bold {
  font-family: "Roboto Slab Bold", sans-serif;
}
.cb-widget .btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 1rem;
  line-height: 1.5em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cb-widget .btn:focus,
.cb-widget .btn:active:focus,
.cb-widget .btn.active:focus,
.cb-widget .btn.focus,
.cb-widget .btn:active.focus,
.cb-widget .btn.active.focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.cb-widget .btn:hover,
.cb-widget .btn:focus,
.cb-widget .btn.focus {
  color: #333;
  text-decoration: none;
}
.cb-widget .btn:active,
.cb-widget .btn.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.cb-widget .btn.disabled,
.cb-widget .btn[disabled],
.cb-widget fieldset[disabled] .btn {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.cb-widget a.btn.disabled,
.cb-widget fieldset[disabled] a.btn {
  pointer-events: none;
}
.cb-widget .btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
.cb-widget .btn-default:hover,
.cb-widget .btn-default:focus,
.cb-widget .btn-default:active,
.cb-widget .btn-default.active,
.cb-widget .open > .dropdown-toggle.btn-default {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.cb-widget .btn-default:active,
.cb-widget .btn-default.active,
.cb-widget .open > .dropdown-toggle.btn-default {
  background-image: none;
}
.cb-widget .btn-default.disabled,
.cb-widget .btn-default[disabled],
.cb-widget fieldset[disabled] .btn-default,
.cb-widget .btn-default.disabled:hover,
.cb-widget .btn-default[disabled]:hover,
.cb-widget fieldset[disabled] .btn-default:hover,
.cb-widget .btn-default.disabled:focus,
.cb-widget .btn-default[disabled]:focus,
.cb-widget fieldset[disabled] .btn-default:focus,
.cb-widget .btn-default.disabled:active,
.cb-widget .btn-default[disabled]:active,
.cb-widget fieldset[disabled] .btn-default:active,
.cb-widget .btn-default.disabled.active,
.cb-widget .btn-default[disabled].active,
.cb-widget fieldset[disabled] .btn-default.active {
  background-color: #fff;
  border-color: #ccc;
}
.cb-widget .btn-default .badge {
  color: #fff;
  background-color: #333;
}
.cb-widget .btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}
.cb-widget .btn-primary:hover,
.cb-widget .btn-primary:focus,
.cb-widget .btn-primary:active,
.cb-widget .btn-primary.active,
.cb-widget .open > .dropdown-toggle.btn-primary {
  color: #fff;
  background-color: #286090;
  border-color: #204d74;
}
.cb-widget .btn-primary:active,
.cb-widget .btn-primary.active,
.cb-widget .open > .dropdown-toggle.btn-primary {
  background-image: none;
}
.cb-widget .btn-primary.disabled,
.cb-widget .btn-primary[disabled],
.cb-widget fieldset[disabled] .btn-primary,
.cb-widget .btn-primary.disabled:hover,
.cb-widget .btn-primary[disabled]:hover,
.cb-widget fieldset[disabled] .btn-primary:hover,
.cb-widget .btn-primary.disabled:focus,
.cb-widget .btn-primary[disabled]:focus,
.cb-widget fieldset[disabled] .btn-primary:focus,
.cb-widget .btn-primary.disabled:active,
.cb-widget .btn-primary[disabled]:active,
.cb-widget fieldset[disabled] .btn-primary:active,
.cb-widget .btn-primary.disabled.active,
.cb-widget .btn-primary[disabled].active,
.cb-widget fieldset[disabled] .btn-primary.active {
  background-color: #337ab7;
  border-color: #2e6da4;
}
.cb-widget .btn-primary .badge {
  color: #337ab7;
  background-color: #fff;
}
.cb-widget .btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}
.cb-widget .btn-success:hover,
.cb-widget .btn-success:focus,
.cb-widget .btn-success:active,
.cb-widget .btn-success.active,
.cb-widget .open > .dropdown-toggle.btn-success {
  color: #fff;
  background-color: #449d44;
  border-color: #398439;
}
.cb-widget .btn-success:active,
.cb-widget .btn-success.active,
.cb-widget .open > .dropdown-toggle.btn-success {
  background-image: none;
}
.cb-widget .btn-success.disabled,
.cb-widget .btn-success[disabled],
.cb-widget fieldset[disabled] .btn-success,
.cb-widget .btn-success.disabled:hover,
.cb-widget .btn-success[disabled]:hover,
.cb-widget fieldset[disabled] .btn-success:hover,
.cb-widget .btn-success.disabled:focus,
.cb-widget .btn-success[disabled]:focus,
.cb-widget fieldset[disabled] .btn-success:focus,
.cb-widget .btn-success.disabled:active,
.cb-widget .btn-success[disabled]:active,
.cb-widget fieldset[disabled] .btn-success:active,
.cb-widget .btn-success.disabled.active,
.cb-widget .btn-success[disabled].active,
.cb-widget fieldset[disabled] .btn-success.active {
  background-color: #5cb85c;
  border-color: #4cae4c;
}
.cb-widget .btn-success .badge {
  color: #5cb85c;
  background-color: #fff;
}
.cb-widget .btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da;
}
.cb-widget .btn-info:hover,
.cb-widget .btn-info:focus,
.cb-widget .btn-info:active,
.cb-widget .btn-info.active,
.cb-widget .open > .dropdown-toggle.btn-info {
  color: #fff;
  background-color: #31b0d5;
  border-color: #269abc;
}
.cb-widget .btn-info:active,
.cb-widget .btn-info.active,
.cb-widget .open > .dropdown-toggle.btn-info {
  background-image: none;
}
.cb-widget .btn-info.disabled,
.cb-widget .btn-info[disabled],
.cb-widget fieldset[disabled] .btn-info,
.cb-widget .btn-info.disabled:hover,
.cb-widget .btn-info[disabled]:hover,
.cb-widget fieldset[disabled] .btn-info:hover,
.cb-widget .btn-info.disabled:focus,
.cb-widget .btn-info[disabled]:focus,
.cb-widget fieldset[disabled] .btn-info:focus,
.cb-widget .btn-info.disabled:active,
.cb-widget .btn-info[disabled]:active,
.cb-widget fieldset[disabled] .btn-info:active,
.cb-widget .btn-info.disabled.active,
.cb-widget .btn-info[disabled].active,
.cb-widget fieldset[disabled] .btn-info.active {
  background-color: #5bc0de;
  border-color: #46b8da;
}
.cb-widget .btn-info .badge {
  color: #5bc0de;
  background-color: #fff;
}
.cb-widget .btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236;
}
.cb-widget .btn-warning:hover,
.cb-widget .btn-warning:focus,
.cb-widget .btn-warning:active,
.cb-widget .btn-warning.active,
.cb-widget .open > .dropdown-toggle.btn-warning {
  color: #fff;
  background-color: #ec971f;
  border-color: #d58512;
}
.cb-widget .btn-warning:active,
.cb-widget .btn-warning.active,
.cb-widget .open > .dropdown-toggle.btn-warning {
  background-image: none;
}
.cb-widget .btn-warning.disabled,
.cb-widget .btn-warning[disabled],
.cb-widget fieldset[disabled] .btn-warning,
.cb-widget .btn-warning.disabled:hover,
.cb-widget .btn-warning[disabled]:hover,
.cb-widget fieldset[disabled] .btn-warning:hover,
.cb-widget .btn-warning.disabled:focus,
.cb-widget .btn-warning[disabled]:focus,
.cb-widget fieldset[disabled] .btn-warning:focus,
.cb-widget .btn-warning.disabled:active,
.cb-widget .btn-warning[disabled]:active,
.cb-widget fieldset[disabled] .btn-warning:active,
.cb-widget .btn-warning.disabled.active,
.cb-widget .btn-warning[disabled].active,
.cb-widget fieldset[disabled] .btn-warning.active {
  background-color: #f0ad4e;
  border-color: #eea236;
}
.cb-widget .btn-warning .badge {
  color: #f0ad4e;
  background-color: #fff;
}
.cb-widget .btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}
.cb-widget .btn-danger:hover,
.cb-widget .btn-danger:focus,
.cb-widget .btn-danger:active,
.cb-widget .btn-danger.active,
.cb-widget .open > .dropdown-toggle.btn-danger {
  color: #fff;
  background-color: #c9302c;
  border-color: #ac2925;
}
.cb-widget .btn-danger:active,
.cb-widget .btn-danger.active,
.cb-widget .open > .dropdown-toggle.btn-danger {
  background-image: none;
}
.cb-widget .btn-danger.disabled,
.cb-widget .btn-danger[disabled],
.cb-widget fieldset[disabled] .btn-danger,
.cb-widget .btn-danger.disabled:hover,
.cb-widget .btn-danger[disabled]:hover,
.cb-widget fieldset[disabled] .btn-danger:hover,
.cb-widget .btn-danger.disabled:focus,
.cb-widget .btn-danger[disabled]:focus,
.cb-widget fieldset[disabled] .btn-danger:focus,
.cb-widget .btn-danger.disabled:active,
.cb-widget .btn-danger[disabled]:active,
.cb-widget fieldset[disabled] .btn-danger:active,
.cb-widget .btn-danger.disabled.active,
.cb-widget .btn-danger[disabled].active,
.cb-widget fieldset[disabled] .btn-danger.active {
  background-color: #d9534f;
  border-color: #d43f3a;
}
.cb-widget .btn-danger .badge {
  color: #d9534f;
  background-color: #fff;
}
.cb-widget .btn-link {
  color: #0077c8;
  font-weight: normal;
  border-radius: 0;
}
.cb-widget .btn-link,
.cb-widget .btn-link:active,
.cb-widget .btn-link.active,
.cb-widget .btn-link[disabled],
.cb-widget fieldset[disabled] .btn-link {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.cb-widget .btn-link,
.cb-widget .btn-link:hover,
.cb-widget .btn-link:focus,
.cb-widget .btn-link:active {
  border-color: transparent;
}
.cb-widget .btn-link:hover,
.cb-widget .btn-link:focus {
  color: #0077c8;
  text-decoration: underline;
  background-color: transparent;
}
.cb-widget .btn-link[disabled]:hover,
.cb-widget fieldset[disabled] .btn-link:hover,
.cb-widget .btn-link[disabled]:focus,
.cb-widget fieldset[disabled] .btn-link:focus {
  color: #777777;
  text-decoration: none;
}
.cb-widget .btn-lg,
.cb-widget .btn-group-lg > .btn {
  padding: 10px 16px;
  font-size: 1.125rem;
  line-height: 1.3333333;
}
.cb-widget .btn-sm,
.cb-widget .btn-group-sm > .btn {
  padding: 5px 10px;
  font-size: 0.875rem;
  line-height: 1.286em;
}
.cb-widget .btn-xs,
.cb-widget .btn-group-xs > .btn {
  padding: 1px 5px;
  font-size: 0.875rem;
  line-height: 1.286em;
}
.cb-widget .btn-block {
  display: block;
  width: 100%;
}
.cb-widget .btn-block + .btn-block {
  margin-top: 5px;
}
.cb-widget input[type="submit"].btn-block,
.cb-widget input[type="reset"].btn-block,
.cb-widget input[type="button"].btn-block {
  width: 100%;
}
.cb-widget .btn {
  line-height: 24px;
  font-family: "Roboto Slab Bold", sans-serif;
  border-radius: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.cb-widget .btn-lg,
.cb-widget .btn-group-lg > .btn {
  padding: 12px 52px;
  font-size: 1.25rem;
}
.cb-widget .btn-lg.btn-primary {
  padding-top: 20px;
  padding-bottom: 20px;
}
.cb-widget .btn-sm,
.cb-widget .btn-group-sm > .btn {
  padding: 12px 32px;
  font-size: 0.875rem;
}
.cb-widget .btn-xs,
.cb-widget .btn-group-xs > .btn {
  padding: 2px 8px;
  font-size: 0.875rem;
  line-height: 18px !important;
}
.cb-widget .btn-primary {
  background-color: #fedb00;
  color: #1e1e1e;
}
.cb-widget .btn-primary[disabled],
.cb-widget .btn-primary.disabled,
.cb-widget fieldset[disabled] .btn-primary {
  color: #989795;
  background-color: #ebe9e6;
  border-color: transparent;
  background-image: none;
  text-shadow: none;
  pointer-events: none;
}
.cb-widget .btn-primary[disabled]:hover,
.cb-widget .btn-primary.disabled:hover,
.cb-widget fieldset[disabled] .btn-primary:hover,
.cb-widget .btn-primary[disabled]:focus,
.cb-widget .btn-primary.disabled:focus,
.cb-widget fieldset[disabled] .btn-primary:focus {
  background-color: #ebe9e6;
  color: #989795;
  text-decoration: none;
}
.cb-widget .btn-primary:hover,
.cb-widget .btn-primary:focus,
.cb-widget .btn-primary:active,
.cb-widget .btn-primary.hover,
.cb-widget .btn-primary.focus,
.cb-widget .btn-primary.active {
  color: #1e1e1e;
  text-decoration: underline;
  background-color: #fedb00;
  border-color: transparent;
  background-image: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.cb-widget .btn-secondary {
  color: #1e1e1e;
  background-color: transparent;
  border-color: #1e1e1e;
}
.cb-widget .btn-secondary.btn-lg {
  padding: calc(12px - 1px) calc(52px - 1px);
  font-size: 1.25rem;
}
.cb-widget .btn-secondary.btn-sm {
  padding: calc(12px - 1px) calc(32px - 1px);
  font-size: 0.875rem;
}
.cb-widget .btn-secondary:hover,
.cb-widget .btn-secondary:focus,
.cb-widget .btn-secondary:active,
.cb-widget .btn-secondary.hover,
.cb-widget .btn-secondary.focus,
.cb-widget .btn-secondary.active {
  color: #1e1e1e;
  text-decoration: underline;
  background-color: transparent;
  border-color: #1e1e1e;
  background-image: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.cb-widget .btn-secondary[disabled],
.cb-widget .btn-secondary.disabled,
.cb-widget fieldset[disabled] .btn-secondary {
  color: #989795;
  background-color: transform;
  border-color: #e5e2e0;
  background-image: none;
  text-shadow: none;
  pointer-events: none;
}
.cb-widget .btn-secondary[disabled]:hover,
.cb-widget .btn-secondary.disabled:hover,
.cb-widget fieldset[disabled] .btn-secondary:hover,
.cb-widget .btn-secondary[disabled]:focus,
.cb-widget .btn-secondary.disabled:focus,
.cb-widget fieldset[disabled] .btn-secondary:focus {
  background-color: transform;
  color: #989795;
  text-decoration: none;
}
.cb-widget .btn-secondary:focus,
.cb-widget .btn-secondary.focus {
  outline-style: solid;
  outline-color: #0077c8;
  outline-width: 1px;
  outline-offset: -1px;
}
.cb-widget .btn-secondary-light {
  color: #ffffff;
  background-color: transparent;
  border-color: #ffffff;
}
.cb-widget .btn-secondary-light.btn-lg {
  padding: calc(12px - 1px) calc(52px - 1px);
  font-size: 1.25rem;
}
.cb-widget .btn-secondary-light.btn-sm {
  padding: calc(12px - 1px) calc(32px - 1px);
  font-size: 0.875rem;
}
.cb-widget .btn-secondary-light:hover,
.cb-widget .btn-secondary-light:focus,
.cb-widget .btn-secondary-light:active,
.cb-widget .btn-secondary-light.hover,
.cb-widget .btn-secondary-light.focus,
.cb-widget .btn-secondary-light.active {
  color: #ffffff;
  text-decoration: underline;
  background-color: transparent;
  border-color: #ffffff;
  background-image: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.cb-widget .btn-secondary-light[disabled],
.cb-widget .btn-secondary-light.disabled,
.cb-widget fieldset[disabled] .btn-secondary-light {
  color: #989795;
  background-color: transform;
  border-color: #e5e2e0;
  background-image: none;
  text-shadow: none;
  pointer-events: none;
}
.cb-widget .btn-secondary-light[disabled]:hover,
.cb-widget .btn-secondary-light.disabled:hover,
.cb-widget fieldset[disabled] .btn-secondary-light:hover,
.cb-widget .btn-secondary-light[disabled]:focus,
.cb-widget .btn-secondary-light.disabled:focus,
.cb-widget fieldset[disabled] .btn-secondary-light:focus {
  background-color: transform;
  color: #989795;
  text-decoration: none;
}
.cb-widget .btn-secondary-light:focus,
.cb-widget .btn-secondary-light.focus {
  outline-style: solid;
  outline-color: #0077c8;
  outline-width: 1px;
  outline-offset: -1px;
}
.cb-widget .btn-secondary-blue {
  color: #ffffff;
  background-color: #0077c8;
  border-color: #0077c8;
}
.cb-widget .btn-secondary-blue.btn-lg {
  padding: calc(12px - 1px) calc(52px - 1px);
  font-size: 1.25rem;
}
.cb-widget .btn-secondary-blue.btn-sm {
  padding: calc(12px - 1px) calc(32px - 1px);
  font-size: 0.875rem;
}
.cb-widget .btn-secondary-blue:hover,
.cb-widget .btn-secondary-blue:focus,
.cb-widget .btn-secondary-blue:active,
.cb-widget .btn-secondary-blue.hover,
.cb-widget .btn-secondary-blue.focus,
.cb-widget .btn-secondary-blue.active {
  color: #ffffff;
  text-decoration: underline;
  background-color: #0077c8;
  border-color: #0077c8;
  background-image: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.cb-widget .btn-secondary-blue[disabled],
.cb-widget .btn-secondary-blue.disabled,
.cb-widget fieldset[disabled] .btn-secondary-blue {
  color: #989795;
  background-color: #ebe9e6;
  border-color: transparent;
  background-image: none;
  text-shadow: none;
  pointer-events: none;
}
.cb-widget .btn-secondary-blue[disabled]:hover,
.cb-widget .btn-secondary-blue.disabled:hover,
.cb-widget fieldset[disabled] .btn-secondary-blue:hover,
.cb-widget .btn-secondary-blue[disabled]:focus,
.cb-widget .btn-secondary-blue.disabled:focus,
.cb-widget fieldset[disabled] .btn-secondary-blue:focus {
  background-color: #ebe9e6;
  color: #989795;
  text-decoration: none;
}
.cb-widget .btn-secondary-blue:focus,
.cb-widget .btn-secondary-blue.focus {
  outline-style: solid;
  outline-color: #0077c8;
  outline-width: 1px;
  outline-offset: -1px;
}
.cb-widget .btn-secondary-group .btn-secondary {
  border-color: transparent !important;
  color: #0077c8;
  padding-right: 0;
  padding-left: 0;
}
.cb-widget .btn .cb-glyph {
  font-size: 0.875rem;
}
.cb-widget .btn.btn-lg .cb-glyph {
  font-size: 1.125rem;
}
.cb-widget fieldset[disabled] .btn-primary {
  color: #989795;
  background-color: #ebe9e6;
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-image: none;
  text-shadow: none;
}
.cb-widget .btn-primary.btn {
  border: 0;
}
.cb-widget .btn-primary:focus,
.cb-widget .btn-primary.focus {
  outline-style: solid;
  outline-color: #0077c8;
  outline-width: 1px;
  outline-offset: -1px;
}
.cb-widget .btn-default {
  color: #1e1e1e;
  line-height: 22px;
  background-color: #faf9f7;
  border-color: #d9d9d9;
  transition: background-color 300ms ease-out;
}
.cb-widget .btn-default:hover,
.cb-widget .btn-default:focus,
.cb-widget .btn-default:active,
.cb-widget .btn-default.hover,
.cb-widget .btn-default.focus,
.cb-widget .btn-default.active {
  color: "Roboto Slab", sans-serif;
  text-decoration: underline;
  background-color: #e5e2e0;
  border-color: #e5e2e0;
  background-image: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.cb-widget .btn-default[disabled],
.cb-widget .btn-default.disabled,
.cb-widget fieldset[disabled] .btn-default {
  color: #989795;
  background-color: transform;
  border-color: #e5e2e0;
  background-image: none;
  text-shadow: none;
  pointer-events: none;
}
.cb-widget .btn-default[disabled]:hover,
.cb-widget .btn-default.disabled:hover,
.cb-widget fieldset[disabled] .btn-default:hover,
.cb-widget .btn-default[disabled]:focus,
.cb-widget .btn-default.disabled:focus,
.cb-widget fieldset[disabled] .btn-default:focus {
  background-color: transform;
  color: #989795;
  text-decoration: none;
}
.cb-widget .btn-default:focus,
.cb-widget .btn-default.focus {
  outline-style: solid;
  outline-color: #0077c8;
  outline-width: 1px;
  outline-offset: -1px;
}
.cb-widget .btn-tag {
  font-family: "Roboto Slab", sans-serif;
  color: #1e1e1e;
  line-height: 22px;
  background-color: #e5e5e5;
  border-color: #e5e5e5;
  transition: background-color 300ms ease-out;
}
.cb-widget .btn-tag:hover,
.cb-widget .btn-tag:focus,
.cb-widget .btn-tag:active,
.cb-widget .btn-tag.hover,
.cb-widget .btn-tag.focus,
.cb-widget .btn-tag.active {
  color: #ffffff;
  text-decoration: underline;
  background-color: #bfbfbf;
  border-color: #e5e5e5;
  background-image: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.cb-widget .btn-tag[disabled],
.cb-widget .btn-tag.disabled,
.cb-widget fieldset[disabled] .btn-tag {
  color: #989795;
  background-color: transform;
  border-color: #e5e2e0;
  background-image: none;
  text-shadow: none;
  pointer-events: none;
}
.cb-widget .btn-tag[disabled]:hover,
.cb-widget .btn-tag.disabled:hover,
.cb-widget fieldset[disabled] .btn-tag:hover,
.cb-widget .btn-tag[disabled]:focus,
.cb-widget .btn-tag.disabled:focus,
.cb-widget fieldset[disabled] .btn-tag:focus {
  background-color: transform;
  color: #989795;
  text-decoration: none;
}
.cb-widget .btn-tag:focus,
.cb-widget .btn-tag.focus {
  outline-style: solid;
  outline-color: #0077c8;
  outline-width: 1px;
  outline-offset: -1px;
}
.cb-widget .btn-minimal {
  color: #1e1e1e;
  background-color: transparent;
  border-color: #1e1e1e;
}
.cb-widget .btn-minimal.btn-lg {
  padding: calc(12px - 1px) calc(52px - 1px);
  font-size: 1.25rem;
}
.cb-widget .btn-minimal.btn-sm {
  padding: calc(12px - 1px) calc(32px - 1px);
  font-size: 0.875rem;
}
.cb-widget .btn-minimal:hover,
.cb-widget .btn-minimal:focus,
.cb-widget .btn-minimal:active,
.cb-widget .btn-minimal.hover,
.cb-widget .btn-minimal.focus,
.cb-widget .btn-minimal.active {
  color: #1e1e1e;
  text-decoration: underline;
  background-color: transparent;
  border-color: #1e1e1e;
  background-image: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.cb-widget .btn-minimal[disabled],
.cb-widget .btn-minimal.disabled,
.cb-widget fieldset[disabled] .btn-minimal {
  color: #989795;
  background-color: transform;
  border-color: #e5e2e0;
  background-image: none;
  text-shadow: none;
  pointer-events: none;
}
.cb-widget .btn-minimal[disabled]:hover,
.cb-widget .btn-minimal.disabled:hover,
.cb-widget fieldset[disabled] .btn-minimal:hover,
.cb-widget .btn-minimal[disabled]:focus,
.cb-widget .btn-minimal.disabled:focus,
.cb-widget fieldset[disabled] .btn-minimal:focus {
  background-color: transform;
  color: #989795;
  text-decoration: none;
}
.cb-widget .btn-minimal:focus,
.cb-widget .btn-minimal.focus {
  outline-style: solid;
  outline-color: #0077c8;
  outline-width: 1px;
  outline-offset: -1px;
}
.cb-widget .btn-light-minimal {
  color: #ffffff;
  background-color: transparent;
  border-color: #ffffff;
}
.cb-widget .btn-light-minimal.btn-lg {
  padding: calc(12px - 1px) calc(52px - 1px);
  font-size: 1.25rem;
}
.cb-widget .btn-light-minimal.btn-sm {
  padding: calc(12px - 1px) calc(32px - 1px);
  font-size: 0.875rem;
}
.cb-widget .btn-light-minimal:hover,
.cb-widget .btn-light-minimal:focus,
.cb-widget .btn-light-minimal:active,
.cb-widget .btn-light-minimal.hover,
.cb-widget .btn-light-minimal.focus,
.cb-widget .btn-light-minimal.active {
  color: #ffffff;
  text-decoration: underline;
  background-color: transparent;
  border-color: #ffffff;
  background-image: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.cb-widget .btn-light-minimal[disabled],
.cb-widget .btn-light-minimal.disabled,
.cb-widget fieldset[disabled] .btn-light-minimal {
  color: #989795;
  background-color: transform;
  border-color: #e5e2e0;
  background-image: none;
  text-shadow: none;
  pointer-events: none;
}
.cb-widget .btn-light-minimal[disabled]:hover,
.cb-widget .btn-light-minimal.disabled:hover,
.cb-widget fieldset[disabled] .btn-light-minimal:hover,
.cb-widget .btn-light-minimal[disabled]:focus,
.cb-widget .btn-light-minimal.disabled:focus,
.cb-widget fieldset[disabled] .btn-light-minimal:focus {
  background-color: transform;
  color: #989795;
  text-decoration: none;
}
.cb-widget .btn-light-minimal:focus,
.cb-widget .btn-light-minimal.focus {
  outline-style: solid;
  outline-color: #0077c8;
  outline-width: 1px;
  outline-offset: -1px;
}
.cb-widget .btn:hover {
  text-decoration: underline;
}
.cb-widget .btn.cb-btn-fluid {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}
@media (max-width: 767px) {
  .cb-widget .btn.cb-xs-btn-fluid {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 1023px) and (min-width: 768px) {
  .cb-widget .btn.cb-sm-btn-fluid {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 1024px) and (max-width: 1249px) {
  .cb-widget .btn.cb-md-btn-fluid {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 1250px) {
  .cb-widget .btn.cb-lg-btn-fluid {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}
.cb-widget .cb-primary-secondary .btn {
  line-height: 24px !important;
}
.cb-widget .cb-toggle-btn {
  display: inline-block;
  font-size: 0;
}
.cb-widget .cb-toggle-btn .radio-inline {
  padding: 0;
  margin: 0;
  width: 48px;
  height: 48px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.cb-widget .cb-toggle-btn .radio-inline input[type="radio"] {
  margin: 0;
}
.cb-widget .cb-toggle-btn .radio-inline input[type="radio"] + span:not(.cb-glyph) {
  position: absolute;
  top: 0;
  z-index: 2;
  background-color: #ffffff;
  border: 1px solid #1e1e1e;
  border-right-width: 0;
  margin: 0;
  display: block;
  width: 48px;
  height: 48px;
  font-size: 0;
}
.cb-widget .cb-toggle-btn .radio-inline input[type="radio"] + span:not(.cb-glyph) + .cb-glyph {
  font-size: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
  transform: translate(-50%, -50%);
}
.cb-widget .cb-toggle-btn .radio-inline input[type="radio"]:hover,
.cb-widget .cb-toggle-btn .radio-inline input[type="radio"]:focus {
  outline: 0;
}
.cb-widget .cb-toggle-btn .radio-inline input[type="radio"]:hover + span:not(.cb-glyph),
.cb-widget .cb-toggle-btn .radio-inline input[type="radio"]:focus + span:not(.cb-glyph) {
  background-color: #009cde;
  color: #ffffff;
}
.cb-widget .cb-toggle-btn .radio-inline input[type="radio"]:hover + span:not(.cb-glyph) + .cb-glyph,
.cb-widget .cb-toggle-btn .radio-inline input[type="radio"]:focus + span:not(.cb-glyph) + .cb-glyph {
  color: #ffffff;
}
.cb-widget .cb-toggle-btn .radio-inline input[type="radio"]:hover + span:not(.cb-glyph):after,
.cb-widget .cb-toggle-btn .radio-inline input[type="radio"]:focus + span:not(.cb-glyph):after {
  border: 0!important;
}
.cb-widget .cb-toggle-btn .radio-inline input[type="radio"]:checked,
.cb-widget .cb-toggle-btn .radio-inline input[type="radio"]:active,
.cb-widget .cb-toggle-btn .radio-inline input[type="radio"].checked {
  outline: 0;
}
.cb-widget .cb-toggle-btn .radio-inline input[type="radio"]:checked + span:not(.cb-glyph),
.cb-widget .cb-toggle-btn .radio-inline input[type="radio"]:active + span:not(.cb-glyph),
.cb-widget .cb-toggle-btn .radio-inline input[type="radio"].checked + span:not(.cb-glyph) {
  background-color: #0077c8;
  color: #ffffff;
}
.cb-widget .cb-toggle-btn .radio-inline input[type="radio"]:checked + span:not(.cb-glyph) + .cb-glyph,
.cb-widget .cb-toggle-btn .radio-inline input[type="radio"]:active + span:not(.cb-glyph) + .cb-glyph,
.cb-widget .cb-toggle-btn .radio-inline input[type="radio"].checked + span:not(.cb-glyph) + .cb-glyph {
  color: #ffffff;
}
.cb-widget .cb-toggle-btn .radio-inline input[type="radio"][disabled] + span:not(.cb-glyph),
.cb-widget .cb-toggle-btn .radio-inline input[type="radio"].disabled + span:not(.cb-glyph),
.cb-widget fieldset[disabled] .cb-toggle-btn .radio-inline input[type="radio"] + span:not(.cb-glyph) {
  background-color: #ebe9e6;
  border-color: #e5e2e0;
  color: #989795;
}
.cb-widget .cb-toggle-btn .radio-inline input[type="radio"][disabled] + span:not(.cb-glyph) + .cb-glyph,
.cb-widget .cb-toggle-btn .radio-inline input[type="radio"].disabled + span:not(.cb-glyph) + .cb-glyph,
.cb-widget fieldset[disabled] .cb-toggle-btn .radio-inline input[type="radio"] + span:not(.cb-glyph) + .cb-glyph {
  color: #989795;
}
.cb-widget .cb-toggle-btn .radio-inline + .radio-inline {
  margin: 0;
}
.cb-widget .cb-toggle-btn .radio-inline:last-of-type input[type="radio"] + span:not(.cb-glyph) {
  border-right-width: 1px;
}
.cb-widget .cb-toggle-btn .radio-inline span:last-of-type:not(.cb-glyph) {
  font-family: "Roboto Bold", sans-serif;
  font-size: 0.75rem !important;
  line-height: 4em;
  text-align: center;
}
.cb-widget .cb-toggle-btn .radio-inline span:last-of-type:not(.cb-glyph)::before {
  content: "";
}
.cb-widget .btn-group > .btn.btn-primary + .btn-primary {
  border-left: 1px solid #ffffff;
  margin-left: 0;
}
.cb-widget .cb-carousel {
  border-radius: 50%;
  background-color: #0077c8;
  width: 48px;
  height: 48px;
  display: inline-block;
  outline: 0;
  position: relative;
  text-decoration: none;
}
.cb-widget .cb-carousel span {
  font-size: 0;
}
.cb-widget .cb-carousel.cb-prev::before {
  font-family: 'CB Icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 700;
  position: absolute;
  display: block;
  content: "\E921";
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
}
.cb-widget .cb-carousel.cb-next::before {
  font-family: 'CB Icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 700;
  position: absolute;
  display: block;
  content: "\E940";
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
}
.cb-widget .cb-carousel:hover,
.cb-widget .cb-carousel:focus,
.cb-widget .cb-carousel.rollover {
  background-color: #009cde;
  text-decoration: none;
}
.cb-widget .cb-carousel:focus {
  outline: 0;
  -moz-border-radius: 50%;
  border-radius: 50%;
  outline-style: solid;
  outline-color: #0077c8;
  outline-width: 1px;
}
.cb-widget .alert {
  padding: 15px;
  margin-bottom: 1.5em;
  border: 1px solid transparent;
  border-radius: 4px;
}
.cb-widget .alert h4 {
  margin-top: 0;
  color: inherit;
}
.cb-widget .alert .alert-link {
  font-weight: bold;
}
.cb-widget .alert > p,
.cb-widget .alert > ul {
  margin-bottom: 0;
}
.cb-widget .alert > p + p {
  margin-top: 5px;
}
.cb-widget .alert-dismissable,
.cb-widget .alert-dismissible {
  padding-right: 35px;
}
.cb-widget .alert-dismissable .close,
.cb-widget .alert-dismissible .close {
  position: relative;
  top: -2px;
  right: -21px;
  color: inherit;
}
.cb-widget .alert-success {
  background-color: #ffffff;
  border-color: #1d9582;
  color: #505050;
}
.cb-widget .alert-success hr {
  border-top-color: #19806f;
}
.cb-widget .alert-success .alert-link {
  color: #373737;
}
.cb-widget .alert-info {
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: #31708f;
}
.cb-widget .alert-info hr {
  border-top-color: #a6e1ec;
}
.cb-widget .alert-info .alert-link {
  color: #245269;
}
.cb-widget .alert-warning {
  background-color: #ffffff;
  border-color: #ff8000;
  color: #505050;
}
.cb-widget .alert-warning hr {
  border-top-color: #e67300;
}
.cb-widget .alert-warning .alert-link {
  color: #373737;
}
.cb-widget .alert-danger {
  background-color: #ffffff;
  border-color: #c13145;
  color: #c13145;
}
.cb-widget .alert-danger hr {
  border-top-color: #ad2c3e;
}
.cb-widget .alert-danger .alert-link {
  color: #982736;
}
.cb-widget .alert {
  border-radius: 0;
  padding: 20px 0 24px 0;
  margin-bottom: 24px;
  width: 100%;
  display: inline-block;
}
.cb-widget .alert .btn-minimal,
.cb-widget .alert .btn-secondary {
  float: right;
  margin-top: 24px;
}
.cb-widget .alert.alert-success {
  background-color: #ffffff;
  border: 0;
  border-top: 4px solid #1d9582;
  color: #505050;
}
.cb-widget .alert.alert-success.alert-dismissible {
  border: 1px solid #1d9582;
  border-top: 4px solid #1d9582;
}
.cb-widget .alert.alert-success.cb-alert-heading h2,
.cb-widget .alert.alert-success.cb-alert-heading h3,
.cb-widget .alert.alert-success.cb-alert-heading h4 {
  color: #1d9582;
  font-family: "Roboto Bold", sans-serif;
  font-size: 1.1875rem;
  line-height: 1.68421053em;
  position: relative;
  padding-left: 48px;
  margin-top: 0;
  margin-bottom: 16px;
  z-index: 1;
}
.cb-widget .alert.alert-success.cb-alert-heading h2::before,
.cb-widget .alert.alert-success.cb-alert-heading h3::before,
.cb-widget .alert.alert-success.cb-alert-heading h4::before {
  font-family: 'CB Icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  content: "\E90B";
  color: #ffffff;
  position: absolute;
  z-index: 2;
  left: 8px !important;
  top: 8px !important;
}
.cb-widget .alert.alert-success.cb-alert-heading h2::after,
.cb-widget .alert.alert-success.cb-alert-heading h3::after,
.cb-widget .alert.alert-success.cb-alert-heading h4::after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: block;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  background-color: #1d9582;
}
.cb-widget .alert.alert-success.cb-alert-heading p {
  padding-left: 48px;
}
.cb-widget .alert.alert-success hr {
  border-top-color: #19806f;
}
.cb-widget .alert.alert-success .alert-link {
  color: #0077c8;
}
.cb-widget .alert.alert-success .alert-link:hover {
  color: #0077c8;
}
.cb-widget .alert.alert-success p,
.cb-widget .alert.alert-success ul {
  font-size: 0.875rem;
  line-height: 1.71428571em;
}
.cb-widget .alert.alert-success .close {
  z-index: 2;
  right: -10px;
  top: -10px;
  width: 32px;
  height: 32px;
}
.cb-widget .alert.alert-success .close::before {
  font-family: 'CB Icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  content: "\E955";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.cb-widget .alert.alert-warning {
  background-color: #ffffff;
  border: 0;
  border-top: 4px solid #ff8000;
  color: #505050;
}
.cb-widget .alert.alert-warning.alert-dismissible {
  border: 1px solid #ff8000;
  border-top: 4px solid #ff8000;
}
.cb-widget .alert.alert-warning.cb-alert-heading h2,
.cb-widget .alert.alert-warning.cb-alert-heading h3,
.cb-widget .alert.alert-warning.cb-alert-heading h4 {
  color: #505050;
  font-family: "Roboto Bold", sans-serif;
  font-size: 1.1875rem;
  line-height: 1.68421053em;
  position: relative;
  padding-left: 48px;
  margin-top: 0;
  margin-bottom: 16px;
  z-index: 1;
}
.cb-widget .alert.alert-warning.cb-alert-heading h2::before,
.cb-widget .alert.alert-warning.cb-alert-heading h3::before,
.cb-widget .alert.alert-warning.cb-alert-heading h4::before {
  font-family: 'CB Icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  content: "\E919";
  color: #ffffff;
  position: absolute;
  z-index: 2;
  left: 8px !important;
  top: 8px !important;
}
.cb-widget .alert.alert-warning.cb-alert-heading h2::after,
.cb-widget .alert.alert-warning.cb-alert-heading h3::after,
.cb-widget .alert.alert-warning.cb-alert-heading h4::after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: block;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  background-color: #ff8000;
}
.cb-widget .alert.alert-warning.cb-alert-heading p {
  padding-left: 48px;
}
.cb-widget .alert.alert-warning hr {
  border-top-color: #e67300;
}
.cb-widget .alert.alert-warning .alert-link {
  color: #0077c8;
}
.cb-widget .alert.alert-warning .alert-link:hover {
  color: #0077c8;
}
.cb-widget .alert.alert-warning p,
.cb-widget .alert.alert-warning ul {
  font-size: 0.875rem;
  line-height: 1.71428571em;
}
.cb-widget .alert.alert-warning .close {
  z-index: 2;
  right: -10px;
  top: -10px;
  width: 32px;
  height: 32px;
}
.cb-widget .alert.alert-warning .close::before {
  font-family: 'CB Icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  content: "\E955";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.cb-widget .alert.alert-danger {
  background-color: #ffffff;
  border: 0;
  border-top: 4px solid #c13145;
  color: #c13145;
}
.cb-widget .alert.alert-danger.alert-dismissible {
  border: 1px solid #c13145;
  border-top: 4px solid #c13145;
}
.cb-widget .alert.alert-danger.cb-alert-heading h2,
.cb-widget .alert.alert-danger.cb-alert-heading h3,
.cb-widget .alert.alert-danger.cb-alert-heading h4 {
  color: #c13145;
  font-family: "Roboto Bold", sans-serif;
  font-size: 1.1875rem;
  line-height: 1.68421053em;
  position: relative;
  padding-left: 48px;
  margin-top: 0;
  margin-bottom: 16px;
  z-index: 1;
}
.cb-widget .alert.alert-danger.cb-alert-heading h2::before,
.cb-widget .alert.alert-danger.cb-alert-heading h3::before,
.cb-widget .alert.alert-danger.cb-alert-heading h4::before {
  font-family: 'CB Icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  content: "\E918";
  color: #ffffff;
  position: absolute;
  z-index: 2;
  left: 8px !important;
  top: 8px !important;
}
.cb-widget .alert.alert-danger.cb-alert-heading h2::after,
.cb-widget .alert.alert-danger.cb-alert-heading h3::after,
.cb-widget .alert.alert-danger.cb-alert-heading h4::after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: block;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  background-color: #c13145;
}
.cb-widget .alert.alert-danger.cb-alert-heading p {
  padding-left: 48px;
}
.cb-widget .alert.alert-danger hr {
  border-top-color: #ad2c3e;
}
.cb-widget .alert.alert-danger .alert-link {
  color: #0077c8;
}
.cb-widget .alert.alert-danger .alert-link:hover {
  color: #0077c8;
}
.cb-widget .alert.alert-danger p,
.cb-widget .alert.alert-danger ul {
  font-size: 0.875rem;
  line-height: 1.71428571em;
}
.cb-widget .alert.alert-danger .close {
  z-index: 2;
  right: -10px;
  top: -10px;
  width: 32px;
  height: 32px;
}
.cb-widget .alert.alert-danger .close::before {
  font-family: 'CB Icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  content: "\E955";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.cb-widget .alert.alert-danger .alert-link {
  text-decoration: underline;
  color: #c13145;
}
.cb-widget .alert.alert-danger .alert-link:hover {
  text-decoration: underline;
  color: #c13145;
}
.cb-widget .alert.alert-dismissible {
  padding: 20px 15px 23px 15px;
}
.cb-widget .alert .close {
  opacity: 1;
  filter: alpha(opacity=100);
}
.cb-widget .alert .close:hover,
.cb-widget .alert .close:focus {
  opacity: 1;
  filter: alpha(opacity=100);
}
.cb-widget .alert ul.cb-list-style {
  padding-left: 64px;
}
.cb-widget .alert > p + p {
  margin-top: 16px;
}
.cb-widget .close {
  float: right;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.2;
  filter: alpha(opacity=20);
}
.cb-widget .close:hover,
.cb-widget .close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.cb-widget button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}
.cb-widget * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.cb-widget *:before,
.cb-widget *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.cb-widget html {
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.cb-widget body {
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  line-height: 1.5em;
  color: #505050;
  background-color: #fff;
}
.cb-widget input,
.cb-widget button,
.cb-widget select,
.cb-widget textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.cb-widget a {
  color: #0077c8;
  text-decoration: none;
}
.cb-widget a:hover,
.cb-widget a:focus {
  color: #0077c8;
  text-decoration: underline;
}
.cb-widget a:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.cb-widget figure {
  margin: 0;
}
.cb-widget img {
  vertical-align: middle;
}
.cb-widget .img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}
.cb-widget .img-rounded {
  border-radius: 6px;
}
.cb-widget .img-thumbnail {
  padding: 4px;
  line-height: 1.5em;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  max-width: 100%;
  height: auto;
}
.cb-widget .img-circle {
  border-radius: 50%;
}
.cb-widget hr {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  border: 0;
  border-top: 1px solid #eeeeee;
}
.cb-widget .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.cb-widget .sr-only-focusable:active,
.cb-widget .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}
.cb-widget [role="button"] {
  cursor: pointer;
}
.cb-widget html {
  font-size: 16px;
  line-height: 1.5em;
}
.cb-widget p {
  font-size: 1rem;
  line-height: 1.5em;
}
.cb-widget p,
.cb-widget ul,
.cb-widget ol {
  margin-bottom: 1.5em;
}
.cb-widget hr {
  margin-top: 1.4375em;
  margin-bottom: 1.5em;
  border-top: 1px solid #d9d9d9;
}
.cb-widget ul.cb-list-style li {
  list-style: disc;
}
.cb-widget ul.cb-list-style ul li {
  list-style: circle;
}
.cb-widget ul.cb-list-style ul ul li {
  text-indent: -5px;
  list-style-type: none;
}
.cb-widget ul.cb-list-style ul ul li::before {
  content: "-";
  position: relative;
  left: -12px;
}
.cb-widget ul.cb-list-style-checkbox li {
  list-style: none !important;
  position: relative;
  padding-left: 8px;
}
.cb-widget ul.cb-list-style-checkbox li::before {
  font-family: 'CB Icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  position: absolute;
  left: -17px;
  top: 4px;
  content: "\E95A";
}
.cb-widget ul.cb-list-no-style,
.cb-widget ul.cb-no-style {
  list-style: none;
}
.cb-widget ul.cb-list-no-style li,
.cb-widget ul.cb-no-style li {
  list-style: none;
}
.cb-widget ul.cb-list-no-indent {
  padding-left: 0;
}
.cb-widget ul.cb-list-no-indent li {
  margin-left: 18px;
}
.cb-widget ul.cb-list-no-indent.cb-list-no-style li {
  margin-left: 0;
}
.cb-widget ol.cb-list-no-indent {
  padding-left: 0;
}
.cb-widget ol.cb-list-no-indent li {
  margin-left: 18px;
}
.cb-widget body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: 'SerifaStd-Light';
  src: url(https://atlas.collegeboard.org/widgets/release/2020-08-31/f513383ae0a9016f0055196b9a835308.eot);
  src: url(https://atlas.collegeboard.org/widgets/release/2020-08-31/f513383ae0a9016f0055196b9a835308.eot?#iefix) format('embedded-opentype'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/ed22c81e7e3641d5f9f6ce998e64edd1.woff) format('woff'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/60aaa73fee2aff07cbfebe92895ada6d.ttf) format('truetype'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/c651c3e22d37d4df7d0b20f1c8019c68.svg#SerifaStd45Light) format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'SerifaStd-Roman';
  src: url(https://atlas.collegeboard.org/widgets/release/2020-08-31/5da2a6768814af623d98ad29b0a12724.eot);
  src: url(https://atlas.collegeboard.org/widgets/release/2020-08-31/5da2a6768814af623d98ad29b0a12724.eot?#iefix) format('embedded-opentype'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/f9d0c7ef5d7002c6093deb0c156bd09b.woff) format('woff'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/ee829afcc6bdeb795a3225b169b6a48f.ttf) format('truetype'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/b4b4f998a1db4c1c4a554c2412d01517.svg#SerifaStd55Roman) format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'SerifaStd-Bold';
  src: url(https://atlas.collegeboard.org/widgets/release/2020-08-31/8c198e987200a2a3aeb2f07a5eb25b35.eot);
  src: url(https://atlas.collegeboard.org/widgets/release/2020-08-31/8c198e987200a2a3aeb2f07a5eb25b35.eot?#iefix) format('embedded-opentype'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/6157d1853ff50edf030826880eb86f12.woff) format('woff'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/165fd155212756f3e0c861be239879fb.ttf) format('truetype'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/c2273df4ccb4f5ffea4e253983d72e79.svg#SerifaStd65Bold) format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'RobotoSlab-Light';
  src: url(https://atlas.collegeboard.org/widgets/release/2020-08-31/b569b346849bdc37fb6aaf74751e9867.eot);
  src: url(https://atlas.collegeboard.org/widgets/release/2020-08-31/b569b346849bdc37fb6aaf74751e9867.eot?#iefix) format('embedded-opentype'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/85efc5670f0a0b323f0846527a9f9638.woff) format('woff'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/79754934891c17dd798ca5e7eb5fa9a9.ttf) format('truetype'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/ea04aa2fdd4bf8dea30607f7bc70509d.svg#) format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url(https://atlas.collegeboard.org/widgets/release/2020-08-31/faa0a861a79f5385c2516cab575b3a79.eot);
  src: local('Roboto'), local('Roboto-Regular'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/faa0a861a79f5385c2516cab575b3a79.eot?#iefix) format('embedded-opentype'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/bb5b5cb817d0ff65062e0b924dd46608.woff2) format('woff'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/0e45c141d5e10dd53685e862215443a6.woff) format('woff'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/0fb3e89257a599dd9418fadd60c47115.ttf) format('truetype'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/11d2d2cc003065e30ed2873e904b2fc3.svg#Roboto-Regular) format('svg');
  font-weight: 'normal';
  font-style: normal;
}
@font-face {
  font-family: 'Roboto Bold';
  src: url(https://atlas.collegeboard.org/widgets/release/2020-08-31/cdba55f2578dfb7c69a3a12923bb107e.eot);
  src: local('Roboto Bold'), local('Roboto-Bold'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/cdba55f2578dfb7c69a3a12923bb107e.eot?#iefix) format('embedded-opentype'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/e1acd88558cab0b1eaec51ae53330a39.woff2) format('woff'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/6403f00b41f12a74b14c9c9f8972e341.woff) format('woff'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/3edb58a8f11025383f8db89b1ef583d2.ttf) format('truetype'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/a1c0db53220097b9df89c8b99b292b33.svg#Roboto-Bold) format('svg');
  font-weight: 'bold';
  font-style: normal;
}
@font-face {
  font-family: 'Roboto Italic';
  src: url(https://atlas.collegeboard.org/widgets/release/2020-08-31/ceca5bb409e1682e410cb760e50d1273.eot);
  src: local('Roboto Italic'), local('Roboto-Italic'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/ceca5bb409e1682e410cb760e50d1273.eot?#iefix) format('embedded-opentype'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/336d58c849986eb8829ceb362b4353d1.woff2) format('woff'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/30183630b45058b7089f1111c2723026.woff) format('woff'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/4ae0b2f98fcf6d76a1eac1c638beff59.ttf) format('truetype'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/e881cf5b15656d97e3e3639764a81adb.svg#Roboto-Italic) format('svg');
  font-weight: 'normal';
  font-style: 'italic';
}
@font-face {
  font-family: 'Roboto Slab';
  src: url(https://atlas.collegeboard.org/widgets/release/2020-08-31/4cee134c8138edd168884808d266f06b.eot);
  src: local('Roboto Slab'), local('RobotoSlab-Regular'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/4cee134c8138edd168884808d266f06b.eot?#iefix) format('embedded-opentype'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/c2795d67e997a2e44d515c90bc6cf4e2.woff2) format('woff'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/ea972456b3f3e1a09d82a2fe6740325a.woff) format('woff'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/fbc04291d1c94d7c434e0efefb12cf7c.ttf) format('truetype'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/87c19640e43cd1191e02d1b3f6c7f46f.svg#RobotoSlab-Regular) format('svg');
  font-weight: 'normal';
  font-style: normal;
}
@font-face {
  font-family: 'Roboto Slab Bold';
  src: url(https://atlas.collegeboard.org/widgets/release/2020-08-31/333040050ff115b6504a085d06cad84f.eot);
  src: local('Roboto Slab Bold'), local('RobotoSlab-Bold'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/333040050ff115b6504a085d06cad84f.eot?#iefix) format('embedded-opentype'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/80ae951d41b5dab8e080034e66092cbf.woff2) format('woff'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/35357db32f084cd6cc6ff0bcd1976f0d.woff) format('woff'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/2158a2c8ad0e6c2e0ec3691e6a18c2e9.ttf) format('truetype'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/a4245b827d6bbcca4941183fa6e0cd3a.svg#RobotoSlab-Bold) format('svg');
  font-weight: 'bold';
  font-style: normal;
}
@font-face {
  font-family: 'CB Icons';
  src: url(https://atlas.collegeboard.org/widgets/release/2020-08-31/0547390b58b9bc0ce83afb2581783546.eot);
  src: url(https://atlas.collegeboard.org/widgets/release/2020-08-31/0547390b58b9bc0ce83afb2581783546.eot?#iefix) format('embedded-opentype'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/5b02621da1e318e427d3b195a7553417.woff) format('woff'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/20c165d3fb79930a18003c4f7ff8be02.ttf) format('truetype'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/27f9deed176bc0ac773a92e50b772c85.svg#cb-icons-font-svg-id) format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'CB Icons Multi';
  src: url(https://atlas.collegeboard.org/widgets/release/2020-08-31/11c3beb5ecea123534d1e41a735ffcce.eot);
  src: url(https://atlas.collegeboard.org/widgets/release/2020-08-31/11c3beb5ecea123534d1e41a735ffcce.eot?#iefix) format('embedded-opentype'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/86683b0b54983f5174a98375ff8bc49f.woff) format('woff'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/c653501987fe371f8b61e9b5686029c4.ttf) format('truetype'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/316678c4c66e18cd0aa1819654152802.svg#cb-icons-multi-font-svg-id) format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'CB Icons Logo';
  src: url(https://atlas.collegeboard.org/widgets/release/2020-08-31/284a7b630deca07fc6f3902b7e6e1621.eot);
  src: url(https://atlas.collegeboard.org/widgets/release/2020-08-31/284a7b630deca07fc6f3902b7e6e1621.eot?#iefix) format('embedded-opentype'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/2d5a29242f53357e8e9f1e86905ef5a0.woff) format('woff'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/237e385d8f54a2fe4da75867d2ba837f.ttf) format('truetype'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/8c001efe3b7fd118568d27423ecabd17.svg#cb-icons-logo-font-svg-id) format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Glyphicons Halflings';
  src: url(https://atlas.collegeboard.org/widgets/release/2020-08-31/f4769f9bdb7466be65088239c12046d1.eot);
  src: url(https://atlas.collegeboard.org/widgets/release/2020-08-31/f4769f9bdb7466be65088239c12046d1.eot?#iefix) format('embedded-opentype'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/448c34a56d699c29117adc64c43affeb.woff2) format('woff2'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/fa2772327f55d8198301fdb8bcfc8158.woff) format('woff'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/e18bbf611f2a2e43afc071aa2f4e1512.ttf) format('truetype'), url(https://atlas.collegeboard.org/widgets/release/2020-08-31/89889688147bd7575d6327160d64e760.svg#glyphicons_halflingsregular) format('svg');
}
.cb-widget .glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.cb-widget .glyphicon-asterisk:before {
  content: "*";
}
.cb-widget .glyphicon-plus:before {
  content: "+";
}
.cb-widget .glyphicon-euro:before,
.cb-widget .glyphicon-eur:before {
  content: "\20AC";
}
.cb-widget .glyphicon-minus:before {
  content: "\2212";
}
.cb-widget .glyphicon-cloud:before {
  content: "\2601";
}
.cb-widget .glyphicon-envelope:before {
  content: "\2709";
}
.cb-widget .glyphicon-pencil:before {
  content: "\270F";
}
.cb-widget .glyphicon-glass:before {
  content: "\E001";
}
.cb-widget .glyphicon-music:before {
  content: "\E002";
}
.cb-widget .glyphicon-search:before {
  content: "\E003";
}
.cb-widget .glyphicon-heart:before {
  content: "\E005";
}
.cb-widget .glyphicon-star:before {
  content: "\E006";
}
.cb-widget .glyphicon-star-empty:before {
  content: "\E007";
}
.cb-widget .glyphicon-user:before {
  content: "\E008";
}
.cb-widget .glyphicon-film:before {
  content: "\E009";
}
.cb-widget .glyphicon-th-large:before {
  content: "\E010";
}
.cb-widget .glyphicon-th:before {
  content: "\E011";
}
.cb-widget .glyphicon-th-list:before {
  content: "\E012";
}
.cb-widget .glyphicon-ok:before {
  content: "\E013";
}
.cb-widget .glyphicon-remove:before {
  content: "\E014";
}
.cb-widget .glyphicon-zoom-in:before {
  content: "\E015";
}
.cb-widget .glyphicon-zoom-out:before {
  content: "\E016";
}
.cb-widget .glyphicon-off:before {
  content: "\E017";
}
.cb-widget .glyphicon-signal:before {
  content: "\E018";
}
.cb-widget .glyphicon-cog:before {
  content: "\E019";
}
.cb-widget .glyphicon-trash:before {
  content: "\E020";
}
.cb-widget .glyphicon-home:before {
  content: "\E021";
}
.cb-widget .glyphicon-file:before {
  content: "\E022";
}
.cb-widget .glyphicon-time:before {
  content: "\E023";
}
.cb-widget .glyphicon-road:before {
  content: "\E024";
}
.cb-widget .glyphicon-download-alt:before {
  content: "\E025";
}
.cb-widget .glyphicon-download:before {
  content: "\E026";
}
.cb-widget .glyphicon-upload:before {
  content: "\E027";
}
.cb-widget .glyphicon-inbox:before {
  content: "\E028";
}
.cb-widget .glyphicon-play-circle:before {
  content: "\E029";
}
.cb-widget .glyphicon-repeat:before {
  content: "\E030";
}
.cb-widget .glyphicon-refresh:before {
  content: "\E031";
}
.cb-widget .glyphicon-list-alt:before {
  content: "\E032";
}
.cb-widget .glyphicon-lock:before {
  content: "\E033";
}
.cb-widget .glyphicon-flag:before {
  content: "\E034";
}
.cb-widget .glyphicon-headphones:before {
  content: "\E035";
}
.cb-widget .glyphicon-volume-off:before {
  content: "\E036";
}
.cb-widget .glyphicon-volume-down:before {
  content: "\E037";
}
.cb-widget .glyphicon-volume-up:before {
  content: "\E038";
}
.cb-widget .glyphicon-qrcode:before {
  content: "\E039";
}
.cb-widget .glyphicon-barcode:before {
  content: "\E040";
}
.cb-widget .glyphicon-tag:before {
  content: "\E041";
}
.cb-widget .glyphicon-tags:before {
  content: "\E042";
}
.cb-widget .glyphicon-book:before {
  content: "\E043";
}
.cb-widget .glyphicon-bookmark:before {
  content: "\E044";
}
.cb-widget .glyphicon-print:before {
  content: "\E045";
}
.cb-widget .glyphicon-camera:before {
  content: "\E046";
}
.cb-widget .glyphicon-font:before {
  content: "\E047";
}
.cb-widget .glyphicon-bold:before {
  content: "\E048";
}
.cb-widget .glyphicon-italic:before {
  content: "\E049";
}
.cb-widget .glyphicon-text-height:before {
  content: "\E050";
}
.cb-widget .glyphicon-text-width:before {
  content: "\E051";
}
.cb-widget .glyphicon-align-left:before {
  content: "\E052";
}
.cb-widget .glyphicon-align-center:before {
  content: "\E053";
}
.cb-widget .glyphicon-align-right:before {
  content: "\E054";
}
.cb-widget .glyphicon-align-justify:before {
  content: "\E055";
}
.cb-widget .glyphicon-list:before {
  content: "\E056";
}
.cb-widget .glyphicon-indent-left:before {
  content: "\E057";
}
.cb-widget .glyphicon-indent-right:before {
  content: "\E058";
}
.cb-widget .glyphicon-facetime-video:before {
  content: "\E059";
}
.cb-widget .glyphicon-picture:before {
  content: "\E060";
}
.cb-widget .glyphicon-map-marker:before {
  content: "\E062";
}
.cb-widget .glyphicon-adjust:before {
  content: "\E063";
}
.cb-widget .glyphicon-tint:before {
  content: "\E064";
}
.cb-widget .glyphicon-edit:before {
  content: "\E065";
}
.cb-widget .glyphicon-share:before {
  content: "\E066";
}
.cb-widget .glyphicon-check:before {
  content: "\E067";
}
.cb-widget .glyphicon-move:before {
  content: "\E068";
}
.cb-widget .glyphicon-step-backward:before {
  content: "\E069";
}
.cb-widget .glyphicon-fast-backward:before {
  content: "\E070";
}
.cb-widget .glyphicon-backward:before {
  content: "\E071";
}
.cb-widget .glyphicon-play:before {
  content: "\E072";
}
.cb-widget .glyphicon-pause:before {
  content: "\E073";
}
.cb-widget .glyphicon-stop:before {
  content: "\E074";
}
.cb-widget .glyphicon-forward:before {
  content: "\E075";
}
.cb-widget .glyphicon-fast-forward:before {
  content: "\E076";
}
.cb-widget .glyphicon-step-forward:before {
  content: "\E077";
}
.cb-widget .glyphicon-eject:before {
  content: "\E078";
}
.cb-widget .glyphicon-chevron-left:before {
  content: "\E079";
}
.cb-widget .glyphicon-chevron-right:before {
  content: "\E080";
}
.cb-widget .glyphicon-plus-sign:before {
  content: "\E081";
}
.cb-widget .glyphicon-minus-sign:before {
  content: "\E082";
}
.cb-widget .glyphicon-remove-sign:before {
  content: "\E083";
}
.cb-widget .glyphicon-ok-sign:before {
  content: "\E084";
}
.cb-widget .glyphicon-question-sign:before {
  content: "\E085";
}
.cb-widget .glyphicon-info-sign:before {
  content: "\E086";
}
.cb-widget .glyphicon-screenshot:before {
  content: "\E087";
}
.cb-widget .glyphicon-remove-circle:before {
  content: "\E088";
}
.cb-widget .glyphicon-ok-circle:before {
  content: "\E089";
}
.cb-widget .glyphicon-ban-circle:before {
  content: "\E090";
}
.cb-widget .glyphicon-arrow-left:before {
  content: "\E091";
}
.cb-widget .glyphicon-arrow-right:before {
  content: "\E092";
}
.cb-widget .glyphicon-arrow-up:before {
  content: "\E093";
}
.cb-widget .glyphicon-arrow-down:before {
  content: "\E094";
}
.cb-widget .glyphicon-share-alt:before {
  content: "\E095";
}
.cb-widget .glyphicon-resize-full:before {
  content: "\E096";
}
.cb-widget .glyphicon-resize-small:before {
  content: "\E097";
}
.cb-widget .glyphicon-exclamation-sign:before {
  content: "\E101";
}
.cb-widget .glyphicon-gift:before {
  content: "\E102";
}
.cb-widget .glyphicon-leaf:before {
  content: "\E103";
}
.cb-widget .glyphicon-fire:before {
  content: "\E104";
}
.cb-widget .glyphicon-eye-open:before {
  content: "\E105";
}
.cb-widget .glyphicon-eye-close:before {
  content: "\E106";
}
.cb-widget .glyphicon-warning-sign:before {
  content: "\E107";
}
.cb-widget .glyphicon-plane:before {
  content: "\E108";
}
.cb-widget .glyphicon-calendar:before {
  content: "\E109";
}
.cb-widget .glyphicon-random:before {
  content: "\E110";
}
.cb-widget .glyphicon-comment:before {
  content: "\E111";
}
.cb-widget .glyphicon-magnet:before {
  content: "\E112";
}
.cb-widget .glyphicon-chevron-up:before {
  content: "\E113";
}
.cb-widget .glyphicon-chevron-down:before {
  content: "\E114";
}
.cb-widget .glyphicon-retweet:before {
  content: "\E115";
}
.cb-widget .glyphicon-shopping-cart:before {
  content: "\E116";
}
.cb-widget .glyphicon-folder-close:before {
  content: "\E117";
}
.cb-widget .glyphicon-folder-open:before {
  content: "\E118";
}
.cb-widget .glyphicon-resize-vertical:before {
  content: "\E119";
}
.cb-widget .glyphicon-resize-horizontal:before {
  content: "\E120";
}
.cb-widget .glyphicon-hdd:before {
  content: "\E121";
}
.cb-widget .glyphicon-bullhorn:before {
  content: "\E122";
}
.cb-widget .glyphicon-bell:before {
  content: "\E123";
}
.cb-widget .glyphicon-certificate:before {
  content: "\E124";
}
.cb-widget .glyphicon-thumbs-up:before {
  content: "\E125";
}
.cb-widget .glyphicon-thumbs-down:before {
  content: "\E126";
}
.cb-widget .glyphicon-hand-right:before {
  content: "\E127";
}
.cb-widget .glyphicon-hand-left:before {
  content: "\E128";
}
.cb-widget .glyphicon-hand-up:before {
  content: "\E129";
}
.cb-widget .glyphicon-hand-down:before {
  content: "\E130";
}
.cb-widget .glyphicon-circle-arrow-right:before {
  content: "\E131";
}
.cb-widget .glyphicon-circle-arrow-left:before {
  content: "\E132";
}
.cb-widget .glyphicon-circle-arrow-up:before {
  content: "\E133";
}
.cb-widget .glyphicon-circle-arrow-down:before {
  content: "\E134";
}
.cb-widget .glyphicon-globe:before {
  content: "\E135";
}
.cb-widget .glyphicon-wrench:before {
  content: "\E136";
}
.cb-widget .glyphicon-tasks:before {
  content: "\E137";
}
.cb-widget .glyphicon-filter:before {
  content: "\E138";
}
.cb-widget .glyphicon-briefcase:before {
  content: "\E139";
}
.cb-widget .glyphicon-fullscreen:before {
  content: "\E140";
}
.cb-widget .glyphicon-dashboard:before {
  content: "\E141";
}
.cb-widget .glyphicon-paperclip:before {
  content: "\E142";
}
.cb-widget .glyphicon-heart-empty:before {
  content: "\E143";
}
.cb-widget .glyphicon-link:before {
  content: "\E144";
}
.cb-widget .glyphicon-phone:before {
  content: "\E145";
}
.cb-widget .glyphicon-pushpin:before {
  content: "\E146";
}
.cb-widget .glyphicon-usd:before {
  content: "\E148";
}
.cb-widget .glyphicon-gbp:before {
  content: "\E149";
}
.cb-widget .glyphicon-sort:before {
  content: "\E150";
}
.cb-widget .glyphicon-sort-by-alphabet:before {
  content: "\E151";
}
.cb-widget .glyphicon-sort-by-alphabet-alt:before {
  content: "\E152";
}
.cb-widget .glyphicon-sort-by-order:before {
  content: "\E153";
}
.cb-widget .glyphicon-sort-by-order-alt:before {
  content: "\E154";
}
.cb-widget .glyphicon-sort-by-attributes:before {
  content: "\E155";
}
.cb-widget .glyphicon-sort-by-attributes-alt:before {
  content: "\E156";
}
.cb-widget .glyphicon-unchecked:before {
  content: "\E157";
}
.cb-widget .glyphicon-expand:before {
  content: "\E158";
}
.cb-widget .glyphicon-collapse-down:before {
  content: "\E159";
}
.cb-widget .glyphicon-collapse-up:before {
  content: "\E160";
}
.cb-widget .glyphicon-log-in:before {
  content: "\E161";
}
.cb-widget .glyphicon-flash:before {
  content: "\E162";
}
.cb-widget .glyphicon-log-out:before {
  content: "\E163";
}
.cb-widget .glyphicon-new-window:before {
  content: "\E164";
}
.cb-widget .glyphicon-record:before {
  content: "\E165";
}
.cb-widget .glyphicon-save:before {
  content: "\E166";
}
.cb-widget .glyphicon-open:before {
  content: "\E167";
}
.cb-widget .glyphicon-saved:before {
  content: "\E168";
}
.cb-widget .glyphicon-import:before {
  content: "\E169";
}
.cb-widget .glyphicon-export:before {
  content: "\E170";
}
.cb-widget .glyphicon-send:before {
  content: "\E171";
}
.cb-widget .glyphicon-floppy-disk:before {
  content: "\E172";
}
.cb-widget .glyphicon-floppy-saved:before {
  content: "\E173";
}
.cb-widget .glyphicon-floppy-remove:before {
  content: "\E174";
}
.cb-widget .glyphicon-floppy-save:before {
  content: "\E175";
}
.cb-widget .glyphicon-floppy-open:before {
  content: "\E176";
}
.cb-widget .glyphicon-credit-card:before {
  content: "\E177";
}
.cb-widget .glyphicon-transfer:before {
  content: "\E178";
}
.cb-widget .glyphicon-cutlery:before {
  content: "\E179";
}
.cb-widget .glyphicon-header:before {
  content: "\E180";
}
.cb-widget .glyphicon-compressed:before {
  content: "\E181";
}
.cb-widget .glyphicon-earphone:before {
  content: "\E182";
}
.cb-widget .glyphicon-phone-alt:before {
  content: "\E183";
}
.cb-widget .glyphicon-tower:before {
  content: "\E184";
}
.cb-widget .glyphicon-stats:before {
  content: "\E185";
}
.cb-widget .glyphicon-sd-video:before {
  content: "\E186";
}
.cb-widget .glyphicon-hd-video:before {
  content: "\E187";
}
.cb-widget .glyphicon-subtitles:before {
  content: "\E188";
}
.cb-widget .glyphicon-sound-stereo:before {
  content: "\E189";
}
.cb-widget .glyphicon-sound-dolby:before {
  content: "\E190";
}
.cb-widget .glyphicon-sound-5-1:before {
  content: "\E191";
}
.cb-widget .glyphicon-sound-6-1:before {
  content: "\E192";
}
.cb-widget .glyphicon-sound-7-1:before {
  content: "\E193";
}
.cb-widget .glyphicon-copyright-mark:before {
  content: "\E194";
}
.cb-widget .glyphicon-registration-mark:before {
  content: "\E195";
}
.cb-widget .glyphicon-cloud-download:before {
  content: "\E197";
}
.cb-widget .glyphicon-cloud-upload:before {
  content: "\E198";
}
.cb-widget .glyphicon-tree-conifer:before {
  content: "\E199";
}
.cb-widget .glyphicon-tree-deciduous:before {
  content: "\E200";
}
.cb-widget .glyphicon-cd:before {
  content: "\E201";
}
.cb-widget .glyphicon-save-file:before {
  content: "\E202";
}
.cb-widget .glyphicon-open-file:before {
  content: "\E203";
}
.cb-widget .glyphicon-level-up:before {
  content: "\E204";
}
.cb-widget .glyphicon-copy:before {
  content: "\E205";
}
.cb-widget .glyphicon-paste:before {
  content: "\E206";
}
.cb-widget .glyphicon-alert:before {
  content: "\E209";
}
.cb-widget .glyphicon-equalizer:before {
  content: "\E210";
}
.cb-widget .glyphicon-king:before {
  content: "\E211";
}
.cb-widget .glyphicon-queen:before {
  content: "\E212";
}
.cb-widget .glyphicon-pawn:before {
  content: "\E213";
}
.cb-widget .glyphicon-bishop:before {
  content: "\E214";
}
.cb-widget .glyphicon-knight:before {
  content: "\E215";
}
.cb-widget .glyphicon-baby-formula:before {
  content: "\E216";
}
.cb-widget .glyphicon-tent:before {
  content: "\26FA";
}
.cb-widget .glyphicon-blackboard:before {
  content: "\E218";
}
.cb-widget .glyphicon-bed:before {
  content: "\E219";
}
.cb-widget .glyphicon-apple:before {
  content: "\F8FF";
}
.cb-widget .glyphicon-erase:before {
  content: "\E221";
}
.cb-widget .glyphicon-hourglass:before {
  content: "\231B";
}
.cb-widget .glyphicon-lamp:before {
  content: "\E223";
}
.cb-widget .glyphicon-duplicate:before {
  content: "\E224";
}
.cb-widget .glyphicon-piggy-bank:before {
  content: "\E225";
}
.cb-widget .glyphicon-scissors:before {
  content: "\E226";
}
.cb-widget .glyphicon-bitcoin:before {
  content: "\E227";
}
.cb-widget .glyphicon-btc:before {
  content: "\E227";
}
.cb-widget .glyphicon-xbt:before {
  content: "\E227";
}
.cb-widget .glyphicon-yen:before {
  content: "\A5";
}
.cb-widget .glyphicon-jpy:before {
  content: "\A5";
}
.cb-widget .glyphicon-ruble:before {
  content: "\20BD";
}
.cb-widget .glyphicon-rub:before {
  content: "\20BD";
}
.cb-widget .glyphicon-scale:before {
  content: "\E230";
}
.cb-widget .glyphicon-ice-lolly:before {
  content: "\E231";
}
.cb-widget .glyphicon-ice-lolly-tasted:before {
  content: "\E232";
}
.cb-widget .glyphicon-education:before {
  content: "\E233";
}
.cb-widget .glyphicon-option-horizontal:before {
  content: "\E234";
}
.cb-widget .glyphicon-option-vertical:before {
  content: "\E235";
}
.cb-widget .glyphicon-menu-hamburger:before {
  content: "\E236";
}
.cb-widget .glyphicon-modal-window:before {
  content: "\E237";
}
.cb-widget .glyphicon-oil:before {
  content: "\E238";
}
.cb-widget .glyphicon-grain:before {
  content: "\E239";
}
.cb-widget .glyphicon-sunglasses:before {
  content: "\E240";
}
.cb-widget .glyphicon-text-size:before {
  content: "\E241";
}
.cb-widget .glyphicon-text-color:before {
  content: "\E242";
}
.cb-widget .glyphicon-text-background:before {
  content: "\E243";
}
.cb-widget .glyphicon-object-align-top:before {
  content: "\E244";
}
.cb-widget .glyphicon-object-align-bottom:before {
  content: "\E245";
}
.cb-widget .glyphicon-object-align-horizontal:before {
  content: "\E246";
}
.cb-widget .glyphicon-object-align-left:before {
  content: "\E247";
}
.cb-widget .glyphicon-object-align-vertical:before {
  content: "\E248";
}
.cb-widget .glyphicon-object-align-right:before {
  content: "\E249";
}
.cb-widget .glyphicon-triangle-right:before {
  content: "\E250";
}
.cb-widget .glyphicon-triangle-left:before {
  content: "\E251";
}
.cb-widget .glyphicon-triangle-bottom:before {
  content: "\E252";
}
.cb-widget .glyphicon-triangle-top:before {
  content: "\E253";
}
.cb-widget .glyphicon-console:before {
  content: "\E254";
}
.cb-widget .glyphicon-superscript:before {
  content: "\E255";
}
.cb-widget .glyphicon-subscript:before {
  content: "\E256";
}
.cb-widget .glyphicon-menu-left:before {
  content: "\E257";
}
.cb-widget .glyphicon-menu-right:before {
  content: "\E258";
}
.cb-widget .glyphicon-menu-down:before {
  content: "\E259";
}
.cb-widget .glyphicon-menu-up:before {
  content: "\E260";
}
.cb-widget [class^="cb-icon-"],
.cb-widget [class*=" cb-icon-"],
.cb-widget .glyphicon-cb,
.cb-widget .cb-glyph:not(.cb-glyph-numeric) {
  font-family: 'CB Icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.cb-widget .cb-glyph-numeric {
  display: inline-block;
  border-radius: 50%;
  width: 2em;
  height: 2em;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  line-height: 1.82em !important;
  text-align: center;
  font-size: 1rem;
  border: solid 0.125em #505050;
}
.cb-widget .cb-glyph-numeric::before {
  content: attr(data-cb-number);
}
.cb-widget .cb-glyph-numeric.cb-glyph-circular {
  color: #ffffff;
  border-color: #009cde;
  background-color: #009cde;
}
.cb-widget .cb-glyph.cb-glyph-xs,
.cb-widget .cb-glyph-numeric.cb-glyph-xs {
  font-size: 1rem;
}
.cb-widget .cb-glyph.cb-glyph-sm,
.cb-widget .cb-glyph-numeric.cb-glyph-sm {
  font-size: calc(1rem * 2) !important;
}
.cb-widget .cb-glyph.cb-glyph-md,
.cb-widget .cb-glyph-numeric.cb-glyph-md {
  font-size: calc(1rem * 3) !important;
}
.cb-widget .cb-glyph.cb-glyph-lg,
.cb-widget .cb-glyph-numeric.cb-glyph-lg {
  font-size: calc(1rem * 4) !important;
}
.cb-widget .cb-glyph.cb-glyph-xl,
.cb-widget .cb-glyph-numeric.cb-glyph-xl {
  font-size: calc(1rem * 6) !important;
}
.cb-widget .cb-glyph.cb-glyph-corporate,
.cb-widget .cb-glyph-numeric.cb-glyph-corporate {
  color: #006298;
}
.cb-widget .cb-glyph.cb-glyph-corporate.cb-glyph-numeric,
.cb-widget .cb-glyph-numeric.cb-glyph-corporate.cb-glyph-numeric {
  border-color: #006298;
}
.cb-widget .cb-glyph.cb-glyph-corporate.cb-glyph-numeric.cb-glyph-circular,
.cb-widget .cb-glyph-numeric.cb-glyph-corporate.cb-glyph-numeric.cb-glyph-circular {
  color: #ffffff;
  border-color: #006298;
  background-color: #006298;
}
.cb-widget .cb-glyph.cb-glyph-corporate2,
.cb-widget .cb-glyph-numeric.cb-glyph-corporate2 {
  color: #0077c8;
}
.cb-widget .cb-glyph.cb-glyph-corporate2.cb-glyph-numeric,
.cb-widget .cb-glyph-numeric.cb-glyph-corporate2.cb-glyph-numeric {
  border-color: #0077c8;
}
.cb-widget .cb-glyph.cb-glyph-corporate2.cb-glyph-numeric.cb-glyph-circular,
.cb-widget .cb-glyph-numeric.cb-glyph-corporate2.cb-glyph-numeric.cb-glyph-circular {
  color: #ffffff;
  border-color: #0077c8;
  background-color: #0077c8;
}
.cb-widget .cb-glyph.cb-glyph-k-12,
.cb-widget .cb-glyph-numeric.cb-glyph-k-12 {
  color: #009cde;
}
.cb-widget .cb-glyph.cb-glyph-k-12.cb-glyph-numeric,
.cb-widget .cb-glyph-numeric.cb-glyph-k-12.cb-glyph-numeric {
  border-color: #009cde;
}
.cb-widget .cb-glyph.cb-glyph-k-12.cb-glyph-numeric.cb-glyph-circular,
.cb-widget .cb-glyph-numeric.cb-glyph-k-12.cb-glyph-numeric.cb-glyph-circular {
  color: #ffffff;
  border-color: #009cde;
  background-color: #009cde;
}
.cb-widget .cb-glyph.cb-glyph-higher-education,
.cb-widget .cb-glyph-numeric.cb-glyph-higher-education {
  color: #702f8a;
}
.cb-widget .cb-glyph.cb-glyph-higher-education.cb-glyph-numeric,
.cb-widget .cb-glyph-numeric.cb-glyph-higher-education.cb-glyph-numeric {
  border-color: #702f8a;
}
.cb-widget .cb-glyph.cb-glyph-higher-education.cb-glyph-numeric.cb-glyph-circular,
.cb-widget .cb-glyph-numeric.cb-glyph-higher-education.cb-glyph-numeric.cb-glyph-circular {
  color: #ffffff;
  border-color: #702f8a;
  background-color: #702f8a;
}
.cb-widget .cb-glyph.cb-glyph-access,
.cb-widget .cb-glyph-numeric.cb-glyph-access {
  color: #3a913f;
}
.cb-widget .cb-glyph.cb-glyph-access.cb-glyph-numeric,
.cb-widget .cb-glyph-numeric.cb-glyph-access.cb-glyph-numeric {
  border-color: #3a913f;
}
.cb-widget .cb-glyph.cb-glyph-access.cb-glyph-numeric.cb-glyph-circular,
.cb-widget .cb-glyph-numeric.cb-glyph-access.cb-glyph-numeric.cb-glyph-circular {
  color: #ffffff;
  border-color: #3a913f;
  background-color: #3a913f;
}
.cb-widget .cb-glyph-circular:not(.cb-glyph-numeric) {
  position: relative;
  display: block;
  height: 2em;
  width: 2em;
}
.cb-widget .cb-glyph-circular:not(.cb-glyph-numeric)::before {
  position: absolute;
  z-index: 2;
  left: calc(1em / 2);
  top: calc(1em / 2);
  color: #ffffff;
}
.cb-widget .cb-glyph-circular:not(.cb-glyph-numeric)::after {
  content: " ";
  position: absolute;
  z-index: 1;
  display: block;
  border-radius: 50%;
  height: 2em;
  width: 2em;
  background-color: #009cde;
}
.cb-widget .cb-glyph-circular:not(.cb-glyph-numeric).cb-glyph-corporate::after {
  background-color: #006298;
}
.cb-widget .cb-glyph-circular:not(.cb-glyph-numeric).cb-glyph-corporate2::after {
  background-color: #0077c8;
}
.cb-widget .cb-glyph-circular:not(.cb-glyph-numeric).cb-glyph-k-12::after {
  background-color: #009cde;
}
.cb-widget .cb-glyph-circular:not(.cb-glyph-numeric).cb-glyph-higher-education::after {
  background-color: #702f8a;
}
.cb-widget .cb-glyph-circular:not(.cb-glyph-numeric).cb-glyph-access::after {
  background-color: #3a913f;
}
.cb-widget .cb-icon-icn_online-tool::before,
.cb-widget .cb-online-tool::before {
  content: "\E936";
}
.cb-widget .cb-icon-icn_download::before,
.cb-widget .cb-icon-icn_download_med::before,
.cb-widget .cb-icon-icn_download_small::before,
.cb-widget .cb-icon-icn_download_res::before,
.cb-widget .cb-download::before {
  content: "\E914";
}
.cb-widget .cb-icon-icn_download_bulk::before,
.cb-widget .cb-batch-download::before {
  content: "\E902";
}
.cb-widget .cb-icon-icn_local-menu::before,
.cb-widget .cb-menu-local::before {
  content: "\E925";
}
.cb-widget .cb-icon-icn_filter::before,
.cb-widget .cb-filter::before {
  content: "\E91D";
}
.cb-widget .cb-icon-icn_sort::before,
.cb-widget .cb-sort::before {
  content: "\E947";
}
.cb-widget .cb-sort-asc::before {
  content: "\E950";
}
.cb-widget .cb-sort-desc::before {
  content: "\E913";
}
.cb-widget .cb-icon-icn_arrow-up::before,
.cb-widget .cb-icon-double_arrow_round::before,
.cb-widget .cb-icon-double_arrow::before,
.cb-widget .cb-up::before {
  content: "\E950";
}
.cb-widget .cb-icon-icn_arrow-down::before,
.cb-widget .cb-down::before {
  content: "\E913";
}
.cb-widget .cb-icon-icn_arrow-left::before,
.cb-widget .cb-left::before {
  content: "\E921";
}
.cb-widget .cb-icon-icn_arrow-right::before,
.cb-widget .cb-right::before {
  content: "\E940";
}
.cb-widget .cb-icon-icn_double-left-arrow::before,
.cb-widget .cb-dbl-left::before {
  content: "\E910";
}
.cb-widget .cb-icon-icn_double-right-arrow::before,
.cb-widget .cb-dbl-right::before {
  content: "\E911";
}
.cb-widget .cb-icon-icn_email::before,
.cb-widget .cb-mail::before {
  content: "\E928";
}
.cb-widget .cb-icon-icn_fax::before,
.cb-widget .cb-fax::before {
  content: "\E91C";
}
.cb-widget .cb-icon-icn_home::before,
.cb-widget .cb-home::before {
  content: "\E91F";
}
.cb-widget .cb-icon-icn_location::before,
.cb-widget .cb-location::before {
  content: "\E926";
}
.cb-widget .cb-icon-icn_phone::before,
.cb-widget .cb-icon-icn_phone_right::before,
.cb-widget .cb-phone-alt::before {
  content: "\E938";
}
.cb-widget .cb-icon-icn_resources::before,
.cb-widget .cb-resources::before {
  content: "\E93F";
}
.cb-widget .cb-icon-icn_calendar::before,
.cb-widget .cb-icon-icn_calendar_circle::before,
.cb-widget .cb-cal-full::before {
  content: "\E907";
}
.cb-widget .cb-icon-round-arrow::before,
.cb-widget .cb-east::before {
  content: "\E916";
}
.cb-widget .cb-icon-calendar::before,
.cb-widget .cb-cal-empty::before {
  content: "\E906";
}
.cb-widget .cb-icon-note::before,
.cb-widget .cb-note::before {
  content: "\E935";
}
.cb-widget .cb-icon-light::before,
.cb-widget .cb-lightbulb::before {
  content: "\E922";
}
.cb-widget .cb-icon-icn_question-mark::before,
.cb-widget .cb-icon-ssd::before,
.cb-widget .cb-question::before {
  content: "\E93C";
}
.cb-widget .cb-icon-megaphone::before,
.cb-widget .cb-megaphone::before {
  content: "\E92A";
}
.cb-widget .cb-icon-expand::before,
.cb-widget .cb-icon-icn_plus_round::before,
.cb-widget .cb-plus::before {
  content: "\E93A";
}
.cb-widget .cb-icon-collapse::before,
.cb-widget .cb-minus::before {
  content: "\E92C";
}
.cb-widget .cb-icon-chatbubble::before,
.cb-widget .cb-chat-bubble::before {
  content: "\E909";
}
.cb-widget .cb-icon-facebook::before,
.cb-widget .cb-icon-icn_facebook::before,
.cb-widget .cb-facebook::before {
  content: "\E91B";
}
.cb-widget .cb-icon-linkedin::before,
.cb-widget .cb-icon-icn_linkedin::before,
.cb-widget .cb-linkedin::before {
  content: "\E923";
}
.cb-widget .cb-icon-twitter::before,
.cb-widget .cb-icon-icn_twitter::before,
.cb-widget .cb-twitter::before {
  content: "\E94F";
}
.cb-widget .cb-icon-youtube::before,
.cb-widget .cb-icon-icn_youtube::before,
.cb-widget .cb-youtube::before {
  content: "\E956";
}
.cb-widget .cb-icon-icn_instagram::before,
.cb-widget .cb-instagram::before {
  content: "\E920";
}
.cb-widget .cb-icon-icn_compose::before,
.cb-widget .cb-compose::before {
  content: "\E90D";
}
.cb-widget .cb-icon-icn_out::before,
.cb-widget .cb-upload::before {
  content: "\E951";
}
.cb-widget .cb-icon-icn_list::before,
.cb-widget .cb-list::before {
  content: "\E924";
}
.cb-widget .cb-icon-icn_user::before,
.cb-widget .cb-icon-icn_signin::before,
.cb-widget .cb-user::before {
  content: "\E952";
}
.cb-widget .cb-icon-icn_settings::before,
.cb-widget .cb-settings::before {
  content: "\E944";
}
.cb-widget .cb-icon-icn_error::before,
.cb-widget .cb-error::before {
  content: "\E918";
}
.cb-widget .cb-icon-icn_book::before,
.cb-widget .cb-book::before {
  content: "\E905";
}
.cb-widget .cb-icon-icn_tag::before,
.cb-widget .cb-tag::before {
  content: "\E94C";
}
.cb-widget .cb-icon-icn_check::before,
.cb-widget .cb-check::before {
  content: "\E90B";
}
.cb-widget .cb-icon-icn_menu_trigger::before,
.cb-widget .cb-menu::before {
  content: "\E92B";
}
.cb-widget .cb-icon-icn_exclamation::before,
.cb-widget .cb-icon-icn_round_alerts::before,
.cb-widget .cb-icon-icn_deadline::before,
.cb-widget .cb-exclamation::before {
  content: "\E919";
}
.cb-widget .cb-icon-icn_draft-in::before,
.cb-widget .cb-draft-in::before {
  content: "\E915";
}
.cb-widget .cb-icon-icn_new-window::before,
.cb-widget .cb-new-window::before {
  content: "\E930";
}
.cb-widget .cb-icon-icn_no-draft::before,
.cb-widget .cb-no-draft::before {
  content: "\E931";
}
.cb-widget .cb-icon-Nav_Links::before,
.cb-widget .cb-nav-links::before {
  content: "\E92D";
}
.cb-widget .cb-icon-icn_quote_right::before,
.cb-widget .cb-quote-right::before {
  content: "\E93E";
}
.cb-widget .cb-icon-icn_quote_left::before,
.cb-widget .cb-quote-left::before {
  content: "\E93D";
}
.cb-widget .cb-icon-icn_close::before,
.cb-widget .cb-x-mark::before {
  content: "\E955";
}
.cb-widget .cb-icon-circle-o::before,
.cb-widget .cb-circle::before {
  content: "\E90C";
}
.cb-widget .cb-icon-circle::before,
.cb-widget .cb-disc::before {
  content: "\E912";
}
.cb-widget .cb-icon-icn_arrow_e::before,
.cb-widget .cb-east::before {
  content: "\E916";
}
.cb-widget .cb-icon-icn_arrow_ne::before,
.cb-widget .cb-north-east::before {
  content: "\E932";
}
.cb-widget .cb-icon-icn_creditcard::before,
.cb-widget .cb-creditcard::before {
  content: "\E90F";
}
.cb-widget .cb-icon-icn_educator::before,
.cb-widget .cb-educator::before {
  content: "\E917";
}
.cb-widget .cb-icon-icn_series::before,
.cb-widget .cb-series::before {
  content: "\E943";
}
.cb-widget .cb-icon-icn_ringing_bell::before,
.cb-widget .cb-bell::before {
  content: "\E904";
}
.cb-widget .cb-icon-icn_pending::before,
.cb-widget .cb-pending::before {
  content: "\E937";
}
.cb-widget .cb-icon-icn_authenticated::before,
.cb-widget .cb-signed-in::before {
  content: "\E946";
}
.cb-widget .cb-icon-icn_matrix::before,
.cb-widget .cb-matrix::before {
  content: "\E929";
}
.cb-widget .cb-icon-icn_search::before,
.cb-widget .cb-search::before {
  content: "\E942";
}
.cb-widget .cb-icon-icn_shopping_cart::before,
.cb-widget .cb-cart::before {
  content: "\E908";
}
.cb-widget .cb-icon-icn_global::before,
.cb-widget .cb-globe::before {
  content: "\E91E";
}
.cb-widget .cb-icon-Acorn::before,
.cb-widget .cb-acorn::before {
  content: "\E957";
}
.cb-widget .cb-batch-upload::before {
  content: "\E903";
}
.cb-widget .cb-needs-score::before {
  content: "\E92E";
}
.cb-widget .cb-test-scored::before {
  content: "\E94E";
}
.cb-widget .cb-save-date::before {
  content: "\E941";
}
.cb-widget .cb-north-west::before {
  content: "\E933";
}
.cb-widget .cb-west::before {
  content: "\E953";
}
.cb-widget .cb-south-west::before {
  content: "\E94A";
}
.cb-widget .cb-south-east::before {
  content: "\E949";
}
.cb-widget .cb-barchart::before {
  content: "\E901";
}
.cb-widget .cb-print::before {
  content: "\E93B";
}
.cb-widget .cb-chat::before {
  content: "\E90A";
}
.cb-widget .cb-window::before {
  content: "\E954";
}
.cb-widget .cb-bag::before {
  content: "\E900";
}
.cb-widget .cb-expand-window::before {
  content: "\E91A";
}
.cb-widget .cb-task-complete::before {
  content: "\E94D";
}
.cb-widget .cb-new-doc::before {
  content: "\E92F";
}
.cb-widget .cb-sound::before {
  content: "\E948";
}
.cb-widget .cb-computer::before {
  content: "\E90E";
}
.cb-widget .cb-share::before {
  content: "\E945";
}
.cb-widget .cb-lock::before {
  content: "\E927";
}
.cb-widget .cb-phone::before {
  content: "\E939";
}
.cb-widget .cb-disc-alt::before {
  content: "\E958";
}
.cb-widget .cb-box-fill::before {
  content: "\E959";
}
.cb-widget .cb-box::before {
  content: "\E95A";
}
.cb-widget .cb-play::before {
  content: "\E95B";
}
.cb-widget .cb-check-alt::before {
  content: "\E95C";
}
.cb-widget .cb-check-circle::before {
  content: "\E95D";
}
.cb-widget .cb-glyph-multi {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  font-family: 'CB Icons Multi';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 4rem;
  background-color: #0077c8;
}
.cb-widget .cb-glyph-multi::before,
.cb-widget .cb-glyph-multi::after {
  position: absolute;
  top: 0;
  left: 0;
}
.cb-widget .cb-glyph-multi.cb-glyph-sm {
  font-size: calc(1rem * 2) !important;
}
.cb-widget .cb-glyph-multi.cb-glyph-lg {
  font-size: calc(1rem * 4) !important;
}
.cb-widget .cb-glyph-multi.cb-glyph-xl {
  font-size: calc(1rem * 6) !important;
}
.cb-widget .cb-glyph-multi::before {
  color: #ffffff;
}
.cb-widget .cb-glyph-multi::after {
  color: #fedb00;
}
.cb-widget .cb-glyph-multi[class^="cb-glyph-hover-"] {
  transition: all 0.5s ease-out;
}
.cb-widget .cb-glyph-multi.cb-glyph-hover-rotate:hover {
  transition: 0.9s;
  transform: rotateY(180deg);
}
.cb-widget .cb-glyph-multi.cb-glyph-hover-flip-color:hover::before {
  color: #000000;
}
.cb-widget .cb-glyph-multi.cb-glyph-hover-flip-color:hover::after {
  color: #ffffff;
}
.cb-widget .cb-glyph-multi.cb-glyph-corporate1 {
  background-color: #0077c8;
}
.cb-widget .cb-glyph-multi.cb-glyph-corporate1::before {
  color: #ffffff;
}
.cb-widget .cb-glyph-multi.cb-glyph-corporate1::after {
  color: #fedb00;
}
.cb-widget .cb-glyph-multi.cb-glyph-corporate2 {
  background-color: #009cde;
}
.cb-widget .cb-glyph-multi.cb-glyph-corporate2::before {
  color: #ffffff;
}
.cb-widget .cb-glyph-multi.cb-glyph-corporate2::after {
  color: #000000;
}
.cb-widget .cb-glyph-multi.cb-glyph-corporate3 {
  background-color: #fedb00;
}
.cb-widget .cb-glyph-multi.cb-glyph-corporate3::before {
  color: #000000;
}
.cb-widget .cb-glyph-multi.cb-glyph-corporate3::after {
  color: #000000;
}
.cb-widget .cb-glyph-multi.cb-glyph-k-12 {
  background-color: #009cde;
}
.cb-widget .cb-glyph-multi.cb-glyph-k-12::before {
  color: #ffffff;
}
.cb-widget .cb-glyph-multi.cb-glyph-k-12::after {
  color: #000000;
}
.cb-widget .cb-glyph-multi.cb-glyph-k-12-accent {
  background-color: #71c5e8;
}
.cb-widget .cb-glyph-multi.cb-glyph-k-12-accent::before {
  color: #ffffff;
}
.cb-widget .cb-glyph-multi.cb-glyph-k-12-accent::after {
  color: #000000;
}
.cb-widget .cb-glyph-multi.cb-glyph-k-12-accent2 {
  background-color: #0077c8;
}
.cb-widget .cb-glyph-multi.cb-glyph-k-12-accent2::before {
  color: #ffffff;
}
.cb-widget .cb-glyph-multi.cb-glyph-k-12-accent2::after {
  color: #000000;
}
.cb-widget .cb-glyph-multi.cb-glyph-higher-education {
  background-color: #702f8a;
}
.cb-widget .cb-glyph-multi.cb-glyph-higher-education::before {
  color: #ffffff;
}
.cb-widget .cb-glyph-multi.cb-glyph-higher-education::after {
  color: #000000;
}
.cb-widget .cb-glyph-multi.cb-glyph-higher-education-accent {
  background-color: #a05eb5;
}
.cb-widget .cb-glyph-multi.cb-glyph-higher-education-accent::before {
  color: #ffffff;
}
.cb-widget .cb-glyph-multi.cb-glyph-higher-education-accent::after {
  color: #000000;
}
.cb-widget .cb-glyph-multi.cb-glyph-access {
  background-color: #3a913f;
}
.cb-widget .cb-glyph-multi.cb-glyph-access::before {
  color: #ffffff;
}
.cb-widget .cb-glyph-multi.cb-glyph-access::after {
  color: #000000;
}
.cb-widget .cb-glyph-multi.cb-glyph-access-accent {
  background-color: #6cc24a;
}
.cb-widget .cb-glyph-multi.cb-glyph-access-accent::before {
  color: #ffffff;
}
.cb-widget .cb-glyph-multi.cb-glyph-access-accent::after {
  color: #000000;
}
.cb-widget .cb-glyph-multi.cb-exam::before {
  content: "\E921";
}
.cb-widget .cb-glyph-multi.cb-exam::after {
  content: "\E944";
}
.cb-widget .cb-glyph-multi.cb-send-scores::before {
  content: "\E925";
}
.cb-widget .cb-glyph-multi.cb-send-scores::after {
  content: "\E945";
}
.cb-widget .cb-glyph-multi.cb-submit-documents::before {
  content: "\E92A";
}
.cb-widget .cb-glyph-multi.cb-submit-documents::after {
  content: "\E946";
}
.cb-widget .cb-glyph-multi.cb-submit-portfolio::before {
  content: "\E932";
}
.cb-widget .cb-glyph-multi.cb-submit-portfolio::after {
  content: "\E952";
}
.cb-widget .cb-glyph-multi.cb-accessible::before {
  content: "\E900";
}
.cb-widget .cb-glyph-multi.cb-accessible::after {
  content: "\E97C";
}
.cb-widget .cb-glyph-multi.cb-app::before {
  content: "\E901";
}
.cb-widget .cb-glyph-multi.cb-app::after {
  content: "\E97D";
}
.cb-widget .cb-glyph-multi.cb-bag::before {
  content: "\E902";
}
.cb-widget .cb-glyph-multi.cb-bag::after {
  content: "\E97E";
}
.cb-widget .cb-glyph-multi.cb-barchart::before {
  content: "\E903";
}
.cb-widget .cb-glyph-multi.cb-barchart::after {
  content: "\E97F";
}
.cb-widget .cb-glyph-multi.cb-batch-download::before {
  content: "\E904";
}
.cb-widget .cb-glyph-multi.cb-batch-download::after {
  content: "\E980";
}
.cb-widget .cb-glyph-multi.cb-batch-upload::before {
  content: "\E905";
}
.cb-widget .cb-glyph-multi.cb-batch-upload::after {
  content: "\E981";
}
.cb-widget .cb-glyph-multi.cb-bell::before {
  content: "\E906";
}
.cb-widget .cb-glyph-multi.cb-bell::after {
  content: "\E982";
}
.cb-widget .cb-glyph-multi.cb-book::before {
  content: "\E907";
}
.cb-widget .cb-glyph-multi.cb-book::after {
  content: "\E983";
}
.cb-widget .cb-glyph-multi.cb-bullet-list::before {
  content: "\E908";
}
.cb-widget .cb-glyph-multi.cb-bullet-list::after {
  content: "\E984";
}
.cb-widget .cb-glyph-multi.cb-bullet-list-doc::before {
  content: "\E909";
}
.cb-widget .cb-glyph-multi.cb-bullet-list-doc::after {
  content: "\E985";
}
.cb-widget .cb-glyph-multi.cb-calculator::before {
  content: "\E90A";
}
.cb-widget .cb-glyph-multi.cb-calculator::after {
  content: "\E986";
}
.cb-widget .cb-glyph-multi.cb-calendar-empty::before {
  content: "\E90B";
}
.cb-widget .cb-glyph-multi.cb-calendar-empty::after {
  content: "\E987";
}
.cb-widget .cb-glyph-multi.cb-calendar-full::before {
  content: "\E90C";
}
.cb-widget .cb-glyph-multi.cb-calendar-full::after {
  content: "\E988";
}
.cb-widget .cb-glyph-multi.cb-cart::before {
  content: "\E90D";
}
.cb-widget .cb-glyph-multi.cb-cart::after {
  content: "\E989";
}
.cb-widget .cb-glyph-multi.cb-chat::before {
  content: "\E90E";
}
.cb-widget .cb-glyph-multi.cb-chat::after {
  content: "\E98A";
}
.cb-widget .cb-glyph-multi.cb-check::before {
  content: "\E910";
}
.cb-widget .cb-glyph-multi.cb-check::after {
  content: "\E98B";
}
.cb-widget .cb-glyph-multi.cb-check-list::before {
  content: "\E911";
}
.cb-widget .cb-glyph-multi.cb-check-list::after {
  content: "\E98C";
}
.cb-widget .cb-glyph-multi.cb-check-list-doc::before {
  content: "\E912";
}
.cb-widget .cb-glyph-multi.cb-check-list-doc::after {
  content: "\E98D";
}
.cb-widget .cb-glyph-multi.cb-college-reputation::before {
  content: "\E914";
}
.cb-widget .cb-glyph-multi.cb-college-reputation::after {
  content: "\E98E";
}
.cb-widget .cb-glyph-multi.cb-compose::before {
  content: "\E915";
}
.cb-widget .cb-glyph-multi.cb-compose::after {
  content: "\E98F";
}
.cb-widget .cb-glyph-multi.cb-computer::before {
  content: "\E916";
}
.cb-widget .cb-glyph-multi.cb-computer::after {
  content: "\E990";
}
.cb-widget .cb-glyph-multi.cb-credit-card::before {
  content: "\E917";
}
.cb-widget .cb-glyph-multi.cb-credit-card::after {
  content: "\E991";
}
.cb-widget .cb-glyph-multi.cb-dbl-left::before {
  content: "\E91A";
}
.cb-widget .cb-glyph-multi.cb-dbl-left::after {
  content: "\E994";
}
.cb-widget .cb-glyph-multi.cb-dbl-right::before {
  content: "\E91B";
}
.cb-widget .cb-glyph-multi.cb-dbl-right::after {
  content: "\E995";
}
.cb-widget .cb-glyph-multi.cb-dollar::before {
  content: "\E91D";
}
.cb-widget .cb-glyph-multi.cb-dollar::after {
  content: "\E997";
}
.cb-widget .cb-glyph-multi.cb-download::before {
  content: "\E91F";
}
.cb-widget .cb-glyph-multi.cb-download::after {
  content: "\E999";
}
.cb-widget .cb-glyph-multi.cb-educator::before {
  content: "\E920";
}
.cb-widget .cb-glyph-multi.cb-educator::after {
  content: "\E99A";
}
.cb-widget .cb-glyph-multi.cb-error::before {
  content: "\E922";
}
.cb-widget .cb-glyph-multi.cb-error::after {
  content: " ";
}
.cb-widget .cb-glyph-multi.cb-everyday-words::before {
  content: "\E924";
}
.cb-widget .cb-glyph-multi.cb-everyday-words::after {
  content: "\E99C";
}
.cb-widget .cb-glyph-multi.cb-expand-window::before {
  content: "\E926";
}
.cb-widget .cb-glyph-multi.cb-expand-window::after {
  content: "\E99E";
}
.cb-widget .cb-glyph-multi.cb-fax::before {
  content: "\E929";
}
.cb-widget .cb-glyph-multi.cb-fax::after {
  content: "\E9A0";
}
.cb-widget .cb-glyph-multi.cb-full-length-practice::before {
  content: "\E92B";
}
.cb-widget .cb-glyph-multi.cb-full-length-practice::after {
  content: "\E9A1";
}
.cb-widget .cb-glyph-multi.cb-globe::before {
  content: "\E92C";
}
.cb-widget .cb-glyph-multi.cb-globe::after {
  content: "\E9A2";
}
.cb-widget .cb-glyph-multi.cb-graduate-pro-school::before {
  content: "\E92D";
}
.cb-widget .cb-glyph-multi.cb-graduate-pro-school::after {
  content: "\E9A3";
}
.cb-widget .cb-glyph-multi.cb-graduation::before {
  content: "\E92E";
}
.cb-widget .cb-glyph-multi.cb-graduation::after {
  content: "\E9A4";
}
.cb-widget .cb-glyph-multi.cb-home::before {
  content: "\E92F";
}
.cb-widget .cb-glyph-multi.cb-home::after {
  content: "\E9A5";
}
.cb-widget .cb-glyph-multi.cb-information::before {
  content: "\E930";
}
.cb-widget .cb-glyph-multi.cb-information::after {
  content: "\E9A6";
}
.cb-widget .cb-glyph-multi.cb-instagram::before {
  content: "\E931";
}
.cb-widget .cb-glyph-multi.cb-instagram::after {
  content: " ";
}
.cb-widget .cb-glyph-multi.cb-facebook::before {
  content: "\E928";
}
.cb-widget .cb-glyph-multi.cb-facebook::after {
  content: " ";
}
.cb-widget .cb-glyph-multi.cb-youtube::before {
  content: "\E97B";
}
.cb-widget .cb-glyph-multi.cb-youtube::after {
  content: " ";
}
.cb-widget .cb-glyph-multi.cb-light-bulb::before {
  content: "\E933";
}
.cb-widget .cb-glyph-multi.cb-light-bulb::after {
  content: "\E9A8";
}
.cb-widget .cb-glyph-multi.cb-linkedin::before {
  content: "\E934";
}
.cb-widget .cb-glyph-multi.cb-linkedin::after {
  content: " ";
}
.cb-widget .cb-glyph-multi.cb-list::before {
  content: "\E935";
}
.cb-widget .cb-glyph-multi.cb-list::after {
  content: "\E9A9";
}
.cb-widget .cb-glyph-multi.cb-local-menu::before {
  content: "\E936";
}
.cb-widget .cb-glyph-multi.cb-local-menu::after {
  content: "\E9AA";
}
.cb-widget .cb-glyph-multi.cb-location::before {
  content: "\E937";
}
.cb-widget .cb-glyph-multi.cb-location::after {
  content: "\E9AB";
}
.cb-widget .cb-glyph-multi.cb-lock::before {
  content: "\E938";
}
.cb-widget .cb-glyph-multi.cb-lock::after {
  content: "\E9AC";
}
.cb-widget .cb-glyph-multi.cb-lock-open::before {
  content: "\E939";
}
.cb-widget .cb-glyph-multi.cb-lock-open::after {
  content: "\E9AD";
}
.cb-widget .cb-glyph-multi.cb-mail::before {
  content: "\E93A";
}
.cb-widget .cb-glyph-multi.cb-mail::after {
  content: "\E9AE";
}
.cb-widget .cb-glyph-multi.cb-matrix::before {
  content: "\E93B";
}
.cb-widget .cb-glyph-multi.cb-matrix::after {
  content: "\E9AF";
}
.cb-widget .cb-glyph-multi.cb-megaphone::before {
  content: "\E93C";
}
.cb-widget .cb-glyph-multi.cb-megaphone::after {
  content: "\E9B0";
}
.cb-widget .cb-glyph-multi.cb-menu::before {
  content: "\E93D";
}
.cb-widget .cb-glyph-multi.cb-menu::after {
  content: "\E9B1";
}
.cb-widget .cb-glyph-multi.cb-minus::before {
  content: "\E93E";
}
.cb-widget .cb-glyph-multi.cb-minus::after {
  content: "\E9B2";
}
.cb-widget .cb-glyph-multi.cb-nav-links::before {
  content: "\E93F";
}
.cb-widget .cb-glyph-multi.cb-nav-links::after {
  content: "\E9B3";
}
.cb-widget .cb-glyph-multi.cb-new-doc::before {
  content: "\E941";
}
.cb-widget .cb-glyph-multi.cb-new-doc::after {
  content: "\E9B5";
}
.cb-widget .cb-glyph-multi.cb-new-window::before {
  content: "\E942";
}
.cb-widget .cb-glyph-multi.cb-new-window::after {
  content: "\E9B6";
}
.cb-widget .cb-glyph-multi.cb-note::before {
  content: "\E947";
}
.cb-widget .cb-glyph-multi.cb-note::after {
  content: "\E9B9";
}
.cb-widget .cb-glyph-multi.cb-online-tool::before {
  content: "\E948";
}
.cb-widget .cb-glyph-multi.cb-online-tool::after {
  content: "\E9BA";
}
.cb-widget .cb-glyph-multi.cb-pending::before {
  content: "\E949";
}
.cb-widget .cb-glyph-multi.cb-pending::after {
  content: "\E9BB";
}
.cb-widget .cb-glyph-multi.cb-phone::before {
  content: "\E94A";
}
.cb-widget .cb-glyph-multi.cb-phone::after {
  content: "\E9BC";
}
.cb-widget .cb-glyph-multi.cb-phone-alt::before {
  content: "\E94B";
}
.cb-widget .cb-glyph-multi.cb-phone-alt::after {
  content: "\E9BD";
}
.cb-widget .cb-glyph-multi.cb-plus::before {
  content: "\E94C";
}
.cb-widget .cb-glyph-multi.cb-plus::after {
  content: "\E9BE";
}
.cb-widget .cb-glyph-multi.cb-practice::before {
  content: "\E94D";
}
.cb-widget .cb-glyph-multi.cb-practice::after {
  content: "\E9BF";
}
.cb-widget .cb-glyph-multi.cb-practice-test::before {
  content: "\E94E";
}
.cb-widget .cb-glyph-multi.cb-practice-test::after {
  content: "\E9C0";
}
.cb-widget .cb-glyph-multi.cb-presentation::before {
  content: "\E94F";
}
.cb-widget .cb-glyph-multi.cb-presentation::after {
  content: "\E9C1";
}
.cb-widget .cb-glyph-multi.cb-print::before {
  content: "\E950";
}
.cb-widget .cb-glyph-multi.cb-print::after {
  content: "\E9C2";
}
.cb-widget .cb-glyph-multi.cb-problem-feedback::before {
  content: "\E951";
}
.cb-widget .cb-glyph-multi.cb-problem-feedback::after {
  content: "\E9C3";
}
.cb-widget .cb-glyph-multi.cb-raided-pencil::before {
  content: "\E955";
}
.cb-widget .cb-glyph-multi.cb-raided-pencil::after {
  content: "\E9C5";
}
.cb-widget .cb-glyph-multi.cb-resources::before {
  content: "\E956";
}
.cb-widget .cb-glyph-multi.cb-resources::after {
  content: "\E9C6";
}
.cb-widget .cb-glyph-multi.cb-rupee::before {
  content: "\E958";
}
.cb-widget .cb-glyph-multi.cb-rupee::after {
  content: "\E9C8";
}
.cb-widget .cb-glyph-multi.cb-save-date::before {
  content: "\E959";
}
.cb-widget .cb-glyph-multi.cb-save-date::after {
  content: "\E9C9";
}
.cb-widget .cb-glyph-multi.cb-science::before {
  content: "\E95D";
}
.cb-widget .cb-glyph-multi.cb-science::after {
  content: "\E9CC";
}
.cb-widget .cb-glyph-multi.cb-search {
  background-image: none!important;
}
.cb-widget .cb-glyph-multi.cb-search::before {
  content: "\E95E";
}
.cb-widget .cb-glyph-multi.cb-search::after {
  content: "\E9CD";
}
.cb-widget .cb-glyph-multi.cb-series::before {
  content: "\E95F";
}
.cb-widget .cb-glyph-multi.cb-series::after {
  content: "\E9CE";
}
.cb-widget .cb-glyph-multi.cb-settings::before {
  content: "\E960";
}
.cb-widget .cb-glyph-multi.cb-settings::after {
  content: "\E9CF";
}
.cb-widget .cb-glyph-multi.cb-share::before {
  content: "\E961";
}
.cb-widget .cb-glyph-multi.cb-share::after {
  content: "\E9D0";
}
.cb-widget .cb-glyph-multi.cb-share-alt::before {
  content: "\E962";
}
.cb-widget .cb-glyph-multi.cb-share-alt::after {
  content: "\E9D1";
}
.cb-widget .cb-glyph-multi.cb-signed-in::before {
  content: "\E963";
}
.cb-widget .cb-glyph-multi.cb-signed-in::after {
  content: "\E9D2";
}
.cb-widget .cb-glyph-multi.cb-sound::before {
  content: "\E966";
}
.cb-widget .cb-glyph-multi.cb-sound::after {
  content: "\E9D4";
}
.cb-widget .cb-glyph-multi.cb-special-edition::before {
  content: "\E969";
}
.cb-widget .cb-glyph-multi.cb-special-edition::after {
  content: "\E9D5";
}
.cb-widget .cb-glyph-multi.cb-tag::before {
  content: "\E96B";
}
.cb-widget .cb-glyph-multi.cb-tag::after {
  content: "\E9D6";
}
.cb-widget .cb-glyph-multi.cb-task-complete::before {
  content: "\E96D";
}
.cb-widget .cb-glyph-multi.cb-task-complete::after {
  content: "\E9D8";
}
.cb-widget .cb-glyph-multi.cb-teacher-edition::before {
  content: "\E96E";
}
.cb-widget .cb-glyph-multi.cb-teacher-edition::after {
  content: "\E9D9";
}
.cb-widget .cb-glyph-multi.cb-team-leader::before {
  content: "\E96F";
}
.cb-widget .cb-glyph-multi.cb-team-leader::after {
  content: "\E9DA";
}
.cb-widget .cb-glyph-multi.cb-test-scored::before {
  content: "\E970";
}
.cb-widget .cb-glyph-multi.cb-test-scored::after {
  content: "\E9DB";
}
.cb-widget .cb-glyph-multi.cb-tuitinon-financial-aid::before {
  content: "\E971";
}
.cb-widget .cb-glyph-multi.cb-tuitinon-financial-aid::after {
  content: "\E9DC";
}
.cb-widget .cb-glyph-multi.cb-twitter::before {
  content: "\E972";
}
.cb-widget .cb-glyph-multi.cb-twitter::after {
  content: " ";
}
.cb-widget .cb-glyph-multi.cb-upload::before {
  content: "\E974";
}
.cb-widget .cb-glyph-multi.cb-upload::after {
  content: "\E9DE";
}
.cb-widget .cb-glyph-multi.cb-user::before {
  content: "\E975";
}
.cb-widget .cb-glyph-multi.cb-user::after {
  content: " ";
}
.cb-widget .cb-glyph-multi.cb-video-lesson::before {
  content: "\E976";
}
.cb-widget .cb-glyph-multi.cb-video-lesson::after {
  content: "\E9DF";
}
.cb-widget .cb-glyph-multi.cb-window::before {
  content: "\E978";
}
.cb-widget .cb-glyph-multi.cb-window::after {
  content: " ";
}
.cb-widget .cb-glyph-multi.cb-work-together::before {
  content: "\E979";
}
.cb-widget .cb-glyph-multi.cb-work-together::after {
  content: "\E9E0";
}
.cb-widget .cb-glyph-multi.cb-quote-left::before {
  content: "\E913";
}
.cb-widget .cb-glyph-multi.cb-quote-left::after {
  content: "\E91C";
}
.cb-widget .cb-glyph-multi.cb-quote-right::before {
  content: "\E91E";
}
.cb-widget .cb-glyph-multi.cb-quote-right::after {
  content: "\E919";
}
.cb-widget .cb-glyph-multi.cb-add-user::before {
  content: "\E9E2";
}
.cb-widget .cb-glyph-multi.cb-add-user::after {
  content: "\E9EC";
}
.cb-widget .cb-glyph-multi.cb-dollars::before {
  content: "\E9E3";
}
.cb-widget .cb-glyph-multi.cb-dollars::after {
  content: "\E9ED";
}
.cb-widget .cb-glyph-multi.cb-essential-math::before {
  content: "\E9E4";
}
.cb-widget .cb-glyph-multi.cb-essential-math::after {
  content: "\E9EE";
}
.cb-widget .cb-glyph-multi.cb-explore::before {
  content: "\E9E5";
}
.cb-widget .cb-glyph-multi.cb-explore::after {
  content: "\E9EF";
}
.cb-widget .cb-glyph-multi.cb-face-happy::before {
  content: "\E9E6";
}
.cb-widget .cb-glyph-multi.cb-face-happy::after {
  content: "\E9D3";
}
.cb-widget .cb-glyph-multi.cb-face-unhappy::before {
  content: "\E9E7";
}
.cb-widget .cb-glyph-multi.cb-face-unhappy::after {
  content: "\E9D3";
}
.cb-widget .cb-glyph-multi.cb-face-unsure::before {
  content: "\E9E8";
}
.cb-widget .cb-glyph-multi.cb-face-unsure::after {
  content: "\E9D3";
}
.cb-widget .cb-glyph-multi.cb-maths::before {
  content: "\E9EA";
}
.cb-widget .cb-glyph-multi.cb-maths::after {
  content: "\E9F3";
}
.cb-widget .cb-glyph-multi.cb-school::before {
  content: "\E9EB";
}
.cb-widget .cb-glyph-multi.cb-school::after {
  content: "\E9F4";
}
.cb-widget .cb-glyph-multi.cb-khan-academy::before {
  content: "\E90F";
}
.cb-widget .cb-glyph-multi.cb-khan-academy::after {
  content: " ";
}
.cb-widget .cb-glyph-logo {
  position: relative;
  display: inline-block;
  background-image: none!important;
  font-family: 'CB Icons Logo';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.cb-widget .cb-glyph-logo::before {
  position: absolute;
  top: 0;
  left: 0;
}
.cb-widget .cb-glyph-logo.cb-logo-acorn::before {
  content: "\E901";
}
.cb-widget .cb-glyph-logo.cb-logo::before {
  content: "\E902";
}
.cb-widget .cb-glyph-logo.cb-programs-access::before {
  content: "\E903";
}
.cb-widget .cb-glyph-logo.cb-programs-accuplacer::before {
  content: "\E904";
}
.cb-widget .cb-glyph-logo.cb-programs-ap::before {
  content: "\E905";
}
.cb-widget .cb-glyph-logo.cb-programs-clep::before {
  content: "\E906";
}
.cb-widget .cb-glyph-logo.cb-programs-css-profile::before {
  content: "\E907";
}
.cb-widget .cb-glyph-logo.cb-programs-powerfaids::before {
  content: "\E908";
}
.cb-widget .cb-glyph-logo.cb-programs-pre-ap::before {
  content: "\E909";
}
.cb-widget .cb-glyph-logo.cb-programs-psat-8-9::before {
  content: "\E90A";
}
.cb-widget .cb-glyph-logo.cb-programs-psat-10::before {
  content: "\E90B";
}
.cb-widget .cb-glyph-logo.cb-programs-psat-nmsqt::before {
  content: "\E90C";
}
.cb-widget .cb-glyph-logo.cb-programs-sat::before {
  content: "\E90D";
}
.cb-widget .cb-glyph-logo.cb-programs-springboard::before {
  content: "\E90E";
}
.cb-widget .cb-glyph-logo.cb-search::before {
  content: "\E90F";
}
.cb-widget .cb-glyph-logo.cb-programs-college-planning::before {
  content: "\E900";
}
.cb-widget fieldset {
  padding: 0;
  margin: 0;
  border: 0;
  min-width: 0;
}
.cb-widget legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 1.5em;
  font-size: 1.5rem;
  line-height: inherit;
  color: #333333;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
}
.cb-widget label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
}
.cb-widget input[type="search"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.cb-widget input[type="radio"],
.cb-widget input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal;
}
.cb-widget input[type="file"] {
  display: block;
}
.cb-widget input[type="range"] {
  display: block;
  width: 100%;
}
.cb-widget select[multiple],
.cb-widget select[size] {
  height: auto;
}
.cb-widget input[type="file"]:focus,
.cb-widget input[type="radio"]:focus,
.cb-widget input[type="checkbox"]:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.cb-widget output {
  display: block;
  padding-top: 7px;
  font-size: 1rem;
  line-height: 1.5em;
  color: #505050;
}
.cb-widget .form-control {
  display: block;
  width: 100%;
  height: 15.5em;
  padding: 6px 12px;
  font-size: 1rem;
  line-height: 1.5em;
  color: #505050;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.cb-widget .form-control:focus {
  border-color: #0077c8;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 119, 200, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 119, 200, 0.6);
}
.cb-widget .form-control::-moz-placeholder {
  color: #737373;
  opacity: 1;
}
.cb-widget .form-control:-ms-input-placeholder {
  color: #737373;
}
.cb-widget .form-control::-webkit-input-placeholder {
  color: #737373;
}
.cb-widget .form-control::-ms-expand {
  border: 0;
  background-color: transparent;
}
.cb-widget .form-control[disabled],
.cb-widget .form-control[readonly],
.cb-widget fieldset[disabled] .form-control {
  background-color: #eeeeee;
  opacity: 1;
}
.cb-widget .form-control[disabled],
.cb-widget fieldset[disabled] .form-control {
  cursor: not-allowed;
}
.cb-widget textarea.form-control {
  height: auto;
}
.cb-widget input[type="search"] {
  -webkit-appearance: none;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .cb-widget input[type="date"].form-control,
  .cb-widget input[type="time"].form-control,
  .cb-widget input[type="datetime-local"].form-control,
  .cb-widget input[type="month"].form-control {
    line-height: 15.5em;
  }
  .cb-widget input[type="date"].input-sm,
  .cb-widget input[type="time"].input-sm,
  .cb-widget input[type="datetime-local"].input-sm,
  .cb-widget input[type="month"].input-sm,
  .cb-widget .input-group-sm input[type="date"],
  .cb-widget .input-group-sm input[type="time"],
  .cb-widget .input-group-sm input[type="datetime-local"],
  .cb-widget .input-group-sm input[type="month"] {
    line-height: 13rem;
  }
  .cb-widget input[type="date"].input-lg,
  .cb-widget input[type="time"].input-lg,
  .cb-widget input[type="datetime-local"].input-lg,
  .cb-widget input[type="month"].input-lg,
  .cb-widget .input-group-lg input[type="date"],
  .cb-widget .input-group-lg input[type="time"],
  .cb-widget .input-group-lg input[type="datetime-local"],
  .cb-widget .input-group-lg input[type="month"] {
    line-height: 24rem;
  }
}
.cb-widget .form-group {
  margin-bottom: 15px;
}
.cb-widget .radio,
.cb-widget .checkbox {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}
.cb-widget .radio label,
.cb-widget .checkbox label {
  min-height: 1.5em;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
}
.cb-widget .radio input[type="radio"],
.cb-widget .radio-inline input[type="radio"],
.cb-widget .checkbox input[type="checkbox"],
.cb-widget .checkbox-inline input[type="checkbox"] {
  position: absolute;
  margin-left: -20px;
  margin-top: 4px \9;
}
.cb-widget .radio + .radio,
.cb-widget .checkbox + .checkbox {
  margin-top: -5px;
}
.cb-widget .radio-inline,
.cb-widget .checkbox-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  vertical-align: middle;
  font-weight: normal;
  cursor: pointer;
}
.cb-widget .radio-inline + .radio-inline,
.cb-widget .checkbox-inline + .checkbox-inline {
  margin-top: 0;
  margin-left: 10px;
}
.cb-widget input[type="radio"][disabled],
.cb-widget input[type="checkbox"][disabled],
.cb-widget input[type="radio"].disabled,
.cb-widget input[type="checkbox"].disabled,
.cb-widget fieldset[disabled] input[type="radio"],
.cb-widget fieldset[disabled] input[type="checkbox"] {
  cursor: not-allowed;
}
.cb-widget .radio-inline.disabled,
.cb-widget .checkbox-inline.disabled,
.cb-widget fieldset[disabled] .radio-inline,
.cb-widget fieldset[disabled] .checkbox-inline {
  cursor: not-allowed;
}
.cb-widget .radio.disabled label,
.cb-widget .checkbox.disabled label,
.cb-widget fieldset[disabled] .radio label,
.cb-widget fieldset[disabled] .checkbox label {
  cursor: not-allowed;
}
.cb-widget .form-control-static {
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 0;
  min-height: 2.5em;
}
.cb-widget .form-control-static.input-lg,
.cb-widget .form-control-static.input-sm {
  padding-left: 0;
  padding-right: 0;
}
.cb-widget .input-sm {
  height: 13rem;
  padding: 5px 10px;
  font-size: 0.875rem;
  line-height: 1.286em;
  border-radius: 3px;
}
.cb-widget select.input-sm {
  height: 13rem;
  line-height: 13rem;
}
.cb-widget textarea.input-sm,
.cb-widget select[multiple].input-sm {
  height: auto;
}
.cb-widget .form-group-sm .form-control {
  height: 13rem;
  padding: 5px 10px;
  font-size: 0.875rem;
  line-height: 1.286em;
  border-radius: 3px;
}
.cb-widget .form-group-sm select.form-control {
  height: 13rem;
  line-height: 13rem;
}
.cb-widget .form-group-sm textarea.form-control,
.cb-widget .form-group-sm select[multiple].form-control {
  height: auto;
}
.cb-widget .form-group-sm .form-control-static {
  height: 13rem;
  min-height: 2.375em;
  padding: 6px 10px;
  font-size: 0.875rem;
  line-height: 1.286em;
}
.cb-widget .input-lg {
  height: 24rem;
  padding: 10px 16px;
  font-size: 1.125rem;
  line-height: 1.3333333;
  border-radius: 6px;
}
.cb-widget select.input-lg {
  height: 24rem;
  line-height: 24rem;
}
.cb-widget textarea.input-lg,
.cb-widget select[multiple].input-lg {
  height: auto;
}
.cb-widget .form-group-lg .form-control {
  height: 24rem;
  padding: 10px 16px;
  font-size: 1.125rem;
  line-height: 1.3333333;
  border-radius: 6px;
}
.cb-widget .form-group-lg select.form-control {
  height: 24rem;
  line-height: 24rem;
}
.cb-widget .form-group-lg textarea.form-control,
.cb-widget .form-group-lg select[multiple].form-control {
  height: auto;
}
.cb-widget .form-group-lg .form-control-static {
  height: 24rem;
  min-height: 2.625em;
  padding: 11px 16px;
  font-size: 1.125rem;
  line-height: 1.3333333;
}
.cb-widget .has-feedback {
  position: relative;
}
.cb-widget .has-feedback .form-control {
  padding-right: 19.375em;
}
.cb-widget .form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 15.5em;
  height: 15.5em;
  line-height: 15.5em;
  text-align: center;
  pointer-events: none;
}
.cb-widget .input-lg + .form-control-feedback,
.cb-widget .input-group-lg + .form-control-feedback,
.cb-widget .form-group-lg .form-control + .form-control-feedback {
  width: 24rem;
  height: 24rem;
  line-height: 24rem;
}
.cb-widget .input-sm + .form-control-feedback,
.cb-widget .input-group-sm + .form-control-feedback,
.cb-widget .form-group-sm .form-control + .form-control-feedback {
  width: 13rem;
  height: 13rem;
  line-height: 13rem;
}
.cb-widget .has-success .help-block,
.cb-widget .has-success .radio,
.cb-widget .has-success .checkbox,
.cb-widget .has-success .radio-inline,
.cb-widget .has-success .checkbox-inline {
  color: #3c763d;
}
.cb-widget .has-success .form-control {
  border-color: #3c763d;
}
.cb-widget .has-success .form-control:focus {
  border-color: #2b542c;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.cb-widget .has-success .input-group-addon {
  color: #3c763d;
  border-color: #3c763d;
  background-color: #dff0d8;
}
.cb-widget .has-success .form-control-feedback {
  color: #3c763d;
}
.cb-widget .has-success .cb-input-state {
  position: relative;
}
.cb-widget .has-success .cb-input-state i {
  position: absolute;
  color: #3c763d;
  top: 16px;
  right: 12px;
}
.cb-widget .has-success .cb-input-state input {
  padding-right: 36px;
}
.cb-widget .has-success .cb-input-state input:focus + i {
  color: #2b542c;
}
.cb-widget .has-warning .help-block,
.cb-widget .has-warning .radio,
.cb-widget .has-warning .checkbox,
.cb-widget .has-warning .radio-inline,
.cb-widget .has-warning .checkbox-inline {
  color: #ff9900;
}
.cb-widget .has-warning .form-control {
  border-color: #ff9900;
}
.cb-widget .has-warning .form-control:focus {
  border-color: #cc7a00;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.cb-widget .has-warning .input-group-addon {
  color: #ff9900;
  border-color: #ff9900;
  background-color: #f0f0f0;
}
.cb-widget .has-warning .form-control-feedback {
  color: #ff9900;
}
.cb-widget .has-warning .cb-input-state {
  position: relative;
}
.cb-widget .has-warning .cb-input-state i {
  position: absolute;
  color: #ff9900;
  top: 16px;
  right: 12px;
}
.cb-widget .has-warning .cb-input-state input {
  padding-right: 36px;
}
.cb-widget .has-warning .cb-input-state input:focus + i {
  color: #cc7a00;
}
.cb-widget .has-error .help-block,
.cb-widget .has-error .radio,
.cb-widget .has-error .checkbox,
.cb-widget .has-error .radio-inline,
.cb-widget .has-error .checkbox-inline {
  color: #c13145;
}
.cb-widget .has-error .form-control {
  border-color: #c13145;
}
.cb-widget .has-error .form-control:focus {
  border-color: #982736;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.cb-widget .has-error .input-group-addon {
  color: #c13145;
  border-color: #c13145;
  background-color: #f0f0f0;
}
.cb-widget .has-error .form-control-feedback {
  color: #c13145;
}
.cb-widget .has-error .cb-input-state {
  position: relative;
}
.cb-widget .has-error .cb-input-state i {
  position: absolute;
  color: #c13145;
  top: 16px;
  right: 12px;
}
.cb-widget .has-error .cb-input-state input {
  padding-right: 36px;
}
.cb-widget .has-error .cb-input-state input:focus + i {
  color: #982736;
}
.cb-widget .has-feedback label ~ .form-control-feedback {
  top: 6.5em;
}
.cb-widget .has-feedback label.sr-only ~ .form-control-feedback {
  top: 0;
}
.cb-widget .help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #909090;
}
@media (min-width: 768px) {
  .cb-widget .form-inline .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .cb-widget .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .cb-widget .form-inline .form-control-static {
    display: inline-block;
  }
  .cb-widget .form-inline .input-group {
    display: inline-table;
    vertical-align: middle;
  }
  .cb-widget .form-inline .input-group .input-group-addon,
  .cb-widget .form-inline .input-group .input-group-btn,
  .cb-widget .form-inline .input-group .form-control {
    width: auto;
  }
  .cb-widget .form-inline .input-group > .form-control {
    width: 100%;
  }
  .cb-widget .form-inline .control-label {
    margin-bottom: 0;
    vertical-align: middle;
  }
  .cb-widget .form-inline .radio,
  .cb-widget .form-inline .checkbox {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .cb-widget .form-inline .radio label,
  .cb-widget .form-inline .checkbox label {
    padding-left: 0;
  }
  .cb-widget .form-inline .radio input[type="radio"],
  .cb-widget .form-inline .checkbox input[type="checkbox"] {
    position: relative;
    margin-left: 0;
  }
  .cb-widget .form-inline .has-feedback .form-control-feedback {
    top: 0;
  }
}
.cb-widget .form-horizontal .radio,
.cb-widget .form-horizontal .checkbox,
.cb-widget .form-horizontal .radio-inline,
.cb-widget .form-horizontal .checkbox-inline {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 7px;
}
.cb-widget .form-horizontal .radio,
.cb-widget .form-horizontal .checkbox {
  min-height: 8.5em;
}
.cb-widget .form-horizontal .form-group {
  margin-left: -7.5px;
  margin-right: -7.5px;
}
@media (min-width: 768px) {
  .cb-widget .form-horizontal .form-group {
    margin-left: -10px;
    margin-right: -10px;
  }
}
@media (min-width: 1024px) {
  .cb-widget .form-horizontal .form-group {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media (min-width: 1250px) {
  .cb-widget .form-horizontal .form-group {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media (min-width: 768px) {
  .cb-widget .form-horizontal .control-label {
    text-align: right;
    margin-bottom: 0;
    padding-top: 7px;
  }
}
.cb-widget .form-horizontal .has-feedback .form-control-feedback {
  right: 7px;
}
@media (min-width: 768px) {
  .cb-widget .form-horizontal .form-group-lg .control-label {
    padding-top: 11px;
    font-size: 1.125rem;
  }
}
@media (min-width: 768px) {
  .cb-widget .form-horizontal .form-group-sm .control-label {
    padding-top: 6px;
    font-size: 0.875rem;
  }
}
.cb-widget .radio-inline,
.cb-widget .checkbox-inline {
  display: inline-block;
}
.cb-widget .form-control {
  border-radius: 0;
  background-color: #f0f0f0;
  border-color: #d9d9d9;
  height: 48px;
  font-size: 1rem;
  line-height: 3em;
  padding-top: 0;
  padding-bottom: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.cb-widget .form-control:hover {
  -webkit-box-shadow: inset 0 0 2px 1px #e7e6e5;
  box-shadow: inset 0 0 2px 1px #e7e6e5;
  border-color: #d9d9d9;
}
.cb-widget .form-control[disabled]:hover,
.cb-widget .form-control[readonly]:hover,
.cb-widget fieldset[disabled] .form-control:hover {
  border-color: #d9d9d9;
}
.cb-widget .form-control.cb-placeholder {
  color: #737373;
}
.cb-widget .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #0077c8;
  border-width: 1px;
}
.cb-widget textarea.form-control {
  line-height: 1.5em;
  padding: 23px 12px;
}
.cb-widget input::-ms-clear {
  width: 0;
  height: 0;
  display: none;
}
.cb-widget .radio input[type="radio"],
.cb-widget .radio-inline input[type="radio"] {
  position: relative;
  opacity: 0;
  margin-left: -17px;
}
.cb-widget .radio input[type="radio"] + span,
.cb-widget .radio-inline input[type="radio"] + span {
  position: relative;
  margin-right: 10px;
}
.cb-widget .radio input[type="radio"] + span::before,
.cb-widget .radio-inline input[type="radio"] + span::before {
  font-family: 'CB Icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\E90C";
  position: absolute;
  display: block;
  top: 2px;
  right: 0;
  color: #505050;
  font-size: 1em;
}
.cb-widget .radio input[type="radio"]:checked + span::before,
.cb-widget .radio-inline input[type="radio"]:checked + span::before {
  content: "\E958";
}
.cb-widget .radio input[type="radio"]:focus + span::before,
.cb-widget .radio-inline input[type="radio"]:focus + span::before {
  color: #0077c8;
}
.cb-widget .radio input[type="radio"]:focus + span::after,
.cb-widget .radio-inline input[type="radio"]:focus + span::after {
  content: " ";
  position: absolute;
  display: block;
  top: -2px;
  right: -4px;
  height: 1.5em;
  width: 1.5em;
  padding: 2px;
  border: 1px solid #0077c8;
}
.cb-widget .radio input[type="radio"]:hover + span::before,
.cb-widget .radio-inline input[type="radio"]:hover + span::before {
  color: #0077c8;
}
.cb-widget .radio input[type="radio"].no-cb,
.cb-widget .radio-inline input[type="radio"].no-cb {
  opacity: 1;
  left: 0;
}
.cb-widget .radio input[type="radio"]:not(.cb-radio),
.cb-widget .radio-inline input[type="radio"]:not(.cb-radio) {
  opacity: 1;
  left: 0;
}
.cb-widget .checkbox input[type=checkbox],
.cb-widget .checkbox-inline input[type=checkbox] {
  position: relative;
  margin-left: -17px;
  opacity: 0;
}
.cb-widget .checkbox input[type=checkbox] + span,
.cb-widget .checkbox-inline input[type=checkbox] + span {
  position: relative;
  margin-right: 10px;
}
.cb-widget .checkbox input[type=checkbox] + span::before,
.cb-widget .checkbox-inline input[type=checkbox] + span::before {
  font-family: 'CB Icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\E95A";
  position: absolute;
  display: block;
  top: 2px;
  right: 0;
  color: #505050;
  font-size: 1em;
}
.cb-widget .checkbox input[type=checkbox]:checked + span::before,
.cb-widget .checkbox-inline input[type=checkbox]:checked + span::before {
  content: "\E95C";
}
.cb-widget .checkbox input[type=checkbox]:focus + span::before,
.cb-widget .checkbox-inline input[type=checkbox]:focus + span::before {
  color: #0077c8;
}
.cb-widget .checkbox input[type=checkbox]:focus + span::after,
.cb-widget .checkbox-inline input[type=checkbox]:focus + span::after {
  content: " ";
  position: absolute;
  display: block;
  top: -2px;
  right: -4px;
  height: 1.5em;
  width: 1.5em;
  padding: 2px;
  border: 1px solid #0077c8;
}
.cb-widget .checkbox input[type=checkbox].no-cb,
.cb-widget .checkbox-inline input[type=checkbox].no-cb {
  opacity: 1;
  left: 0;
}
.cb-widget .checkbox input[type=checkbox]:not(.cb-checkbox),
.cb-widget .checkbox-inline input[type=checkbox]:not(.cb-checkbox) {
  opacity: 1;
  left: 0;
}
.cb-widget .checkbox,
.cb-widget .radio {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 24px;
}
.cb-widget .checkbox + .checkbox,
.cb-widget .radio + .radio {
  margin-top: 0;
}
.cb-widget input[type="radio"][disabled] span::before,
.cb-widget input[type="checkbox"][disabled] span::before,
.cb-widget input[type="radio"].disabled span::before,
.cb-widget input[type="checkbox"].disabled span::before,
.cb-widget fieldset[disabled] input[type="radio"] span::before,
.cb-widget fieldset[disabled] input[type="checkbox"] span::before {
  background-color: #eeeeee;
  border-color: #e5e2e0;
  cursor: default;
}
.cb-widget input[type="radio"][disabled]:hover + span::before,
.cb-widget input[type="checkbox"][disabled]:hover + span::before,
.cb-widget input[type="radio"].disabled:hover + span::before,
.cb-widget input[type="checkbox"].disabled:hover + span::before,
.cb-widget fieldset[disabled] input[type="radio"]:hover + span::before,
.cb-widget fieldset[disabled] input[type="checkbox"]:hover + span::before {
  border-color: #e5e2e0;
}
.cb-widget input[type="radio"][disabled] label,
.cb-widget input[type="checkbox"][disabled] label,
.cb-widget input[type="radio"].disabled label,
.cb-widget input[type="checkbox"].disabled label,
.cb-widget fieldset[disabled] input[type="radio"] label,
.cb-widget fieldset[disabled] input[type="checkbox"] label {
  color: #989795;
}
.cb-widget .radio-inline.disabled span::before,
.cb-widget .checkbox-inline.disabled span::before,
.cb-widget fieldset[disabled] .radio-inline span::before,
.cb-widget fieldset[disabled] .checkbox-inline span::before {
  background-color: #eeeeee;
  border-color: #e5e2e0;
  cursor: default;
}
.cb-widget .radio-inline.disabled:hover + span::before,
.cb-widget .checkbox-inline.disabled:hover + span::before,
.cb-widget fieldset[disabled] .radio-inline:hover + span::before,
.cb-widget fieldset[disabled] .checkbox-inline:hover + span::before {
  border-color: #e5e2e0;
}
.cb-widget .radio-inline.disabled label,
.cb-widget .checkbox-inline.disabled label,
.cb-widget fieldset[disabled] .radio-inline label,
.cb-widget fieldset[disabled] .checkbox-inline label {
  color: #989795;
}
.cb-widget .radio.disabled label,
.cb-widget .checkbox.disabled label,
.cb-widget fieldset[disabled] .radio label,
.cb-widget fieldset[disabled] .checkbox label {
  color: #989795;
}
.cb-widget .radio.disabled span::before,
.cb-widget .checkbox.disabled span::before,
.cb-widget fieldset[disabled] .radio span::before,
.cb-widget fieldset[disabled] .checkbox span::before {
  color: #e5e2e0 !important;
  cursor: default;
}
.cb-widget .radio.disabled:hover + span::before,
.cb-widget .checkbox.disabled:hover + span::before,
.cb-widget fieldset[disabled] .radio:hover + span::before,
.cb-widget fieldset[disabled] .checkbox:hover + span::before {
  border-color: #e5e2e0;
}
.cb-widget .form-control-static {
  margin-top: 0;
}
.cb-widget .form-horizontal .radio,
.cb-widget .form-horizontal .checkbox {
  min-height: 1.5em;
}
.cb-widget .cb-file-upload input[type="file"] {
  display: none;
  /* Note: display:none on the input won't trigger the click event in WebKit.
	     Setting visibility to hidden and width 0 works.*/
  visibility: hidden;
  width: 0;
  height: 0;
}
.cb-widget .cb-file-upload .btn {
  margin-top: 0;
  margin-bottom: 24px;
}
.cb-widget .cb-file-element {
  white-space: nowrap;
  line-height: 24px;
  display: block;
}
.cb-widget .cb-file-element .cb-file-element-rm {
  padding-left: 16px;
  font-size: 0.75rem;
  cursor: pointer;
  text-decoration: none;
}
.cb-widget .cb-file-name {
  word-break: break-word;
}
.cb-widget .has-success .help-block,
.cb-widget .has-success .radio,
.cb-widget .has-success .checkbox,
.cb-widget .has-success .radio-inline,
.cb-widget .has-success .checkbox-inline {
  color: #3c763d;
}
.cb-widget .has-success .form-control {
  border-color: #3c763d;
}
.cb-widget .has-success .form-control:focus {
  border-color: #2b542c;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.cb-widget .has-success .input-group-addon {
  color: #3c763d;
  border-color: #3c763d;
  background-color: #dff0d8;
}
.cb-widget .has-success .form-control-feedback {
  color: #3c763d;
}
.cb-widget .has-success .cb-input-state {
  position: relative;
}
.cb-widget .has-success .cb-input-state i.cb-check {
  position: absolute;
  color: #3c763d;
  top: 16px;
  right: 12px;
}
.cb-widget .has-success .cb-input-state input {
  padding-right: 36px;
}
.cb-widget .has-success .cb-input-state input:focus + i.cb-check {
  color: #2b542c;
}
.cb-widget .has-success .radio,
.cb-widget .has-success .checkbox,
.cb-widget .has-success .radio-inline,
.cb-widget .has-success .checkbox-inline {
  color: #505050;
}
.cb-widget .has-success .checkbox span::before,
.cb-widget .has-success .checkbox-inline span::before {
  color: #3c763d !important;
}
.cb-widget .has-success .checkbox input[type="checkbox"]:focus + span::before,
.cb-widget .has-success .checkbox-inline input[type="checkbox"]:focus + span::before {
  color: #2b542c !important;
}
.cb-widget .has-success .radio span::before,
.cb-widget .has-success .radio-inline span::before {
  color: #3c763d !important;
}
.cb-widget .has-success .radio input[type="radio"]:focus + span::before,
.cb-widget .has-success .radio-inline input[type="radio"]:focus + span::before {
  color: #2b542c !important;
}
.cb-widget .has-error .help-block,
.cb-widget .has-error .radio,
.cb-widget .has-error .checkbox,
.cb-widget .has-error .radio-inline,
.cb-widget .has-error .checkbox-inline {
  color: #c13145;
}
.cb-widget .has-error .form-control {
  border-color: #c13145;
}
.cb-widget .has-error .form-control:focus {
  border-color: #982736;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.cb-widget .has-error .input-group-addon {
  color: #c13145;
  border-color: #c13145;
  background-color: #f0f0f0;
}
.cb-widget .has-error .form-control-feedback {
  color: #c13145;
}
.cb-widget .has-error .cb-input-state {
  position: relative;
}
.cb-widget .has-error .cb-input-state i.cb-x-mark {
  position: absolute;
  color: #c13145;
  top: 16px;
  right: 12px;
}
.cb-widget .has-error .cb-input-state input {
  padding-right: 36px;
}
.cb-widget .has-error .cb-input-state input:focus + i.cb-x-mark {
  color: #982736;
}
.cb-widget .has-error .radio,
.cb-widget .has-error .checkbox,
.cb-widget .has-error .radio-inline,
.cb-widget .has-error .checkbox-inline {
  color: #505050;
}
.cb-widget .has-error .checkbox span::before,
.cb-widget .has-error .checkbox-inline span::before {
  color: #c13145 !important;
}
.cb-widget .has-error .checkbox input[type="checkbox"]:focus + span::before,
.cb-widget .has-error .checkbox-inline input[type="checkbox"]:focus + span::before {
  color: #982736 !important;
}
.cb-widget .has-error .radio span::before,
.cb-widget .has-error .radio-inline span::before {
  color: #c13145 !important;
}
.cb-widget .has-error .radio input[type="radio"]:focus + span::before,
.cb-widget .has-error .radio-inline input[type="radio"]:focus + span::before {
  color: #982736 !important;
}
.cb-widget .has-error .cb-file-element {
  color: #c13145;
}
.cb-widget .has-warning .help-block,
.cb-widget .has-warning .radio,
.cb-widget .has-warning .checkbox,
.cb-widget .has-warning .radio-inline,
.cb-widget .has-warning .checkbox-inline {
  color: #ff9900;
}
.cb-widget .has-warning .form-control {
  border-color: #ff9900;
}
.cb-widget .has-warning .form-control:focus {
  border-color: #cc7a00;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.cb-widget .has-warning .input-group-addon {
  color: #ff9900;
  border-color: #ff9900;
  background-color: #f0f0f0;
}
.cb-widget .has-warning .form-control-feedback {
  color: #ff9900;
}
.cb-widget .has-warning .cb-input-state {
  position: relative;
}
.cb-widget .has-warning .cb-input-state i.cb-exclamation {
  position: absolute;
  color: #ff9900;
  top: 16px;
  right: 12px;
}
.cb-widget .has-warning .cb-input-state input {
  padding-right: 36px;
}
.cb-widget .has-warning .cb-input-state input:focus + i.cb-exclamation {
  color: #cc7a00;
}
.cb-widget .has-warning .radio,
.cb-widget .has-warning .checkbox,
.cb-widget .has-warning .radio-inline,
.cb-widget .has-warning .checkbox-inline {
  color: #505050;
}
.cb-widget .has-warning .checkbox span::before,
.cb-widget .has-warning .checkbox-inline span::before {
  color: #ff9900 !important;
}
.cb-widget .has-warning .checkbox input[type="checkbox"]:focus + span::before,
.cb-widget .has-warning .checkbox-inline input[type="checkbox"]:focus + span::before {
  color: #cc7a00 !important;
}
.cb-widget .has-warning .radio span::before,
.cb-widget .has-warning .radio-inline span::before {
  color: #ff9900 !important;
}
.cb-widget .has-warning .radio input[type="radio"]:focus + span::before,
.cb-widget .has-warning .radio-inline input[type="radio"]:focus + span::before {
  color: #cc7a00 !important;
}
.cb-widget .has-warning .cb-file-element {
  color: #ff9900;
}
.cb-widget .cb-error-msg {
  color: #c13145;
  margin: 0;
}
.cb-widget .cb-warning-msg {
  color: #ff9900;
  margin: 0;
  padding: 0;
}
.cb-widget .cb-required::after {
  content: "*";
  position: absolute;
  color: #c13145;
  font-size: 1rem;
  margin-left: 3px;
}
.cb-widget .cb-notice-callout.cb-notice-error {
  border-left: 4px solid #c13145;
}
.cb-widget .cb-notice-callout.cb-notice-error h3 {
  font-family: "Helvetica Bold", Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: #c13145;
}
.cb-widget .cb-notice-callout.cb-notice-error .cb-callout-content {
  position: relative;
  padding-left: 90px;
}
.cb-widget .cb-notice-callout.cb-notice-error .cb-callout-content::before {
  font-family: 'CB Icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #c13145;
  content: "\E918";
  font-size: 3.125rem;
  left: 20px;
  position: absolute;
}
.cb-widget .cb-input-search {
  position: relative;
}
.cb-widget .cb-input-search i.cb-search {
  position: absolute;
  color: #737373;
  top: 16px;
  left: 12px;
}
.cb-widget .cb-input-search input {
  padding-left: 36px;
}
.cb-widget .cb-input-search input:focus + i.cb-search {
  color: #5a5a5a;
}
.cb-widget .cb-input-edit {
  position: relative;
}
.cb-widget .cb-input-edit i.cb-compose {
  position: absolute;
  color: #737373;
  top: 16px;
  left: 12px;
}
.cb-widget .cb-input-edit input {
  padding-left: 36px;
}
.cb-widget .cb-input-edit input:focus + i.cb-compose {
  color: #5a5a5a;
}
.cb-widget form label {
  font-weight: 400;
}
.cb-widget form .btn {
  margin-top: 24px;
}
.cb-widget form .form-group + .btn {
  margin-top: 0;
}
.cb-widget .form-control[disabled],
.cb-widget .form-control[readonly],
.cb-widget fieldset[disabled] .form-control {
  cursor: default;
}
.cb-widget input[type="radio"][disabled],
.cb-widget input[type="checkbox"][disabled],
.cb-widget input[type="radio"].disabled,
.cb-widget input[type="checkbox"].disabled,
.cb-widget fieldset[disabled] input[type="radio"],
.cb-widget fieldset[disabled] input[type="checkbox"] {
  cursor: default;
}
.cb-widget .radio-inline.disabled,
.cb-widget .checkbox-inline.disabled,
.cb-widget fieldset[disabled] .radio-inline,
.cb-widget fieldset[disabled] .checkbox-inline {
  cursor: default;
}
.cb-widget .radio.disabled label,
.cb-widget .checkbox.disabled label,
.cb-widget fieldset[disabled] .radio label,
.cb-widget fieldset[disabled] .checkbox label {
  cursor: default;
}
.cb-widget select[multiple] {
  padding: 23px 0;
}
.cb-widget select[multiple] option {
  font-size: 0.875rem;
  line-height: 1.71428571em;
  padding: 4px 20px;
  border-left: 3px solid #f0f0f0;
  white-space: nowrap;
  font-weight: 400;
  color: #505050;
}
.cb-widget select[multiple] option:hover {
  background-color: transparent;
  color: #00539b;
  border-left: 3px solid #00539b;
  outline: 0;
}
.cb-widget select[multiple] option:active {
  background-color: #cccac8;
  border-left: 3px solid #cccac8;
}
.cb-widget select[multiple] option:checked {
  padding-left: 23px;
  border-left: 3px;
}
.cb-widget legend {
  margin-bottom: 23px;
  line-height: 2em;
}
.cb-widget fieldset fieldset legend {
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  line-height: 1.5em;
  color: #505050;
  margin-bottom: 0;
  border-bottom: 0;
}
.cb-widget .form-group {
  margin-bottom: 24px;
}
.cb-widget .form-group .form-group {
  margin-bottom: 0;
}
.cb-widget .form-group .radio {
  margin-top: 0;
  margin-bottom: 0;
}
.cb-widget .form-group .checkbox {
  margin-top: 0;
  margin-bottom: 0;
}
.cb-widget .form-group label {
  margin-bottom: 0px;
}
.cb-widget .cb-disabled-color {
  color: #989795;
}
.cb-widget .cb-select-container {
  position: relative;
  width: auto;
}
.cb-widget .cb-select-container select.replaced {
  line-height: 2em;
  position: relative;
  z-index: 10;
  width: 100%;
  height: 48px;
  opacity: 0;
  border: 0 none;
}
.cb-widget .cb-select-container span.cb-select {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 48px;
}
.cb-widget .cb-select-container span.cb-select > span {
  font-size: 1rem;
  line-height: 3em;
  display: block;
  padding: 0 9px;
  color: #505050;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-radius: 0;
  background-color: #f0f0f0;
}
.cb-widget .cb-select-container span.cb-select span span {
  display: block;
  height: 46px;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
}
.cb-widget .cb-select-container span.cb-select .cb-select-arrow {
  position: absolute;
  display: block;
  right: -10px;
  top: 0;
  height: 46px;
  width: 38px;
  border-left: 1px solid #d9d9d9;
  font-family: 'CB Icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.6875rem;
  font-weight: 700;
}
.cb-widget .cb-select-container span.cb-select .cb-select-arrow::before {
  position: absolute;
  display: block;
  content: "\E913";
  top: 17px;
  left: 13px;
}
.cb-widget .cb-select-container span.cb-select.focus > span {
  border-color: #0077c8;
  border-width: 1px;
}
.cb-widget .cb-select-container span.cb-select.mouseover > span {
  border-color: #d9d9d9;
}
.cb-widget .cb-select-container span.cb-select.mouseover .cb-select-arrow {
  border-left: 1px solid #d9d9d9;
}
.cb-widget .cb-select-container span.cb-select.disabled > span {
  cursor: default;
  background-color: #eeeeee;
  color: #989795;
  opacity: 1;
}
.cb-widget .has-success .cb-select-container span.cb-select > span {
  border-color: #3c763d;
}
.cb-widget .has-success .cb-select-container span.cb-select.focus > span {
  border-color: #2b542c;
}
.cb-widget .has-error .cb-select-container span.cb-select > span {
  border-color: #c13145;
}
.cb-widget .has-error .cb-select-container span.cb-select.focus > span {
  border-color: #982736;
}
.cb-widget input.cb-credit-card {
  background: #f0f0f0 url(https://atlas.collegeboard.org/widgets/release/2020-08-31/5b0ff4b8a12ff1e3325dd77d73065119.png) -1000px 0 no-repeat;
}
.cb-widget input.cb-credit-card.amex {
  background-position: right 0;
}
.cb-widget input.cb-credit-card.diners {
  background-position: right -32px;
}
.cb-widget input.cb-credit-card.discover {
  background-position: right -180px;
}
.cb-widget input.cb-credit-card.jcb {
  background-position: right -143px;
}
.cb-widget input.cb-credit-card.mastercard {
  background-position: right -69px;
}
.cb-widget input.cb-credit-card.visa {
  background-position: right -106px;
}
.cb-widget input.cb-credit-card.paypal {
  background-position: right -217px;
}
.cb-widget .cb-int-phone-container {
  position: relative;
  width: 100%;
}
.cb-widget .cb-int-phone-container .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
}
.cb-widget .cb-int-phone-container .flag-dropdown .selected-flag {
  cursor: pointer;
  z-index: 1;
  position: relative;
  width: 52px;
  height: 100%;
  padding: 1px 1px 1px 10px;
  display: inherit;
}
.cb-widget .cb-int-phone-container .flag-dropdown .selected-flag .flag {
  position: absolute;
  top: 50%;
  margin-top: -6px;
}
.cb-widget .cb-int-phone-container .flag-dropdown .selected-flag .flag .arrow {
  display: block;
  position: relative;
  top: 3px;
  margin-top: -2px;
  left: 26px;
  font-family: 'CB Icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.6875rem;
  font-weight: 700;
  color: #505050;
}
.cb-widget .cb-int-phone-container .flag-dropdown .selected-flag .flag .arrow::before {
  display: block;
  content: "\E913";
}
.cb-widget .cb-int-phone-container .flag-dropdown .selected-flag .flag .arrow.up::before {
  content: "\E950";
}
.cb-widget .cb-int-phone-container .flag-dropdown .selected-flag .flag .arrow.down::before {
  content: "\E913";
}
.cb-widget .cb-int-phone-container .flag-dropdown .selected-flag:focus {
  border: 1px solid #0077c8;
  border-radius: 0;
  outline-width: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding: 0 0 0 9px;
}
.cb-widget .cb-int-phone-container .flag-dropdown .country-list {
  display: none;
  list-style: none;
  position: absolute;
  z-index: 2;
  padding: 10px 0;
  margin: 0 0 0 -1px;
  border: 1px solid #d9d9d9;
  border-radius: 0;
  background-color: #f0f0f0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  width: 60px;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  left: 1px;
}
.cb-widget .cb-int-phone-container .flag-dropdown .country-list li {
  height: 25px;
}
.cb-widget .cb-int-phone-container .flag-dropdown .country-list li a {
  opacity: 0.4;
  padding: 5px 10px;
  display: inline-block;
  width: 100%;
  height: 100%;
}
.cb-widget .cb-int-phone-container .flag-dropdown .country-list li a:focus,
.cb-widget .cb-int-phone-container .flag-dropdown .country-list li a:hover {
  opacity: 1;
}
.cb-widget .cb-int-phone-container .flag-dropdown .country-list li a:focus {
  outline-style: solid;
  outline-color: #0077c8;
  outline-width: 1px;
}
.cb-widget .cb-int-phone-container .flag-dropdown .country-list li a.active {
  opacity: 1;
  background-color: #ebe9e6;
}
.cb-widget .cb-int-phone-container .cb-int-phone {
  position: relative;
  z-index: 0;
  margin: 0;
  padding-left: 55px;
}
.cb-widget .cb-int-phone-container.slide-down .cb-int-phone.active-flag {
  border-bottom-left-radius: 0;
}
.cb-widget .cb-int-phone-container.slide-down .country-list {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.cb-widget .cb-int-phone-container.slide-up .country-list {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.cb-widget .cb-int-phone-container.slide-up .cb-int-phone.active-flag {
  border-top-left-radius: 0;
}
.cb-widget .cb-int-phone-container.cb-input-focus {
  outline-width: 0;
}
.cb-widget .cb-int-phone-container.cb-input-focus .cb-int-phone {
  padding-left: 55px;
  border-color: #0077c8;
  border-width: 1px;
}
.cb-widget .cb-int-phone-container.cb-input-focus .selected-flag {
  border: 1px solid #d9d9d9;
  border-radius: 0;
  outline-width: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding: 0 0 0 9px;
  border-right: 1px solid #0077c8;
}
.cb-widget .flag {
  background-image: url(https://atlas.collegeboard.org/widgets/release/2020-08-31/3fbe6c36376b9973403373264557d011.png);
  background-position: 0 -2145px;
  width: 16px;
  height: 11px;
  display: block;
}
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
  .cb-widget .flag {
    background-image: url(https://atlas.collegeboard.org/widgets/release/2020-08-31/1363fd1510e9450ad512869322e2ace1.png);
    background-size: 16px 2156px;
  }
}
.cb-widget .ad {
  background-position: 0 0px;
}
.cb-widget .ae {
  background-position: 0 -11px;
}
.cb-widget .af {
  background-position: 0 -22px;
}
.cb-widget .ag {
  background-position: 0 -33px;
}
.cb-widget .al {
  background-position: 0 -44px;
}
.cb-widget .am {
  background-position: 0 -55px;
}
.cb-widget .ao {
  background-position: 0 -66px;
}
.cb-widget .ar {
  background-position: 0 -77px;
}
.cb-widget .at {
  background-position: 0 -88px;
}
.cb-widget .au {
  background-position: 0 -99px;
}
.cb-widget .az {
  background-position: 0 -110px;
}
.cb-widget .ba {
  background-position: 0 -121px;
}
.cb-widget .bb {
  background-position: 0 -132px;
}
.cb-widget .bd {
  background-position: 0 -143px;
}
.cb-widget .be {
  background-position: 0 -154px;
}
.cb-widget .bf {
  background-position: 0 -165px;
}
.cb-widget .bg {
  background-position: 0 -176px;
}
.cb-widget .bh {
  background-position: 0 -187px;
}
.cb-widget .bi {
  background-position: 0 -198px;
}
.cb-widget .bj {
  background-position: 0 -209px;
}
.cb-widget .bn {
  background-position: 0 -220px;
}
.cb-widget .bo {
  background-position: 0 -231px;
}
.cb-widget .br {
  background-position: 0 -242px;
}
.cb-widget .bs {
  background-position: 0 -253px;
}
.cb-widget .bt {
  background-position: 0 -264px;
}
.cb-widget .bw {
  background-position: 0 -275px;
}
.cb-widget .by {
  background-position: 0 -286px;
}
.cb-widget .bz {
  background-position: 0 -297px;
}
.cb-widget .ca {
  background-position: 0 -308px;
}
.cb-widget .cd {
  background-position: 0 -319px;
}
.cb-widget .cf {
  background-position: 0 -330px;
}
.cb-widget .cg {
  background-position: 0 -341px;
}
.cb-widget .ch {
  background-position: 0 -352px;
}
.cb-widget .ci {
  background-position: 0 -363px;
}
.cb-widget .cl {
  background-position: 0 -374px;
}
.cb-widget .cm {
  background-position: 0 -385px;
}
.cb-widget .cn {
  background-position: 0 -396px;
}
.cb-widget .co {
  background-position: 0 -407px;
}
.cb-widget .cr {
  background-position: 0 -418px;
}
.cb-widget .cu {
  background-position: 0 -429px;
}
.cb-widget .cv {
  background-position: 0 -440px;
}
.cb-widget .cy {
  background-position: 0 -451px;
}
.cb-widget .cz {
  background-position: 0 -462px;
}
.cb-widget .de {
  background-position: 0 -473px;
}
.cb-widget .dj {
  background-position: 0 -484px;
}
.cb-widget .dk {
  background-position: 0 -495px;
}
.cb-widget .dm {
  background-position: 0 -506px;
}
.cb-widget .do {
  background-position: 0 -517px;
}
.cb-widget .dz {
  background-position: 0 -528px;
}
.cb-widget .ec {
  background-position: 0 -539px;
}
.cb-widget .ee {
  background-position: 0 -550px;
}
.cb-widget .eg {
  background-position: 0 -561px;
}
.cb-widget .er {
  background-position: 0 -572px;
}
.cb-widget .es {
  background-position: 0 -583px;
}
.cb-widget .et {
  background-position: 0 -594px;
}
.cb-widget .fi {
  background-position: 0 -605px;
}
.cb-widget .fj {
  background-position: 0 -616px;
}
.cb-widget .fm {
  background-position: 0 -627px;
}
.cb-widget .fr {
  background-position: 0 -638px;
}
.cb-widget .ga {
  background-position: 0 -649px;
}
.cb-widget .gb {
  background-position: 0 -660px;
}
.cb-widget .gd {
  background-position: 0 -671px;
}
.cb-widget .ge {
  background-position: 0 -682px;
}
.cb-widget .gh {
  background-position: 0 -693px;
}
.cb-widget .gm {
  background-position: 0 -704px;
}
.cb-widget .gn {
  background-position: 0 -715px;
}
.cb-widget .gq {
  background-position: 0 -726px;
}
.cb-widget .gr {
  background-position: 0 -737px;
}
.cb-widget .gt {
  background-position: 0 -748px;
}
.cb-widget .gw {
  background-position: 0 -759px;
}
.cb-widget .gy {
  background-position: 0 -770px;
}
.cb-widget .hn {
  background-position: 0 -781px;
}
.cb-widget .hr {
  background-position: 0 -792px;
}
.cb-widget .ht {
  background-position: 0 -803px;
}
.cb-widget .hu {
  background-position: 0 -814px;
}
.cb-widget .id {
  background-position: 0 -825px;
}
.cb-widget .ie {
  background-position: 0 -836px;
}
.cb-widget .il {
  background-position: 0 -847px;
}
.cb-widget .in:not(.collapse) {
  background-position: 0 -858px;
}
.cb-widget .iq {
  background-position: 0 -869px;
}
.cb-widget .ir {
  background-position: 0 -880px;
}
.cb-widget .is {
  background-position: 0 -891px;
}
.cb-widget .it {
  background-position: 0 -902px;
}
.cb-widget .jm {
  background-position: 0 -913px;
}
.cb-widget .jo {
  background-position: 0 -924px;
}
.cb-widget .jp {
  background-position: 0 -935px;
}
.cb-widget .ke {
  background-position: 0 -946px;
}
.cb-widget .kg {
  background-position: 0 -957px;
}
.cb-widget .kh {
  background-position: 0 -968px;
}
.cb-widget .ki {
  background-position: 0 -979px;
}
.cb-widget .km {
  background-position: 0 -990px;
}
.cb-widget .kn {
  background-position: 0 -1001px;
}
.cb-widget .kp {
  background-position: 0 -1012px;
}
.cb-widget .kr {
  background-position: 0 -1023px;
}
.cb-widget .kw {
  background-position: 0 -1034px;
}
.cb-widget .kz {
  background-position: 0 -1045px;
}
.cb-widget .la {
  background-position: 0 -1056px;
}
.cb-widget .lb {
  background-position: 0 -1067px;
}
.cb-widget .lc {
  background-position: 0 -1078px;
}
.cb-widget .li {
  background-position: 0 -1089px;
}
.cb-widget .lk {
  background-position: 0 -1100px;
}
.cb-widget .lr {
  background-position: 0 -1111px;
}
.cb-widget .ls {
  background-position: 0 -1122px;
}
.cb-widget .lt {
  background-position: 0 -1133px;
}
.cb-widget .lu {
  background-position: 0 -1144px;
}
.cb-widget .lv {
  background-position: 0 -1155px;
}
.cb-widget .ly {
  background-position: 0 -1166px;
}
.cb-widget .ma {
  background-position: 0 -1177px;
}
.cb-widget .mc {
  background-position: 0 -1188px;
}
.cb-widget .md {
  background-position: 0 -1199px;
}
.cb-widget .me {
  background-position: 0 -1210px;
}
.cb-widget .mg {
  background-position: 0 -1221px;
}
.cb-widget .mh {
  background-position: 0 -1232px;
}
.cb-widget .mk {
  background-position: 0 -1243px;
}
.cb-widget .ml {
  background-position: 0 -1254px;
}
.cb-widget .mm {
  background-position: 0 -1265px;
}
.cb-widget .mn {
  background-position: 0 -1276px;
}
.cb-widget .mr {
  background-position: 0 -1287px;
}
.cb-widget .mt {
  background-position: 0 -1298px;
}
.cb-widget .mu {
  background-position: 0 -1309px;
}
.cb-widget .mv {
  background-position: 0 -1320px;
}
.cb-widget .mw {
  background-position: 0 -1331px;
}
.cb-widget .mx {
  background-position: 0 -1342px;
}
.cb-widget .my {
  background-position: 0 -1353px;
}
.cb-widget .mz {
  background-position: 0 -1364px;
}
.cb-widget .na {
  background-position: 0 -1375px;
}
.cb-widget .ne {
  background-position: 0 -1386px;
}
.cb-widget .ng {
  background-position: 0 -1397px;
}
.cb-widget .ni {
  background-position: 0 -1408px;
}
.cb-widget .nl {
  background-position: 0 -1419px;
}
.cb-widget .no {
  background-position: 0 -1430px;
}
.cb-widget .np {
  background-position: 0 -1441px;
}
.cb-widget .nr {
  background-position: 0 -1452px;
}
.cb-widget .nz {
  background-position: 0 -1463px;
}
.cb-widget .om {
  background-position: 0 -1474px;
}
.cb-widget .pa {
  background-position: 0 -1485px;
}
.cb-widget .pe {
  background-position: 0 -1496px;
}
.cb-widget .pg {
  background-position: 0 -1507px;
}
.cb-widget .ph {
  background-position: 0 -1518px;
}
.cb-widget .pk {
  background-position: 0 -1529px;
}
.cb-widget .pl {
  background-position: 0 -1540px;
}
.cb-widget .pt {
  background-position: 0 -1551px;
}
.cb-widget .pw {
  background-position: 0 -1562px;
}
.cb-widget .py {
  background-position: 0 -1573px;
}
.cb-widget .qa {
  background-position: 0 -1584px;
}
.cb-widget .ro {
  background-position: 0 -1595px;
}
.cb-widget .rs {
  background-position: 0 -1606px;
}
.cb-widget .ru {
  background-position: 0 -1617px;
}
.cb-widget .rw {
  background-position: 0 -1628px;
}
.cb-widget .sa {
  background-position: 0 -1639px;
}
.cb-widget .sb {
  background-position: 0 -1650px;
}
.cb-widget .sc {
  background-position: 0 -1661px;
}
.cb-widget .sd {
  background-position: 0 -1672px;
}
.cb-widget .se {
  background-position: 0 -1683px;
}
.cb-widget .sg {
  background-position: 0 -1694px;
}
.cb-widget .si {
  background-position: 0 -1705px;
}
.cb-widget .sk {
  background-position: 0 -1716px;
}
.cb-widget .sl {
  background-position: 0 -1727px;
}
.cb-widget .sm {
  background-position: 0 -1738px;
}
.cb-widget .sn {
  background-position: 0 -1749px;
}
.cb-widget .so {
  background-position: 0 -1760px;
}
.cb-widget .sr {
  background-position: 0 -1771px;
}
.cb-widget .ss {
  background-position: 0 -1782px;
}
.cb-widget .st {
  background-position: 0 -1793px;
}
.cb-widget .sv {
  background-position: 0 -1804px;
}
.cb-widget .sy {
  background-position: 0 -1815px;
}
.cb-widget .sz {
  background-position: 0 -1826px;
}
.cb-widget .td {
  background-position: 0 -1837px;
}
.cb-widget .tg {
  background-position: 0 -1848px;
}
.cb-widget .th {
  background-position: 0 -1859px;
}
.cb-widget .tj {
  background-position: 0 -1870px;
}
.cb-widget .tl {
  background-position: 0 -1881px;
}
.cb-widget .tm {
  background-position: 0 -1892px;
}
.cb-widget .tn {
  background-position: 0 -1903px;
}
.cb-widget .to {
  background-position: 0 -1914px;
}
.cb-widget .tr {
  background-position: 0 -1925px;
}
.cb-widget .tt {
  background-position: 0 -1936px;
}
.cb-widget .tv {
  background-position: 0 -1947px;
}
.cb-widget .tz {
  background-position: 0 -1958px;
}
.cb-widget .ua {
  background-position: 0 -1969px;
}
.cb-widget .ug {
  background-position: 0 -1980px;
}
.cb-widget .us {
  background-position: 0 -1991px;
}
.cb-widget .uy {
  background-position: 0 -2002px;
}
.cb-widget .uz {
  background-position: 0 -2013px;
}
.cb-widget .va {
  background-position: 0 -2024px;
}
.cb-widget .vc {
  background-position: 0 -2035px;
}
.cb-widget .ve {
  background-position: 0 -2046px;
}
.cb-widget .vn {
  background-position: 0 -2057px;
}
.cb-widget .vu {
  background-position: 0 -2068px;
}
.cb-widget .ws {
  background-position: 0 -2079px;
}
.cb-widget .xk {
  background-position: 0 -2090px;
}
.cb-widget .ye {
  background-position: 0 -2101px;
}
.cb-widget .za {
  background-position: 0 -2112px;
}
.cb-widget .zm {
  background-position: 0 -2123px;
}
.cb-widget .zw {
  background-position: 0 -2134px;
}
.cb-widget .zzz {
  background-position: 0 -2145px;
}
.cb-widget .cb-hero {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #ffffff;
  background-color: #b2b2b2;
}
.cb-widget .cb-hero .content h1,
.cb-widget .cb-hero .content .h1,
.cb-widget .cb-hero .content h2,
.cb-widget .cb-hero .content .h2,
.cb-widget .cb-hero .content h3,
.cb-widget .cb-hero .content .h3,
.cb-widget .cb-hero .content h4,
.cb-widget .cb-hero .content .h4 {
  font-family: "Roboto Slab", sans-serif;
  font-size: 2.25rem;
  color: #ffffff;
  line-height: 1.1666em;
  margin: -12px 0 12px;
}
.cb-widget .cb-hero .content h1:first-child,
.cb-widget .cb-hero .content .h1:first-child,
.cb-widget .cb-hero .content h2:first-child,
.cb-widget .cb-hero .content .h2:first-child,
.cb-widget .cb-hero .content h3:first-child,
.cb-widget .cb-hero .content .h3:first-child,
.cb-widget .cb-hero .content h4:first-child,
.cb-widget .cb-hero .content .h4:first-child {
  display: inline-block;
  border-top-style: solid;
  border-top-width: 0.2em;
  border-top-color: #ffffff;
  padding-top: 0.2em;
}
.cb-widget .cb-hero .btn {
  font-size: 0.875rem;
  line-height: 1.71428571em;
  background: #fedb00;
  color: #1e1e1e;
  border: 1px solid #fedb00;
  padding: 11px 18px;
}
.cb-widget .cb-hero .btn:focus,
.cb-widget .cb-hero .btn.focus {
  outline-style: solid;
  outline-color: #0077c8;
  outline-width: 1px;
  outline-offset: -1px;
}
.cb-widget .cb-hero .btn:focus,
.cb-widget .cb-hero .btn:hover {
  text-decoration: underline;
}
.cb-widget .cb-hero > .container {
  height: 100%;
}
.cb-widget .cb-hero > .container > .row {
  height: 100%;
}
.cb-widget .cb-hero > .container > .row > .flex-block {
  height: 100%;
  display: flex;
  align-items: center;
}
.cb-widget .cb-hero > .container > .row > .flex-block .content {
  width: 100%;
  margin: 30px 0;
}
.cb-widget .cb-hero > .container > .row > .flex-block .content p {
  margin-bottom: 18px;
  font-family: "Roboto Bold", sans-serif;
}
.cb-widget .cb-hero .cb-hero-cta {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.cb-widget .cb-hero .cb-hero-cta li {
  display: inline-block;
  margin-bottom: 12px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.cb-widget .cb-hero .cb-hero-cta li:nth-child(2) {
  margin-left: 12px;
}
.cb-widget .cb-hero .cb-hero-cta li:nth-child(2) .btn {
  background-color: transparent;
  color: #ffffff;
  border: 1px solid #ffffff;
}
.cb-widget .cb-hero .cb-hero-cta li:nth-child(2) .cb-primary-cta {
  background: #fedb00;
  border: 1px solid #fedb00;
  color: #1e1e1e;
}
.cb-widget .cb-hero.cb-hero-light {
  background-color: #ffffff;
}
.cb-widget .cb-hero.cb-hero-light h1,
.cb-widget .cb-hero.cb-hero-light .h1,
.cb-widget .cb-hero.cb-hero-light h2,
.cb-widget .cb-hero.cb-hero-light .h2,
.cb-widget .cb-hero.cb-hero-light h3,
.cb-widget .cb-hero.cb-hero-light .h3,
.cb-widget .cb-hero.cb-hero-light h4,
.cb-widget .cb-hero.cb-hero-light .h4,
.cb-widget .cb-hero.cb-hero-light p {
  color: #505050;
}
.cb-widget .cb-hero.cb-hero-light .content h1:first-child,
.cb-widget .cb-hero.cb-hero-light .content .h1:first-child,
.cb-widget .cb-hero.cb-hero-light .content h2:first-child,
.cb-widget .cb-hero.cb-hero-light .content .h2:first-child,
.cb-widget .cb-hero.cb-hero-light .content h3:first-child,
.cb-widget .cb-hero.cb-hero-light .content .h3:first-child,
.cb-widget .cb-hero.cb-hero-light .content h4:first-child,
.cb-widget .cb-hero.cb-hero-light .content .h4:first-child {
  border-top-color: #505050;
}
.cb-widget .cb-hero.cb-hero-light .cb-hero-cta li:nth-child(2) .btn:not(.cb-primary-cta) {
  color: #1e1e1e;
  border: 1px solid #1e1e1e;
}
.cb-widget .cb-hero.cb-hero-left,
.cb-widget .cb-hero.cb-hero-right {
  text-align: left;
}
.cb-widget .cb-hero.cb-hero-center {
  text-align: center;
}
.cb-widget .cb-hero.cb-hero-standard {
  padding-top: 0;
}
.cb-widget .cb-hero.cb-hero-standard.cb-hero-border-bottom {
  border-bottom: 1px solid #ffffff;
}
@media (max-width: 767px) {
  .cb-widget .cb-hero.cb-hero-standard .cb-focus-left,
  .cb-widget .cb-hero.cb-hero-standard .cb-focus-right {
    text-align: center!important;
  }
  .cb-widget .cb-hero.cb-hero-standard.cb-hero-border-bottom {
    border-bottom: 0 !important;
  }
}
.cb-widget .cb-hero.cb-hero-standard .cb-hero-date,
.cb-widget .cb-hero.cb-hero-standard .cb-hero-preamble {
  font-size: 1.1875rem;
  line-height: 1.6666;
  letter-spacing: 0.05em;
  margin: 0 0 12px;
}
.cb-widget .cb-hero.cb-hero-no-bar .content h1:first-child,
.cb-widget .cb-hero.cb-hero-no-bar .content .h1:first-child,
.cb-widget .cb-hero.cb-hero-no-bar .content h2:first-child,
.cb-widget .cb-hero.cb-hero-no-bar .content .h2:first-child,
.cb-widget .cb-hero.cb-hero-no-bar .content h3:first-child,
.cb-widget .cb-hero.cb-hero-no-bar .content .h3:first-child,
.cb-widget .cb-hero.cb-hero-no-bar .content h4:first-child,
.cb-widget .cb-hero.cb-hero-no-bar .content .h4:first-child {
  padding-top: 0 !important;
  border: 0 !important;
}
.cb-widget .cb-hero.cb-hero-fullpage {
  max-height: 800px;
}
.cb-widget .cb-hero.cb-hero-fullpage.cb-hero-right .cb-hero-cta li:last-child {
  margin-right: 12px;
}
.cb-widget .cb-hero.cb-hero-fullpage.cb-hero-right .cb-hero-cta li:first-child {
  margin-right: 0;
  float: right;
}
.cb-widget .cb-hero.cb-hero-site-cart.cb-hero-border-bottom {
  border-bottom: 1px solid #ffffff;
}
.cb-widget .cb-hero.cb-hero-site-cart .content h1,
.cb-widget .cb-hero.cb-hero-site-cart .content .h1,
.cb-widget .cb-hero.cb-hero-site-cart .content h2,
.cb-widget .cb-hero.cb-hero-site-cart .content .h2,
.cb-widget .cb-hero.cb-hero-site-cart .content h3,
.cb-widget .cb-hero.cb-hero-site-cart .content .h3,
.cb-widget .cb-hero.cb-hero-site-cart .content h4,
.cb-widget .cb-hero.cb-hero-site-cart .content .h4 {
  font-family: "Roboto Slab", sans-serif;
  font-size: 2.625rem;
  line-height: 1.14285714em;
  color: #ffffff;
  margin: 0 0 12px 0;
}
.cb-widget .cb-hero.cb-hero-site-cart .content h1:first-child,
.cb-widget .cb-hero.cb-hero-site-cart .content .h1:first-child,
.cb-widget .cb-hero.cb-hero-site-cart .content h2:first-child,
.cb-widget .cb-hero.cb-hero-site-cart .content .h2:first-child,
.cb-widget .cb-hero.cb-hero-site-cart .content h3:first-child,
.cb-widget .cb-hero.cb-hero-site-cart .content .h3:first-child,
.cb-widget .cb-hero.cb-hero-site-cart .content h4:first-child,
.cb-widget .cb-hero.cb-hero-site-cart .content .h4:first-child {
  border-top: 0;
  display: block;
  padding-top: 0;
}
.cb-widget .cb-hero.cb-hero-site-cart .cb-hero-cta {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.cb-widget .cb-hero.cb-hero-site-cart .cb-hero-cta li {
  display: inline-block;
  margin-bottom: 0;
  margin-left: 0!important;
}
.cb-widget .cb-hero.cb-hero-site-cart .cb-hero-cta li .btn {
  background-color: transparent;
  color: #ffffff;
  border: 1px solid #ffffff;
  margin: 0 24px 24px 0;
}
.cb-widget .cb-hero.cb-hero-site-cart .cb-hero-cta li .cb-primary-cta {
  background: #fedb00;
  border: 1px solid #fedb00;
  color: #1e1e1e;
}
.cb-widget .cb-hero.cb-hero-site-cart > .container > .row > .flex-block {
  height: 100%;
}
.cb-widget .cb-hero.cb-hero-site-cart > .container > .row > .flex-block .content {
  width: 100%;
  margin: 48px 0 24px 0;
}
.cb-widget .cb-hero.cb-hero-site-cart > .container > .row > .flex-block .content p {
  font-size: 1.1875rem;
  line-height: 1.26315789em;
  margin-bottom: 36px;
  font-family: "Roboto", sans-serif;
}
.cb-widget .cb-hero.cb-hero-site-cart.cb-hero-no-btn > .container > .row > .flex-block .content {
  margin-bottom: 48px;
}
.cb-widget .cb-hero.cb-hero-site-cart .cb-hero-preamble {
  margin-bottom: 0 !important;
}
@media (max-width: 1023px) {
  .cb-widget .cb-hero h1,
  .cb-widget .cb-hero .h1,
  .cb-widget .cb-hero h2,
  .cb-widget .cb-hero .h2,
  .cb-widget .cb-hero h3,
  .cb-widget .cb-hero .h3,
  .cb-widget .cb-hero h4,
  .cb-widget .cb-hero .h4 {
    font-size: 1.625rem;
    line-height: 1.1538em;
  }
  .cb-widget .cb-hero .btn {
    padding: 11px 18px;
  }
  .cb-widget .cb-hero.cb-hero-standard {
    height: 240px;
  }
  .cb-widget .cb-hero.cb-hero-standard.cb-hero-border-bottom {
    height: 241px;
  }
}
@media (max-width: 767px) {
  .cb-widget .cb-hero:not(.cb-hero-site-cart) {
    background-color: #ffffff !important;
  }
  .cb-widget .cb-hero .content h1:first-child,
  .cb-widget .cb-hero .content .h1:first-child,
  .cb-widget .cb-hero .content h2:first-child,
  .cb-widget .cb-hero .content .h2:first-child,
  .cb-widget .cb-hero .content h3:first-child,
  .cb-widget .cb-hero .content .h3:first-child,
  .cb-widget .cb-hero .content h4:first-child,
  .cb-widget .cb-hero .content .h4:first-child {
    border-top-color: #505050;
    display: block;
  }
  .cb-widget .cb-hero.cb-hero-standard.cb-hero-left,
  .cb-widget .cb-hero.cb-hero-standard.cb-hero-right {
    height: auto;
    padding-top: 240px !important;
    background-position: top center;
    background-size: auto 240px;
    -webkit-background-size: auto 240px;
    text-align: center;
  }
  .cb-widget .cb-hero.cb-hero-standard.cb-hero-left h1,
  .cb-widget .cb-hero.cb-hero-standard.cb-hero-right h1,
  .cb-widget .cb-hero.cb-hero-standard.cb-hero-left .h1,
  .cb-widget .cb-hero.cb-hero-standard.cb-hero-right .h1,
  .cb-widget .cb-hero.cb-hero-standard.cb-hero-left h2,
  .cb-widget .cb-hero.cb-hero-standard.cb-hero-right h2,
  .cb-widget .cb-hero.cb-hero-standard.cb-hero-left .h2,
  .cb-widget .cb-hero.cb-hero-standard.cb-hero-right .h2,
  .cb-widget .cb-hero.cb-hero-standard.cb-hero-left h3,
  .cb-widget .cb-hero.cb-hero-standard.cb-hero-right h3,
  .cb-widget .cb-hero.cb-hero-standard.cb-hero-left .h3,
  .cb-widget .cb-hero.cb-hero-standard.cb-hero-right .h3,
  .cb-widget .cb-hero.cb-hero-standard.cb-hero-left h4,
  .cb-widget .cb-hero.cb-hero-standard.cb-hero-right h4,
  .cb-widget .cb-hero.cb-hero-standard.cb-hero-left .h4,
  .cb-widget .cb-hero.cb-hero-standard.cb-hero-right .h4,
  .cb-widget .cb-hero.cb-hero-standard.cb-hero-left p,
  .cb-widget .cb-hero.cb-hero-standard.cb-hero-right p {
    color: #505050;
  }
  .cb-widget .cb-hero.cb-hero-standard.cb-hero-left .cb-hero-cta li:nth-child(2) .btn:not(.cb-primary-cta),
  .cb-widget .cb-hero.cb-hero-standard.cb-hero-right .cb-hero-cta li:nth-child(2) .btn:not(.cb-primary-cta) {
    background-color: transparent;
    color: #1e1e1e;
    border: 1px solid #1e1e1e;
  }
  .cb-widget .cb-hero.cb-hero-standard.cb-hero-center:not(.cb-hero-light) .content h1:first-child,
  .cb-widget .cb-hero.cb-hero-standard.cb-hero-center:not(.cb-hero-light) .content .h1:first-child,
  .cb-widget .cb-hero.cb-hero-standard.cb-hero-center:not(.cb-hero-light) .content h2:first-child,
  .cb-widget .cb-hero.cb-hero-standard.cb-hero-center:not(.cb-hero-light) .content .h2:first-child,
  .cb-widget .cb-hero.cb-hero-standard.cb-hero-center:not(.cb-hero-light) .content h3:first-child,
  .cb-widget .cb-hero.cb-hero-standard.cb-hero-center:not(.cb-hero-light) .content .h3:first-child,
  .cb-widget .cb-hero.cb-hero-standard.cb-hero-center:not(.cb-hero-light) .content h4:first-child,
  .cb-widget .cb-hero.cb-hero-standard.cb-hero-center:not(.cb-hero-light) .content .h4:first-child {
    border-top-color: #ffffff;
  }
  .cb-widget .cb-hero.cb-hero-standard.cb-hero-border-bottom {
    border-bottom: 1px solid #cccac8;
  }
  .cb-widget .cb-hero.cb-hero-fullpage.cb-hero-center,
  .cb-widget .cb-hero.cb-hero-fullpage.cb-hero-left,
  .cb-widget .cb-hero.cb-hero-fullpage.cb-hero-right {
    height: auto;
    padding-top: 240px !important;
    background-position: top center;
    background-size: auto 240px;
    -webkit-background-size: auto 240px;
  }
  .cb-widget .cb-hero.cb-hero-fullpage.cb-hero-center h1,
  .cb-widget .cb-hero.cb-hero-fullpage.cb-hero-left h1,
  .cb-widget .cb-hero.cb-hero-fullpage.cb-hero-right h1,
  .cb-widget .cb-hero.cb-hero-fullpage.cb-hero-center .h1,
  .cb-widget .cb-hero.cb-hero-fullpage.cb-hero-left .h1,
  .cb-widget .cb-hero.cb-hero-fullpage.cb-hero-right .h1,
  .cb-widget .cb-hero.cb-hero-fullpage.cb-hero-center h2,
  .cb-widget .cb-hero.cb-hero-fullpage.cb-hero-left h2,
  .cb-widget .cb-hero.cb-hero-fullpage.cb-hero-right h2,
  .cb-widget .cb-hero.cb-hero-fullpage.cb-hero-center .h2,
  .cb-widget .cb-hero.cb-hero-fullpage.cb-hero-left .h2,
  .cb-widget .cb-hero.cb-hero-fullpage.cb-hero-right .h2,
  .cb-widget .cb-hero.cb-hero-fullpage.cb-hero-center h3,
  .cb-widget .cb-hero.cb-hero-fullpage.cb-hero-left h3,
  .cb-widget .cb-hero.cb-hero-fullpage.cb-hero-right h3,
  .cb-widget .cb-hero.cb-hero-fullpage.cb-hero-center .h3,
  .cb-widget .cb-hero.cb-hero-fullpage.cb-hero-left .h3,
  .cb-widget .cb-hero.cb-hero-fullpage.cb-hero-right .h3,
  .cb-widget .cb-hero.cb-hero-fullpage.cb-hero-center h4,
  .cb-widget .cb-hero.cb-hero-fullpage.cb-hero-left h4,
  .cb-widget .cb-hero.cb-hero-fullpage.cb-hero-right h4,
  .cb-widget .cb-hero.cb-hero-fullpage.cb-hero-center .h4,
  .cb-widget .cb-hero.cb-hero-fullpage.cb-hero-left .h4,
  .cb-widget .cb-hero.cb-hero-fullpage.cb-hero-right .h4,
  .cb-widget .cb-hero.cb-hero-fullpage.cb-hero-center p,
  .cb-widget .cb-hero.cb-hero-fullpage.cb-hero-left p,
  .cb-widget .cb-hero.cb-hero-fullpage.cb-hero-right p {
    color: #505050;
  }
  .cb-widget .cb-hero.cb-hero-fullpage.cb-hero-center .cb-hero-cta li:nth-child(2) .btn,
  .cb-widget .cb-hero.cb-hero-fullpage.cb-hero-left .cb-hero-cta li:nth-child(2) .btn,
  .cb-widget .cb-hero.cb-hero-fullpage.cb-hero-right .cb-hero-cta li:nth-child(2) .btn {
    background-color: transparent;
    color: #1e1e1e;
    border: 1px solid #1e1e1e;
  }
  .cb-widget .cb-hero.cb-hero-fullpage.cb-hero-right .cb-hero-cta li:last-child {
    margin-right: 0;
  }
  .cb-widget .cb-hero.cb-hero-fullpage.cb-hero-right .cb-hero-cta li:first-child {
    margin-right: 12px;
    float: none;
  }
  .cb-widget .cb-hero.cb-hero-fullpage .flex-block {
    margin-top: 24px;
  }
  .cb-widget .cb-hero.cb-hero-site-cart .content h1,
  .cb-widget .cb-hero.cb-hero-site-cart .content .h1,
  .cb-widget .cb-hero.cb-hero-site-cart .content h2,
  .cb-widget .cb-hero.cb-hero-site-cart .content .h2,
  .cb-widget .cb-hero.cb-hero-site-cart .content h3,
  .cb-widget .cb-hero.cb-hero-site-cart .content .h3,
  .cb-widget .cb-hero.cb-hero-site-cart .content h4,
  .cb-widget .cb-hero.cb-hero-site-cart .content .h4 {
    font-size: 2.25rem;
    line-height: 1.33333333em;
  }
}
@media (min-width: 1024px) {
  .cb-widget .cb-hero.cb-hero-standard {
    height: 312px;
  }
  .cb-widget .cb-hero.cb-hero-standard.cb-hero-border-bottom {
    height: 313px;
  }
  .cb-widget .cb-hero.cb-hero-fullpage {
    min-height: 600px;
  }
  .cb-widget .cb-hero.cb-hero-fullpage.cb-hero-left,
  .cb-widget .cb-hero.cb-hero-fullpage.cb-hero-right {
    background-size: 2056px auto;
  }
  .cb-widget .cb-hero.cb-hero-site-cart .flex-block {
    min-height: 384px;
  }
  .cb-widget .cb-hero.cb-hero-site-cart.cb-hero-border-bottom .flex-block {
    min-height: 383px;
  }
}
@media (min-width: 1250px) {
  .cb-widget .cb-hero h1,
  .cb-widget .cb-hero .h1,
  .cb-widget .cb-hero h2,
  .cb-widget .cb-hero .h2,
  .cb-widget .cb-hero h3,
  .cb-widget .cb-hero .h3,
  .cb-widget .cb-hero h4,
  .cb-widget .cb-hero .h4 {
    font-size: 3rem;
    line-height: 1.125em;
  }
  .cb-widget .cb-hero.cb-hero-standard {
    height: 384px;
  }
  .cb-widget .cb-hero.cb-hero-standard.cb-hero-border-bottom {
    height: 385px;
  }
  .cb-widget .cb-hero.cb-hero-fullpage.cb-hero-left,
  .cb-widget .cb-hero.cb-hero-fullpage.cb-hero-right {
    background-size: auto auto;
  }
  .cb-widget .cb-hero.cb-hero-site-cart .flex-block {
    min-height: 384px;
  }
  .cb-widget .cb-hero.cb-hero-site-cart.cb-hero-border-bottom .flex-block {
    min-height: 383px;
  }
}
@media (max-width: 1023px) and (min-width: 768px) {
  .cb-widget .cb-hero.cb-hero-fullpage.cb-hero-left,
  .cb-widget .cb-hero.cb-hero-fullpage.cb-hero-right {
    background-size: 1592px auto;
  }
}
@media (max-width: 1023px) {
  .cb-widget .cb-hero > .container > .row > .flex-block .content p {
    margin-bottom: 12px;
  }
  .cb-widget .cb-hero .content h1,
  .cb-widget .cb-hero .content .h1,
  .cb-widget .cb-hero .content h2,
  .cb-widget .cb-hero .content .h2,
  .cb-widget .cb-hero .content h3,
  .cb-widget .cb-hero .content .h3,
  .cb-widget .cb-hero .content h4,
  .cb-widget .cb-hero .content .h4 {
    font-size: 1.5rem;
    line-height: 1.1666;
    margin: 0 0 12px;
  }
  .cb-widget .cb-hero.cb-hero-standard .cb-hero-date,
  .cb-widget .cb-hero.cb-hero-standard .cb-hero-preamble {
    font-size: 1rem;
    line-height: 1.5em;
    margin: 0 0 12px;
  }
  .cb-widget .cb-hero .cb-hero-cta li {
    margin-bottom: 0;
  }
}
@media (min-width: 1024px) {
  .cb-widget .ie11 .cb-hero-site-cart .flex-block {
    min-height: 384px !important;
  }
  .cb-widget .ie11 .cb-hero-site-cart.cb-hero-border-bottom .flex-block {
    min-height: 383px !important;
  }
}
@media (min-width: 1250px) {
  .cb-widget .ie11 .cb-hero-site-cart .flex-block {
    height: 384px !important;
  }
  .cb-widget .ie11 .cb-hero-site-cart.cb-hero-border-bottom .flex-block {
    height: 383px !important;
  }
}
.cb-widget .cb-login-module {
  color: #505050;
}
.cb-widget .cb-login-module:not(.cb-no-border) {
  padding: 24px;
  border: 1px solid #d9d9d9;
  background: #ffffff;
}
.cb-widget .cb-login-module .cb-login-module-header {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
.cb-widget .cb-login-module .cb-login-module-header h2,
.cb-widget .cb-login-module .cb-login-module-header h3,
.cb-widget .cb-login-module .cb-login-module-header h4 {
  font-family: "Roboto Slab", sans-serif !important;
  font-weight: 300 !important;
  font-size: 1.438rem;
  line-height: 1.04347826em;
  margin: 0 0 0 8px;
  flex: 1;
}
.cb-widget .cb-login-module .cb-login-module-header .cb-glyph-multi {
  min-width: 64px;
}
.cb-widget .cb-login-module .cb-login-module-header .cb-glyph.cb-glyph-circular::after {
  background-color: #0077c8;
}
.cb-widget .cb-login-module .cb-login-module-body {
  margin-bottom: 24px;
}
.cb-widget .cb-login-module .cb-login-module-body .cb-item-list {
  margin-bottom: 0;
}
.cb-widget .cb-login-module .cb-login-module-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cb-widget .cb-login-module .cb-login-module-footer p {
  margin-bottom: 0!important;
}
.cb-widget .cb-login-module .cb-login-module-footer > a {
  display: flex;
  align-items: center;
}
.cb-widget .cb-login-module .cb-login-module-footer > a:hover,
.cb-widget .cb-login-module .cb-login-module-footer > a:focus {
  text-decoration: none!important;
}
.cb-widget .cb-login-module .cb-login-module-footer > a:hover span:not(.cb-glyph),
.cb-widget .cb-login-module .cb-login-module-footer > a:focus span:not(.cb-glyph) {
  text-decoration: underline;
}
.cb-widget .cb-login-module .cb-login-module-footer > a:first-of-type span:not(.cb-glyph) {
  margin-right: 24px;
}
.cb-widget .cb-login-module .cb-login-module-footer > a:first-of-type span.cb-glyph {
  margin-right: 8px;
}
.cb-widget .cb-login-module.cb-logged-in .cb-login-module-header h2,
.cb-widget .cb-login-module.cb-logged-in .cb-login-module-header h3 {
  flex: 1;
}
@media (max-width: 767px) {
  .cb-widget .cb-login-module.cb-logged-in .cb-login-module-header .cb-glyph-multi,
  .cb-widget .cb-login-module.cb-logged-in .cb-login-module-header .cb-glyph {
    display: none;
    visibility: hidden;
  }
}
.cb-widget .cb-login-module.cb-logged-in .cb-login-module-footer a {
  display: flex;
  align-items: center;
}
.cb-widget .cb-login-module.cb-logged-in .cb-login-module-footer a:hover,
.cb-widget .cb-login-module.cb-logged-in .cb-login-module-footer a:focus {
  text-decoration: none!important;
}
.cb-widget .cb-login-module.cb-logged-in .cb-login-module-footer a:hover span:not(.cb-glyph),
.cb-widget .cb-login-module.cb-logged-in .cb-login-module-footer a:focus span:not(.cb-glyph) {
  text-decoration: underline;
}
.cb-widget .cb-login-module.cb-logged-in .cb-login-module-footer a:first-of-type span:not(.cb-glyph) {
  margin-right: 24px;
  margin-left: 8px;
}
.cb-widget .cb-login-module.cb-logged-in .cb-login-module-footer a:first-of-type span.cb-glyph {
  margin-right: 0;
}
@media (max-width: 767px) {
  .cb-widget .cb-login-module-footer p {
    text-align: center;
    width: 100%;
  }
  .cb-widget .cb-login-module-cta.cb-cta-card {
    margin-top: 24px;
  }
}
.cb-widget .col-xs-12 > .cb-login-module .cb-glyph-multi {
  background-color: #0077c8 !important;
}
.cb-widget .col-xs-12 > .cb-login-module .cb-glyph-multi::before {
  color: #ffffff !important;
}
.cb-widget .col-xs-12 > .cb-login-module .cb-glyph-multi::after {
  color: #fedb00 !important;
}
.cb-widget .cb-login-module-cta .cb-glyph-multi {
  background-color: #0077c8 !important;
}
.cb-widget .cb-login-module-cta .cb-glyph-multi::before {
  color: #ffffff !important;
}
.cb-widget .cb-login-module-cta .cb-glyph-multi::after {
  color: #fedb00 !important;
}
.cb-widget .input-group {
  position: relative;
  display: table;
  border-collapse: separate;
}
.cb-widget .input-group[class*="col-"] {
  float: none;
  padding-left: 0;
  padding-right: 0;
}
.cb-widget .input-group .form-control {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0;
}
.cb-widget .input-group .form-control:focus {
  z-index: 3;
}
.cb-widget .input-group-lg > .form-control,
.cb-widget .input-group-lg > .input-group-addon,
.cb-widget .input-group-lg > .input-group-btn > .btn {
  height: 24rem;
  padding: 10px 16px;
  font-size: 1.125rem;
  line-height: 1.3333333;
  border-radius: 6px;
}
.cb-widget select.input-group-lg > .form-control,
.cb-widget select.input-group-lg > .input-group-addon,
.cb-widget select.input-group-lg > .input-group-btn > .btn {
  height: 24rem;
  line-height: 24rem;
}
.cb-widget textarea.input-group-lg > .form-control,
.cb-widget textarea.input-group-lg > .input-group-addon,
.cb-widget textarea.input-group-lg > .input-group-btn > .btn,
.cb-widget select[multiple].input-group-lg > .form-control,
.cb-widget select[multiple].input-group-lg > .input-group-addon,
.cb-widget select[multiple].input-group-lg > .input-group-btn > .btn {
  height: auto;
}
.cb-widget select.input-group-lg > .form-control,
.cb-widget select.input-group-lg > .input-group-addon,
.cb-widget select.input-group-lg > .input-group-btn > .btn {
  height: 24rem;
  line-height: 24rem;
}
.cb-widget textarea.input-group-lg > .form-control,
.cb-widget textarea.input-group-lg > .input-group-addon,
.cb-widget textarea.input-group-lg > .input-group-btn > .btn,
.cb-widget select[multiple].input-group-lg > .form-control,
.cb-widget select[multiple].input-group-lg > .input-group-addon,
.cb-widget select[multiple].input-group-lg > .input-group-btn > .btn {
  height: auto;
}
.cb-widget .input-group-sm > .form-control,
.cb-widget .input-group-sm > .input-group-addon,
.cb-widget .input-group-sm > .input-group-btn > .btn {
  height: 13rem;
  padding: 5px 10px;
  font-size: 0.875rem;
  line-height: 1.286em;
  border-radius: 3px;
}
.cb-widget select.input-group-sm > .form-control,
.cb-widget select.input-group-sm > .input-group-addon,
.cb-widget select.input-group-sm > .input-group-btn > .btn {
  height: 13rem;
  line-height: 13rem;
}
.cb-widget textarea.input-group-sm > .form-control,
.cb-widget textarea.input-group-sm > .input-group-addon,
.cb-widget textarea.input-group-sm > .input-group-btn > .btn,
.cb-widget select[multiple].input-group-sm > .form-control,
.cb-widget select[multiple].input-group-sm > .input-group-addon,
.cb-widget select[multiple].input-group-sm > .input-group-btn > .btn {
  height: auto;
}
.cb-widget select.input-group-sm > .form-control,
.cb-widget select.input-group-sm > .input-group-addon,
.cb-widget select.input-group-sm > .input-group-btn > .btn {
  height: 13rem;
  line-height: 13rem;
}
.cb-widget textarea.input-group-sm > .form-control,
.cb-widget textarea.input-group-sm > .input-group-addon,
.cb-widget textarea.input-group-sm > .input-group-btn > .btn,
.cb-widget select[multiple].input-group-sm > .form-control,
.cb-widget select[multiple].input-group-sm > .input-group-addon,
.cb-widget select[multiple].input-group-sm > .input-group-btn > .btn {
  height: auto;
}
.cb-widget .input-group-addon,
.cb-widget .input-group-btn,
.cb-widget .input-group .form-control {
  display: table-cell;
}
.cb-widget .input-group-addon:not(:first-child):not(:last-child),
.cb-widget .input-group-btn:not(:first-child):not(:last-child),
.cb-widget .input-group .form-control:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.cb-widget .input-group-addon,
.cb-widget .input-group-btn {
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
}
.cb-widget .input-group-addon {
  padding: 6px 12px;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1;
  color: #505050;
  text-align: center;
  background-color: #eeeeee;
  border: 1px solid #ccc;
  border-radius: 0;
}
.cb-widget .input-group-addon.input-sm {
  padding: 5px 10px;
  font-size: 0.875rem;
  border-radius: 3px;
}
.cb-widget .input-group-addon.input-lg {
  padding: 10px 16px;
  font-size: 1.125rem;
  border-radius: 6px;
}
.cb-widget .input-group-addon input[type="radio"],
.cb-widget .input-group-addon input[type="checkbox"] {
  margin-top: 0;
}
.cb-widget .input-group .form-control:first-child,
.cb-widget .input-group-addon:first-child,
.cb-widget .input-group-btn:first-child > .btn,
.cb-widget .input-group-btn:first-child > .btn-group > .btn,
.cb-widget .input-group-btn:first-child > .dropdown-toggle,
.cb-widget .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.cb-widget .input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.cb-widget .input-group-addon:first-child {
  border-right: 0;
}
.cb-widget .input-group .form-control:last-child,
.cb-widget .input-group-addon:last-child,
.cb-widget .input-group-btn:last-child > .btn,
.cb-widget .input-group-btn:last-child > .btn-group > .btn,
.cb-widget .input-group-btn:last-child > .dropdown-toggle,
.cb-widget .input-group-btn:first-child > .btn:not(:first-child),
.cb-widget .input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.cb-widget .input-group-addon:last-child {
  border-left: 0;
}
.cb-widget .input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap;
}
.cb-widget .input-group-btn > .btn {
  position: relative;
}
.cb-widget .input-group-btn > .btn + .btn {
  margin-left: -1px;
}
.cb-widget .input-group-btn > .btn:hover,
.cb-widget .input-group-btn > .btn:focus,
.cb-widget .input-group-btn > .btn:active {
  z-index: 2;
}
.cb-widget .input-group-btn:first-child > .btn,
.cb-widget .input-group-btn:first-child > .btn-group {
  margin-right: -1px;
}
.cb-widget .input-group-btn:last-child > .btn,
.cb-widget .input-group-btn:last-child > .btn-group {
  z-index: 2;
  margin-left: -1px;
}
.cb-widget .input-group .btn-primary,
.cb-widget .input-group .btn-secondary {
  line-height: inherit;
  height: 48px;
}
.cb-widget .input-group .btn-default {
  line-height: inherit;
  height: 48px;
}
.cb-widget .input-group .input-group-addon {
  border-color: #d9d9d9;
  background-color: #f0f0f0;
  width: 48px;
}
.cb-widget .input-group .input-group-addon.primary {
  background-color: #fedb00;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fedb00), to(#fedb00));
  background-image: -webkit-linear-gradient(top, #fedb00 0%, #fedb00 100%);
  background-image: -moz-linear-gradient(top, #fedb00 0%, #fedb00 100%);
  background-image: -ms-linear-gradient(top, #fedb00 0%, #fedb00 100%);
  color: #1e1e1e;
  border: 1px solid transparent;
}
.cb-widget .input-group .input-group-addon.secondary {
  color: #1e1e1e;
  background-color: transparent;
  border: 1px solid #1e1e1e !important;
}
.cb-widget .input-group .input-group-addon.radio {
  padding: 0 6px;
}
.cb-widget .input-group .input-group-addon.radio label {
  padding-left: 16px;
}
.cb-widget .input-group .input-group-addon.radio input[type="radio"] + span::before {
  background: #faf9f7;
}
.cb-widget .input-group .input-group-addon.checkbox {
  padding: 0 6px;
}
.cb-widget .input-group .input-group-addon.checkbox label {
  padding-left: 16px;
}
.cb-widget .input-group .input-group-addon.checkbox input[type="checkbox"] + span::before {
  background: #faf9f7;
}
.cb-widget .input-group .input-group-addon.checkbox input[type="checkbox"] + span::after {
  top: -19px;
}
.cb-widget .input-group .input-group-btn .btn {
  margin-top: 0 !important;
}
.cb-widget .input-group-btn > .btn.btn-secondary + .btn-primary {
  margin-left: 0 !important;
}
.cb-widget .pagination {
  display: inline-block;
  padding-left: 0;
  margin: 1.5em 0;
  border-radius: 4px;
}
.cb-widget .pagination > li {
  display: inline;
}
.cb-widget .pagination > li > a,
.cb-widget .pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.5em;
  text-decoration: none;
  color: #0077c8;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px;
}
.cb-widget .pagination > li:first-child > a,
.cb-widget .pagination > li:first-child > span {
  margin-left: 0;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.cb-widget .pagination > li:last-child > a,
.cb-widget .pagination > li:last-child > span {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}
.cb-widget .pagination > li > a:hover,
.cb-widget .pagination > li > span:hover,
.cb-widget .pagination > li > a:focus,
.cb-widget .pagination > li > span:focus {
  z-index: 2;
  color: #0077c8;
  background-color: #eeeeee;
  border-color: #ddd;
}
.cb-widget .pagination > .active > a,
.cb-widget .pagination > .active > span,
.cb-widget .pagination > .active > a:hover,
.cb-widget .pagination > .active > span:hover,
.cb-widget .pagination > .active > a:focus,
.cb-widget .pagination > .active > span:focus {
  z-index: 3;
  color: #fff;
  background-color: #0077c8;
  border-color: #337ab7;
  cursor: default;
}
.cb-widget .pagination > .disabled > span,
.cb-widget .pagination > .disabled > span:hover,
.cb-widget .pagination > .disabled > span:focus,
.cb-widget .pagination > .disabled > a,
.cb-widget .pagination > .disabled > a:hover,
.cb-widget .pagination > .disabled > a:focus {
  color: #777777;
  background-color: #fff;
  border-color: #ddd;
  cursor: not-allowed;
}
.cb-widget .pagination-lg > li > a,
.cb-widget .pagination-lg > li > span {
  padding: 10px 16px;
  font-size: 1.125rem;
  line-height: 1.3333333;
}
.cb-widget .pagination-lg > li:first-child > a,
.cb-widget .pagination-lg > li:first-child > span {
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
}
.cb-widget .pagination-lg > li:last-child > a,
.cb-widget .pagination-lg > li:last-child > span {
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
}
.cb-widget .pagination-sm > li > a,
.cb-widget .pagination-sm > li > span {
  padding: 5px 10px;
  font-size: 0.875rem;
  line-height: 1.286em;
}
.cb-widget .pagination-sm > li:first-child > a,
.cb-widget .pagination-sm > li:first-child > span {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}
.cb-widget .pagination-sm > li:last-child > a,
.cb-widget .pagination-sm > li:last-child > span {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.cb-widget .pagination {
  margin: 0 0 1.5em 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.cb-widget .pagination li > a,
.cb-widget .pagination li > span {
  padding: 4px 12px;
  line-height: 1.375em;
}
.cb-widget .pagination li a[aria-label="Previous"],
.cb-widget .pagination li a[aria-label="Next"],
.cb-widget .pagination li a[aria-label="previous"],
.cb-widget .pagination li a[aria-label="next"] {
  padding-top: 6px;
  padding-bottom: 2px;
  border-radius: 0;
}
.cb-widget .cb-login-hero {
  position: relative;
  background-color: transparent!important;
  background-position: top;
  color: #505050;
  padding: 48px 0;
}
@media (max-width: 1023px) {
  .cb-widget .cb-login-hero {
    padding: 24px 0;
  }
}
.cb-widget .cb-login-hero .cb-login-img-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #b2b2b2;
  margin-bottom: 0;
}
@media (min-width: 1024px) {
  .cb-widget .cb-login-hero .cb-login-img-container {
    height: 336px;
  }
}
@media (min-width: 1250px) {
  .cb-widget .cb-login-hero .cb-login-img-container {
    height: 408px;
  }
}
@media (max-width: 1023px) and (min-width: 768px) {
  .cb-widget .cb-login-hero .cb-login-img-container {
    height: 288px;
  }
}
@media (max-width: 767px) {
  .cb-widget .cb-login-hero .cb-login-img-container {
    height: 384px;
  }
  .cb-widget .cb-login-hero .cb-login-img-container .container {
    padding: 0;
  }
}
.cb-widget .cb-login-hero .cb-login-img-container .container,
.cb-widget .cb-login-hero .cb-login-img-container .row {
  height: inherit;
}
.cb-widget .cb-login-hero .cb-login-img-container .cb-login-img {
  height: inherit;
  background-position: center center;
  background-size: auto 100%;
  background-repeat: no-repeat;
}
@media (min-width: 1024px) {
  .cb-widget .cb-login-hero .cb-block-content .cb-hero-content {
    margin-top: 336px;
  }
}
@media (min-width: 1250px) {
  .cb-widget .cb-login-hero .cb-block-content .cb-hero-content {
    margin-top: 408px;
  }
}
@media (max-width: 1023px) and (min-width: 768px) {
  .cb-widget .cb-login-hero .cb-block-content .cb-hero-content {
    margin-top: 288px;
  }
}
@media (max-width: 767px) {
  .cb-widget .cb-login-hero .cb-block-content .cb-hero-content {
    margin-top: 384px;
  }
}
.cb-widget .cb-login-hero .cb-block-content .cb-hero-content h1,
.cb-widget .cb-login-hero .cb-block-content .cb-hero-content .h1,
.cb-widget .cb-login-hero .cb-block-content .cb-hero-content h2,
.cb-widget .cb-login-hero .cb-block-content .cb-hero-content .h2,
.cb-widget .cb-login-hero .cb-block-content .cb-hero-content h3,
.cb-widget .cb-login-hero .cb-block-content .cb-hero-content .h3,
.cb-widget .cb-login-hero .cb-block-content .cb-hero-content h4,
.cb-widget .cb-login-hero .cb-block-content .cb-hero-content .h4 {
  font-size: 2.625rem;
  line-height: 1.14285714em;
  font-family: "Roboto Slab", sans-serif;
  font-weight: 200;
  color: #505050;
  display: inline-block;
  border-top-style: solid;
  border-top-width: 0.2em;
  border-top-color: #505050;
  padding-top: 0.2em;
}
.cb-widget .cb-login-hero .cb-block-content .cb-hero-content p {
  margin-bottom: 0!important;
}
@media (max-width: 767px) {
  .cb-widget .cb-login-hero .cb-login-module {
    margin-top: 24px;
  }
}
.cb-widget .cb-login-hero .cb-login-module .cb-login-module-footer p {
  text-align: center;
  width: 100%;
}
.cb-widget .cb-login-hero .cb-cta-card {
  margin-top: 24px;
}
.cb-widget [data-cb-program="corporate"] .cb-login-img-container,
.cb-widget .cb-corporate-program .cb-login-img-container,
.cb-widget .cb-blue-corporate-color .cb-login-img-container {
  background-color: #006298;
}
.cb-widget [data-cb-program="corporate"] .cb-block-content .cb-hero-content h1,
.cb-widget .cb-corporate-program .cb-block-content .cb-hero-content h1,
.cb-widget .cb-blue-corporate-color .cb-block-content .cb-hero-content h1,
.cb-widget [data-cb-program="corporate"] .cb-block-content .cb-hero-content .h1,
.cb-widget .cb-corporate-program .cb-block-content .cb-hero-content .h1,
.cb-widget .cb-blue-corporate-color .cb-block-content .cb-hero-content .h1,
.cb-widget [data-cb-program="corporate"] .cb-block-content .cb-hero-content h2,
.cb-widget .cb-corporate-program .cb-block-content .cb-hero-content h2,
.cb-widget .cb-blue-corporate-color .cb-block-content .cb-hero-content h2,
.cb-widget [data-cb-program="corporate"] .cb-block-content .cb-hero-content .h2,
.cb-widget .cb-corporate-program .cb-block-content .cb-hero-content .h2,
.cb-widget .cb-blue-corporate-color .cb-block-content .cb-hero-content .h2,
.cb-widget [data-cb-program="corporate"] .cb-block-content .cb-hero-content h3,
.cb-widget .cb-corporate-program .cb-block-content .cb-hero-content h3,
.cb-widget .cb-blue-corporate-color .cb-block-content .cb-hero-content h3,
.cb-widget [data-cb-program="corporate"] .cb-block-content .cb-hero-content .h3,
.cb-widget .cb-corporate-program .cb-block-content .cb-hero-content .h3,
.cb-widget .cb-blue-corporate-color .cb-block-content .cb-hero-content .h3,
.cb-widget [data-cb-program="corporate"] .cb-block-content .cb-hero-content h4,
.cb-widget .cb-corporate-program .cb-block-content .cb-hero-content h4,
.cb-widget .cb-blue-corporate-color .cb-block-content .cb-hero-content h4,
.cb-widget [data-cb-program="corporate"] .cb-block-content .cb-hero-content .h4,
.cb-widget .cb-corporate-program .cb-block-content .cb-hero-content .h4,
.cb-widget .cb-blue-corporate-color .cb-block-content .cb-hero-content .h4 {
  border-top-color: #006298;
}
.cb-widget [data-cb-program="corporate"] .cb-login-module .cb-login-module-header .cb-glyph-multi,
.cb-widget .cb-corporate-program .cb-login-module .cb-login-module-header .cb-glyph-multi,
.cb-widget .cb-blue-corporate-color .cb-login-module .cb-login-module-header .cb-glyph-multi {
  min-width: 64px;
  background-color: #0077c8;
}
.cb-widget [data-cb-program="corporate"] .cb-login-module .cb-login-module-header .cb-glyph-multi::before,
.cb-widget .cb-corporate-program .cb-login-module .cb-login-module-header .cb-glyph-multi::before,
.cb-widget .cb-blue-corporate-color .cb-login-module .cb-login-module-header .cb-glyph-multi::before {
  color: #ffffff;
}
.cb-widget [data-cb-program="corporate"] .cb-login-module .cb-login-module-header .cb-glyph-multi::after,
.cb-widget .cb-corporate-program .cb-login-module .cb-login-module-header .cb-glyph-multi::after,
.cb-widget .cb-blue-corporate-color .cb-login-module .cb-login-module-header .cb-glyph-multi::after {
  color: #fedb00;
}
.cb-widget [data-cb-program="k-12"] .cb-login-img-container,
.cb-widget .cb-k-12-program .cb-login-img-container,
.cb-widget .cb-k-12-color .cb-login-img-container {
  background-color: #009cde;
}
.cb-widget [data-cb-program="k-12"] .cb-block-content .cb-hero-content h1,
.cb-widget .cb-k-12-program .cb-block-content .cb-hero-content h1,
.cb-widget .cb-k-12-color .cb-block-content .cb-hero-content h1,
.cb-widget [data-cb-program="k-12"] .cb-block-content .cb-hero-content .h1,
.cb-widget .cb-k-12-program .cb-block-content .cb-hero-content .h1,
.cb-widget .cb-k-12-color .cb-block-content .cb-hero-content .h1,
.cb-widget [data-cb-program="k-12"] .cb-block-content .cb-hero-content h2,
.cb-widget .cb-k-12-program .cb-block-content .cb-hero-content h2,
.cb-widget .cb-k-12-color .cb-block-content .cb-hero-content h2,
.cb-widget [data-cb-program="k-12"] .cb-block-content .cb-hero-content .h2,
.cb-widget .cb-k-12-program .cb-block-content .cb-hero-content .h2,
.cb-widget .cb-k-12-color .cb-block-content .cb-hero-content .h2,
.cb-widget [data-cb-program="k-12"] .cb-block-content .cb-hero-content h3,
.cb-widget .cb-k-12-program .cb-block-content .cb-hero-content h3,
.cb-widget .cb-k-12-color .cb-block-content .cb-hero-content h3,
.cb-widget [data-cb-program="k-12"] .cb-block-content .cb-hero-content .h3,
.cb-widget .cb-k-12-program .cb-block-content .cb-hero-content .h3,
.cb-widget .cb-k-12-color .cb-block-content .cb-hero-content .h3,
.cb-widget [data-cb-program="k-12"] .cb-block-content .cb-hero-content h4,
.cb-widget .cb-k-12-program .cb-block-content .cb-hero-content h4,
.cb-widget .cb-k-12-color .cb-block-content .cb-hero-content h4,
.cb-widget [data-cb-program="k-12"] .cb-block-content .cb-hero-content .h4,
.cb-widget .cb-k-12-program .cb-block-content .cb-hero-content .h4,
.cb-widget .cb-k-12-color .cb-block-content .cb-hero-content .h4 {
  border-top-color: #009cde;
}
.cb-widget [data-cb-program="k-12"] .cb-login-module .cb-login-module-header .cb-glyph-multi,
.cb-widget .cb-k-12-program .cb-login-module .cb-login-module-header .cb-glyph-multi,
.cb-widget .cb-k-12-color .cb-login-module .cb-login-module-header .cb-glyph-multi {
  min-width: 64px;
  background-color: #009cde;
}
.cb-widget [data-cb-program="k-12"] .cb-login-module .cb-login-module-header .cb-glyph-multi::before,
.cb-widget .cb-k-12-program .cb-login-module .cb-login-module-header .cb-glyph-multi::before,
.cb-widget .cb-k-12-color .cb-login-module .cb-login-module-header .cb-glyph-multi::before {
  color: #ffffff;
}
.cb-widget [data-cb-program="k-12"] .cb-login-module .cb-login-module-header .cb-glyph-multi::after,
.cb-widget .cb-k-12-program .cb-login-module .cb-login-module-header .cb-glyph-multi::after,
.cb-widget .cb-k-12-color .cb-login-module .cb-login-module-header .cb-glyph-multi::after {
  color: #000000;
}
.cb-widget [data-cb-program="access"] .cb-login-img-container,
.cb-widget .cb-access-program .cb-login-img-container,
.cb-widget .cb-access-color .cb-login-img-container {
  background-color: #3a913f;
}
.cb-widget [data-cb-program="access"] .cb-block-content .cb-hero-content h1,
.cb-widget .cb-access-program .cb-block-content .cb-hero-content h1,
.cb-widget .cb-access-color .cb-block-content .cb-hero-content h1,
.cb-widget [data-cb-program="access"] .cb-block-content .cb-hero-content .h1,
.cb-widget .cb-access-program .cb-block-content .cb-hero-content .h1,
.cb-widget .cb-access-color .cb-block-content .cb-hero-content .h1,
.cb-widget [data-cb-program="access"] .cb-block-content .cb-hero-content h2,
.cb-widget .cb-access-program .cb-block-content .cb-hero-content h2,
.cb-widget .cb-access-color .cb-block-content .cb-hero-content h2,
.cb-widget [data-cb-program="access"] .cb-block-content .cb-hero-content .h2,
.cb-widget .cb-access-program .cb-block-content .cb-hero-content .h2,
.cb-widget .cb-access-color .cb-block-content .cb-hero-content .h2,
.cb-widget [data-cb-program="access"] .cb-block-content .cb-hero-content h3,
.cb-widget .cb-access-program .cb-block-content .cb-hero-content h3,
.cb-widget .cb-access-color .cb-block-content .cb-hero-content h3,
.cb-widget [data-cb-program="access"] .cb-block-content .cb-hero-content .h3,
.cb-widget .cb-access-program .cb-block-content .cb-hero-content .h3,
.cb-widget .cb-access-color .cb-block-content .cb-hero-content .h3,
.cb-widget [data-cb-program="access"] .cb-block-content .cb-hero-content h4,
.cb-widget .cb-access-program .cb-block-content .cb-hero-content h4,
.cb-widget .cb-access-color .cb-block-content .cb-hero-content h4,
.cb-widget [data-cb-program="access"] .cb-block-content .cb-hero-content .h4,
.cb-widget .cb-access-program .cb-block-content .cb-hero-content .h4,
.cb-widget .cb-access-color .cb-block-content .cb-hero-content .h4 {
  border-top-color: #3a913f;
}
.cb-widget [data-cb-program="access"] .cb-login-module .cb-login-module-header .cb-glyph-multi,
.cb-widget .cb-access-program .cb-login-module .cb-login-module-header .cb-glyph-multi,
.cb-widget .cb-access-color .cb-login-module .cb-login-module-header .cb-glyph-multi {
  min-width: 64px;
  background-color: #3a913f;
}
.cb-widget [data-cb-program="access"] .cb-login-module .cb-login-module-header .cb-glyph-multi::before,
.cb-widget .cb-access-program .cb-login-module .cb-login-module-header .cb-glyph-multi::before,
.cb-widget .cb-access-color .cb-login-module .cb-login-module-header .cb-glyph-multi::before {
  color: #ffffff;
}
.cb-widget [data-cb-program="access"] .cb-login-module .cb-login-module-header .cb-glyph-multi::after,
.cb-widget .cb-access-program .cb-login-module .cb-login-module-header .cb-glyph-multi::after,
.cb-widget .cb-access-color .cb-login-module .cb-login-module-header .cb-glyph-multi::after {
  color: #000000;
}
.cb-widget [data-cb-program="higher-education"] .cb-login-img-container,
.cb-widget .cb-higher-education-program .cb-login-img-container,
.cb-widget .cb-higher-education-color .cb-login-img-container {
  background-color: #702f8a;
}
.cb-widget [data-cb-program="higher-education"] .cb-block-content .cb-hero-content h1,
.cb-widget .cb-higher-education-program .cb-block-content .cb-hero-content h1,
.cb-widget .cb-higher-education-color .cb-block-content .cb-hero-content h1,
.cb-widget [data-cb-program="higher-education"] .cb-block-content .cb-hero-content .h1,
.cb-widget .cb-higher-education-program .cb-block-content .cb-hero-content .h1,
.cb-widget .cb-higher-education-color .cb-block-content .cb-hero-content .h1,
.cb-widget [data-cb-program="higher-education"] .cb-block-content .cb-hero-content h2,
.cb-widget .cb-higher-education-program .cb-block-content .cb-hero-content h2,
.cb-widget .cb-higher-education-color .cb-block-content .cb-hero-content h2,
.cb-widget [data-cb-program="higher-education"] .cb-block-content .cb-hero-content .h2,
.cb-widget .cb-higher-education-program .cb-block-content .cb-hero-content .h2,
.cb-widget .cb-higher-education-color .cb-block-content .cb-hero-content .h2,
.cb-widget [data-cb-program="higher-education"] .cb-block-content .cb-hero-content h3,
.cb-widget .cb-higher-education-program .cb-block-content .cb-hero-content h3,
.cb-widget .cb-higher-education-color .cb-block-content .cb-hero-content h3,
.cb-widget [data-cb-program="higher-education"] .cb-block-content .cb-hero-content .h3,
.cb-widget .cb-higher-education-program .cb-block-content .cb-hero-content .h3,
.cb-widget .cb-higher-education-color .cb-block-content .cb-hero-content .h3,
.cb-widget [data-cb-program="higher-education"] .cb-block-content .cb-hero-content h4,
.cb-widget .cb-higher-education-program .cb-block-content .cb-hero-content h4,
.cb-widget .cb-higher-education-color .cb-block-content .cb-hero-content h4,
.cb-widget [data-cb-program="higher-education"] .cb-block-content .cb-hero-content .h4,
.cb-widget .cb-higher-education-program .cb-block-content .cb-hero-content .h4,
.cb-widget .cb-higher-education-color .cb-block-content .cb-hero-content .h4 {
  border-top-color: #702f8a;
}
.cb-widget [data-cb-program="higher-education"] .cb-login-module .cb-login-module-header .cb-glyph-multi,
.cb-widget .cb-higher-education-program .cb-login-module .cb-login-module-header .cb-glyph-multi,
.cb-widget .cb-higher-education-color .cb-login-module .cb-login-module-header .cb-glyph-multi {
  min-width: 64px;
  background-color: #702f8a;
}
.cb-widget [data-cb-program="higher-education"] .cb-login-module .cb-login-module-header .cb-glyph-multi::before,
.cb-widget .cb-higher-education-program .cb-login-module .cb-login-module-header .cb-glyph-multi::before,
.cb-widget .cb-higher-education-color .cb-login-module .cb-login-module-header .cb-glyph-multi::before {
  color: #ffffff;
}
.cb-widget [data-cb-program="higher-education"] .cb-login-module .cb-login-module-header .cb-glyph-multi::after,
.cb-widget .cb-higher-education-program .cb-login-module .cb-login-module-header .cb-glyph-multi::after,
.cb-widget .cb-higher-education-color .cb-login-module .cb-login-module-header .cb-glyph-multi::after {
  color: #000000;
}
.cb-widget .cb-cta-card {
  background-color: #ffffff;
  padding: 24px;
  text-align: center;
  border: 1px solid #d9d9d9;
}
.cb-widget .cb-cta-card .cb-cta-card-icon {
  display: inline-block;
  width: 64px;
  height: 64px;
}
.cb-widget .cb-cta-card h3,
.cb-widget .cb-cta-card h4 {
  font-family: "Roboto Slab", sans-serif;
  font-weight: 300;
  font-size: 1.438rem;
  margin-bottom: 24px;
  word-wrap: break-word;
}
.cb-widget .cb-cta-card.cb-vertical-card a {
  color: #505050;
  display: inline-block;
}
.cb-widget .cb-cta-card.cb-vertical-card a h3,
.cb-widget .cb-cta-card.cb-vertical-card a h4 {
  color: #505050;
  margin-bottom: 0;
}
.cb-widget .cb-cta-card.cb-vertical-card a:hover {
  color: #505050;
}
.cb-widget .cb-cta-card.cb-vertical-card a:hover h3,
.cb-widget .cb-cta-card.cb-vertical-card a:hover h4 {
  color: #505050;
}
.cb-widget .cb-cta-card.cb-horizontal-card {
  text-align: left;
}
.cb-widget .cb-cta-card.cb-horizontal-card a {
  display: flex;
  align-items: center;
  color: #505050;
}
.cb-widget .cb-cta-card.cb-horizontal-card a h3,
.cb-widget .cb-cta-card.cb-horizontal-card a h4 {
  color: #505050;
  margin-left: 24px;
  font-size: 1.188rem;
  line-height: 24px;
  margin-bottom: 0;
  width: calc(100% - 88px);
}
.cb-widget .cb-cta-card.cb-horizontal-card a:hover {
  color: #505050;
}
.cb-widget .cb-cta-card.cb-horizontal-card a:hover h3,
.cb-widget .cb-cta-card.cb-horizontal-card a:hover h4 {
  color: #505050;
}
.cb-widget .cb-item-list {
  margin-bottom: 48px;
}
.cb-widget .cb-item-list .cb-item-list {
  margin-bottom: 0!important;
}
.cb-widget .cb-item-list ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
}
.cb-widget .cb-item-list ul li {
  padding: 0;
  margin: 0;
  position: relative;
}
.cb-widget .cb-item-list ul li .cb-item-icon {
  line-height: 2em;
}
.cb-widget .cb-item-list ul li .cb-item-icon .cb-glyph {
  float: left;
}
.cb-widget .cb-item-list ul li .cb-item-icon .cb-item-type {
  display: inline;
  margin-left: 12px;
  font-size: 0.875rem;
  line-height: 1.71428571em;
  text-transform: uppercase;
}
.cb-widget .cb-item-list ul li .cb-item-date {
  display: none;
  font-size: 0.875rem;
  line-height: 1.286em;
  text-transform: uppercase;
  padding: 20px 24px 0 24px;
}
.cb-widget .cb-item-list ul li .cb-item-date li {
  width: 99%;
  color: #505050;
}
.cb-widget .cb-item-list ul li .cb-item-meta li {
  color: #505050;
  font-size: 0.75rem;
  line-height: 1.5em;
}
.cb-widget .cb-item-list ul li .cb-item-meta.cb-item-meta-key li:first-of-type {
  border: 1px solid #d9d9d9;
  padding: 0 8px;
}
.cb-widget .cb-item-list ul li .cb-item-meta.cb-item-meta-key li:nth-of-type(2) {
  margin-left: 8px;
}
.cb-widget .cb-item-list ul li .cb-item-meta.cb-item-meta-key li:nth-of-type(2):not(:last-of-type)::after {
  content: '';
  border-right: 1px solid #d9d9d9;
  padding-left: 8px;
  margin-right: 8px;
  display: inline-block;
  width: 1px;
  position: relative;
  top: 1px;
  height: 12px;
}
.cb-widget .cb-item-list ul li .cb-item-meta:not(.cb-item-meta-key) li:not(:last-child)::after {
  content: '';
  border-right: 1px solid #d9d9d9;
  padding-left: 8px;
  margin-right: 8px;
  display: inline-block;
  width: 1px;
  position: relative;
  top: 1px;
  height: 12px;
}
.cb-widget .cb-item-list ul li .cb-item-title {
  font-family: "Roboto Slab Bold", sans-serif;
}
.cb-widget .cb-item-list.cb-item-list-simple {
  background-color: #ffffff;
}
.cb-widget .cb-item-list.cb-item-list-simple li {
  width: 100%;
  border-bottom: solid 1px #d9d9d9;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.cb-widget .cb-item-list.cb-item-list-simple li a {
  position: relative;
  width: calc(100% - 40px);
  display: block;
  top: 1px;
  left: 1px;
  color: #0077c8;
}
.cb-widget .cb-item-list.cb-item-list-simple li a:hover,
.cb-widget .cb-item-list.cb-item-list-simple li a:active {
  color: #0077c8;
}
.cb-widget .cb-item-list.cb-item-list-simple li a::after {
  font-family: 'CB Icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  display: block;
  top: calc(50% - 9px);
  right: -24px;
  color: #0077c8;
  width: 1em;
  height: 1em;
  line-height: 1em;
  content: "\E916";
}
.cb-widget .cb-item-list.cb-item-list-simple li a.cb-target-blank::after {
  content: "\E932";
}
.cb-widget .cb-item-list.cb-item-list-simple li:first-child {
  border-top: solid 4px #505050;
}
.cb-widget .cb-item-list.cb-item-list-simple .cb-item-icon {
  display: none;
}
.cb-widget .cb-item-list.cb-item-list-simple .cb-item-image {
  display: none;
}
.cb-widget .cb-item-list.cb-item-list-simple .cb-item-text {
  padding: 12px 0 11px 16px;
}
.cb-widget .cb-item-list.cb-item-list-simple .cb-item-text .cb-item-title {
  padding: 0;
  margin: 0;
  font-size: 1rem;
  line-height: 1.5em;
  color: #0077c8;
}
.cb-widget .cb-item-list.cb-item-list-simple .cb-item-text .cb-item-des {
  display: none;
}
.cb-widget .cb-item-list.cb-item-list-simple .cb-item-text .cb-item-meta {
  display: none;
}
.cb-widget .cb-item-list.cb-item-list-simple li:first-child .cb-item-text {
  padding-top: 8px;
}
.cb-widget .cb-item-list.cb-item-list-showcase > ul > li {
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  margin: 0 15px 24px;
  width: calc(33.333% - 30px);
  background: #ffffff;
  min-height: 312px;
}
@media (min-width: 1250px) {
  .cb-widget .cb-item-list.cb-item-list-showcase > ul > li {
    width: calc(25% - 30px);
  }
}
@media (max-width: 1023px) and (min-width: 768px) {
  .cb-widget .cb-item-list.cb-item-list-showcase > ul > li {
    width: calc(50% - 20px);
    margin: 0 10px 24px;
    min-height: 336px;
  }
}
@media (max-width: 767px) {
  .cb-widget .cb-item-list.cb-item-list-showcase > ul > li {
    width: calc(100% - 15px);
    margin: 0 7.5px 24px;
    min-height: 0;
  }
}
.cb-widget .cb-item-list.cb-item-list-showcase > ul > li a {
  width: 100%;
  height: 100%;
  display: inherit;
  position: relative;
}
.cb-widget .cb-item-list.cb-item-list-showcase li .cb-item-icon {
  padding: 19px 24px 0 24px;
}
.cb-widget .cb-item-list.cb-item-list-showcase li .cb-item-image {
  margin-top: 20px;
  width: 100%;
  height: 144px;
  overflow: hidden;
}
@media (min-width: 1250px) {
  .cb-widget .cb-item-list.cb-item-list-showcase li .cb-item-image {
    height: 120px;
  }
}
@media (max-width: 1023px) and (min-width: 768px) {
  .cb-widget .cb-item-list.cb-item-list-showcase li .cb-item-image {
    height: 144px;
  }
}
@media (max-width: 767px) {
  .cb-widget .cb-item-list.cb-item-list-showcase li .cb-item-image {
    height: auto;
  }
}
.cb-widget .cb-item-list.cb-item-list-showcase li .cb-item-image img {
  width: 100%;
}
.cb-widget .cb-item-list.cb-item-list-showcase li .cb-item-date li {
  line-height: 24px;
}
.cb-widget .cb-item-list.cb-item-list-showcase li .cb-item-date + .cb-item-text,
.cb-widget .cb-item-list.cb-item-list-showcase li .cb-item-image + .cb-item-text {
  padding-top: 24px;
}
.cb-widget .cb-item-list.cb-item-list-showcase li .cb-item-text {
  padding: 20px 24px 24px 24px;
}
.cb-widget .cb-item-list.cb-item-list-showcase li .cb-item-text .cb-item-title {
  margin: 0;
  font-size: 1.125rem;
  line-height: 1.33333333em;
  color: #505050;
}
.cb-widget .cb-item-list.cb-item-list-showcase li .cb-item-text .cb-item-des {
  margin: 0;
  font-size: 0.875rem;
  line-height: 1.71428571em;
  color: #505050;
}
.cb-widget .cb-item-list.cb-item-list-showcase li .cb-item-meta {
  width: calc(100% - 20px);
  padding: 0 24px 24px 24px;
}
.cb-widget .cb-item-list.cb-item-list-showcase li .cb-item-meta li {
  line-height: 2em;
}
.cb-widget .cb-item-list.cb-item-list-showcase li.cb-type-video .cb-item-image {
  position: relative;
}
.cb-widget .cb-item-list.cb-item-list-showcase li.cb-type-video .cb-item-image .cb-play {
  position: absolute;
  top: calc(50% - 32px);
  left: calc(50% - 32px);
}
.cb-widget .cb-item-list.cb-item-list-showcase li.cb-type-video .cb-item-image .cb-play.cb-glyph-circular::after {
  background-color: #000000;
  opacity: 0.6;
}
.cb-widget .cb-item-list.cb-item-list-showcase li.cb-type-video .cb-item-des {
  display: none;
}
.cb-widget .cb-item-list.cb-item-list-showcase li.cb-type-article a::after {
  font-family: 'CB Icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  display: block;
  bottom: 28px;
  right: 24px;
  color: #009cde;
  width: 1em;
  height: 1em;
  line-height: 1em;
  content: "\E916";
}
.cb-widget .cb-item-list.cb-item-list-showcase li.cb-type-article a.cb-target-blank::after {
  content: "\E932";
}
.cb-widget .cb-item-list.cb-item-list-showcase li.cb-type-article .cb-item-meta {
  width: calc(100% - 66px);
}
.cb-widget .cb-item-list.cb-item-list-showcase li.cb-type-article .cb-item-des {
  display: none;
}
.cb-widget .cb-item-list.cb-item-list-showcase li.cb-type-article .cb-item-meta {
  display: none;
}
.cb-widget .cb-item-list.cb-item-list-showcase li.cb-type-article .cb-item-title {
  margin-bottom: 24px;
}
.cb-widget .cb-item-list.cb-item-list-showcase li.cb-type-event a::after {
  font-family: 'CB Icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  display: block;
  bottom: 28px;
  right: 24px;
  color: #009cde;
  width: 1em;
  height: 1em;
  line-height: 1em;
  content: "\E916";
}
.cb-widget .cb-item-list.cb-item-list-showcase li.cb-type-event a.cb-target-blank::after {
  content: "\E932";
}
.cb-widget .cb-item-list.cb-item-list-showcase li.cb-type-event .cb-item-meta {
  width: calc(100% - 66px);
}
.cb-widget .cb-item-list.cb-item-list-showcase li.cb-type-event .cb-item-date {
  display: block;
}
.cb-widget .cb-item-list.cb-item-list-showcase li.cb-type-event .cb-item-image,
.cb-widget .cb-item-list.cb-item-list-showcase li.cb-type-event .cb-item-des {
  display: none;
}
.cb-widget .cb-item-list.cb-item-list-showcase li.cb-type-resource a::after {
  font-family: 'CB Icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  display: block;
  bottom: 28px;
  right: 24px;
  color: #009cde;
  width: 1em;
  height: 1em;
  line-height: 1em;
  content: "\E914";
}
.cb-widget .cb-item-list.cb-item-list-showcase li.cb-type-resource a.batch-download::after {
  content: "\E902";
}
.cb-widget .cb-item-list.cb-item-list-showcase li.cb-type-resource a.batch-download .cb-item-meta {
  display: none;
}
.cb-widget .cb-item-list.cb-item-list-showcase li.cb-type-resource a.series::after {
  content: "\E943";
}
.cb-widget .cb-item-list.cb-item-list-showcase li.cb-type-resource a.series .cb-item-meta {
  display: none;
}
.cb-widget .cb-item-list.cb-item-list-showcase li.cb-type-resource .cb-item-meta {
  width: calc(100% - 66px);
}
.cb-widget .cb-item-list.cb-item-list-showcase li.cb-type-resource .cb-item-image {
  display: none;
}
.cb-widget .cb-item-list.cb-item-list-showcase li.cb-type-resource .cb-item-text .cb-item-title {
  margin-bottom: 24px;
}
.cb-widget .cb-item-list.cb-item-list-showcase li.cb-no-img .cb-item-image {
  display: none;
}
.cb-widget .cb-item-list.cb-item-list-showcase li.cb-no-des .cb-item-des {
  display: none;
}
.cb-widget .cb-item-list.cb-item-list-showcase li.cb-no-des .cb-item-title {
  font-family: "RobotoSlab-Light", "SerifaStd-Light", sans-serif;
  font-size: 1.4375rem;
  line-height: 1.04347826em;
  font-weight: 300;
}
.cb-widget .cb-item-list.cb-item-list-rich > ul > li {
  border-bottom: 1px solid #d9d9d9;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100%;
  padding: 24px 24px 23px 16px;
  background: #ffffff;
}
.cb-widget .cb-item-list.cb-item-list-rich > ul > li a {
  width: 100%;
  height: 100%;
  display: inherit;
  position: relative;
}
.cb-widget .cb-item-list.cb-item-list-rich > ul > li:first-child,
.cb-widget .cb-item-list.cb-item-list-rich > ul > li.cb-add-top-border {
  border-top: 4px solid #0077c8;
  padding-top: 20px;
}
.cb-widget .cb-item-list.cb-item-list-rich > ul > li .cb-item-icon .cb-item-type {
  display: none;
}
.cb-widget .cb-item-list.cb-item-list-rich > ul > li.cb-batch-download-open {
  padding-bottom: 0;
}
.cb-widget .cb-item-list.cb-item-list-rich .cb-item-text {
  padding: 0;
  width: calc(100% - 32px);
  padding-left: 12px;
  display: inline-block;
}
.cb-widget .cb-item-list.cb-item-list-rich .cb-item-text .cb-item-title {
  margin: 0;
  padding: 0;
  font-size: 1.125rem;
  line-height: 1.33333333em;
  color: #505050;
}
.cb-widget .cb-item-list.cb-item-list-rich .cb-item-text .cb-item-des {
  margin: 0;
  font-size: 0.875rem;
  line-height: 1.71428571em;
  color: #505050;
}
.cb-widget .cb-item-list.cb-item-list-rich .cb-item-meta {
  width: 100%;
  margin-top: 24px;
  padding: 0 0 0 44px;
}
.cb-widget .cb-item-list.cb-item-list-rich .cb-item-meta li {
  line-height: 2em;
}
.cb-widget .cb-item-list.cb-item-list-rich a .cb-item-meta {
  position: relative;
}
.cb-widget .cb-item-list.cb-item-list-rich a .cb-item-meta::after {
  font-family: 'CB Icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  display: block;
  right: 0;
  color: #009cde;
  width: 1em;
  height: 1em;
  line-height: 24px;
  content: "\E916";
}
.cb-widget .cb-item-list.cb-item-list-rich a .cb-target-blank .cb-item-meta::after {
  content: "\E932";
}
.cb-widget .cb-item-list.cb-item-list-rich .cb-type-video .cb-item-meta::after {
  content: "";
}
.cb-widget .cb-item-list.cb-item-list-rich .cb-type-related .cb-item-meta {
  position: relative;
  min-height: 24px;
}
.cb-widget .cb-item-list.cb-item-list-rich .cb-type-related .cb-item-meta::after {
  content: "\E932";
}
.cb-widget .cb-item-list.cb-item-list-rich .cb-type-related .cb-item-meta li.cb-related-site {
  position: absolute;
  right: 28px;
}
.cb-widget .cb-item-list.cb-item-list-rich .cb-type-related .cb-item-meta li:nth-last-child(2)::after {
  border-right: 0;
  padding-left: 0;
  margin-right: 0;
}
.cb-widget .cb-item-list.cb-item-list-rich .cb-type-related .cb-glyph.cb-glyph-circular.cb-north-east::before {
  left: calc((1em / 2) - 1px);
  top: calc((1em / 2) + 1px);
}
.cb-widget .cb-item-list.cb-item-list-rich .cb-type-event .cb-item-date {
  display: inline-block;
  width: calc(100% - 44px);
  padding: 0 0 0 12px;
  margin-bottom: 24px;
}
.cb-widget .cb-item-list.cb-item-list-rich .cb-type-event .cb-item-date li {
  line-height: 24px;
}
.cb-widget .cb-item-list.cb-item-list-rich .cb-type-event .cb-item-text {
  padding-left: 44px;
  display: block;
}
.cb-widget .cb-item-list.cb-item-list-rich .cb-type-resource .cb-item-meta {
  position: relative;
}
.cb-widget .cb-item-list.cb-item-list-rich .cb-type-resource .cb-item-meta::after {
  content: "\E914";
}
.cb-widget .cb-item-list.cb-item-list-rich .cb-type-resource .cb-item-meta.series::after {
  content: "\E943";
}
.cb-widget .cb-item-list.cb-item-list-rich .cb-type-resource .cb-item-meta.batch-download::after {
  content: "\E902";
}
.cb-widget .cb-item-list.cb-item-list-rich .cb-type-resource .cb-batch-resource::before {
  content: '';
}
.cb-widget .cb-item-list.cb-item-list-rich .cb-type-resource .cb-batch-resource .cb-item-download {
  margin-top: 24px;
  padding-left: 44px;
}
.cb-widget .cb-item-list.cb-item-list-rich .cb-type-resource .cb-batch-resource .cb-item-download a {
  font-size: 0.75rem;
  line-height: 2em;
  float: left;
  width: auto;
}
.cb-widget .cb-item-list.cb-item-list-rich .cb-type-resource .cb-batch-resource .cb-item-download a.cb-see-more {
  float: right;
}
.cb-widget .cb-item-list.cb-item-list-rich .cb-type-resource .cb-batch-resource .cb-item-download a.cb-see-more.open::after {
  content: "See Less -";
}
.cb-widget .cb-item-list.cb-item-list-rich .cb-type-resource .cb-batch-resource .cb-item-download a.cb-see-more::after {
  content: "See More +";
}
.cb-widget .cb-item-list.cb-item-list-rich .cb-type-resource .cb-batch-resource .cb-item-list.cb-item-list-rich {
  width: calc(100% + 40px);
  float: left;
  border-top: solid 1px #d9d9d9;
  margin-left: -16px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.cb-widget .cb-item-list.cb-item-list-rich .cb-type-resource .cb-batch-resource .cb-item-list.cb-item-list-rich > ul > li {
  background-color: #f0f0f0;
  padding-left: 24px;
}
.cb-widget .cb-item-list.cb-item-list-rich .cb-type-resource .cb-batch-resource .cb-item-list.cb-item-list-rich > ul > li:first-child {
  border-top: 0;
  padding-top: 24px;
}
.cb-widget .cb-item-list.cb-item-list-rich .cb-type-resource .cb-batch-resource .cb-item-list.cb-item-list-rich > ul > li:last-child {
  border-bottom: 0;
}
.cb-widget .cb-item-list.cb-item-list-rich .cb-type-resource .cb-batch-resource .cb-item-list.cb-item-list-rich > ul > li .cb-item-meta::after {
  content: "\E914";
}
.cb-widget .cb-item-list.cb-item-list-rich .cb-type-resource .cb-batch-resource .cb-item-list.cb-item-list-rich.collapse {
  display: none;
  visibility: hidden;
}
.cb-widget .cb-item-list.cb-item-list-rich .cb-type-resource .cb-batch-resource .cb-item-list.cb-item-list-rich.collapse.in {
  display: block;
  visibility: visible;
}
.cb-widget .cb-item-list.cb-item-list-rich .cb-type-resource .cb-batch-resource .cb-item-list.cb-item-list-rich.collapse.in a:hover,
.cb-widget .cb-item-list.cb-item-list-rich .cb-type-resource .cb-batch-resource .cb-item-list.cb-item-list-rich.collapse.in a:active {
  text-decoration: none;
}
.cb-widget .cb-item-list.cb-item-list-rich.cb-border-top-base > ul > li:first-child,
.cb-widget .cb-item-list.cb-item-list-rich.cb-border-top-base > ul > li.cb-add-top-border {
  border-top: 4px solid #505050;
}
.cb-widget .cb-item-list.cb-item-list-rich.cb-item-list-condensed .cb-type-event .cb-item-date {
  margin-bottom: 0;
}
.cb-widget .cb-item-list a:hover,
.cb-widget .cb-item-list a:active,
.cb-widget .cb-item-list a:focus {
  text-decoration: none;
}
.cb-widget .cb-item-list a:hover .cb-item-text,
.cb-widget .cb-item-list a:active .cb-item-text,
.cb-widget .cb-item-list a:focus .cb-item-text {
  color: #505050;
}
.cb-widget .cb-item-list a:hover .cb-item-text .cb-item-title,
.cb-widget .cb-item-list a:active .cb-item-text .cb-item-title,
.cb-widget .cb-item-list a:focus .cb-item-text .cb-item-title {
  text-decoration: underline;
}
.cb-widget .cb-item-list .cb-glyph-circular::after {
  background-color: #505050;
}
.cb-widget .cb-item-list .cb-batch-resource .cb-glyph-circular::after {
  background-color: #009cde;
}
.cb-widget .cb-item-list a .cb-glyph-circular::after {
  background-color: #009cde;
}
.cb-widget .cb-item-list-modal .modal-header {
  padding-bottom: 0;
}
.cb-widget .cb-item-list-modal .modal-body {
  padding-top: 0;
}
.cb-widget .cb-item-list-modal .modal-content button.close {
  float: left;
}
.cb-widget :lang(es) .cb-see-more .panel .panel-heading a.collapsed::after {
  content: 'Ver m\E1s +';
}
.cb-widget :lang(es) .cb-see-more .panel .panel-heading a.cb-accordion-show::after {
  content: 'Ver menos -';
}
.cb-widget :lang(es) .cb-item-list.cb-item-list-rich .cb-type-resource .cb-batch-resource .cb-item-download a.cb-see-more {
  float: right;
}
.cb-widget :lang(es) .cb-item-list.cb-item-list-rich .cb-type-resource .cb-batch-resource .cb-item-download a.cb-see-more.open::after {
  content: 'Ver menos -';
}
.cb-widget :lang(es) .cb-item-list.cb-item-list-rich .cb-type-resource .cb-batch-resource .cb-item-download a.cb-see-more::after {
  content: 'Ver m\E1s +';
}
.cb-widget :lang(es) .cb-loader.cb-loader-lg::after {
  content: "cargando";
}
.cb-widget :lang(es) .cb-resource.cb-resource-bulk-detail .cb-resource-download .cb-resource-see-more.open::after {
  content: 'Ver menos -';
}
.cb-widget :lang(es) .cb-resource.cb-resource-bulk-detail .cb-resource-download .cb-resource-see-more::after {
  content: 'Ver m\E1s +';
}
.cb-widget .btn-group,
.cb-widget .btn-group-vertical {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.cb-widget .btn-group > .btn,
.cb-widget .btn-group-vertical > .btn {
  position: relative;
  float: left;
}
.cb-widget .btn-group > .btn:hover,
.cb-widget .btn-group-vertical > .btn:hover,
.cb-widget .btn-group > .btn:focus,
.cb-widget .btn-group-vertical > .btn:focus,
.cb-widget .btn-group > .btn:active,
.cb-widget .btn-group-vertical > .btn:active,
.cb-widget .btn-group > .btn.active,
.cb-widget .btn-group-vertical > .btn.active {
  z-index: 2;
}
.cb-widget .btn-group .btn + .btn,
.cb-widget .btn-group .btn + .btn-group,
.cb-widget .btn-group .btn-group + .btn,
.cb-widget .btn-group .btn-group + .btn-group {
  margin-left: -1px;
}
.cb-widget .btn-toolbar {
  margin-left: -5px;
}
.cb-widget .btn-toolbar .btn,
.cb-widget .btn-toolbar .btn-group,
.cb-widget .btn-toolbar .input-group {
  float: left;
}
.cb-widget .btn-toolbar > .btn,
.cb-widget .btn-toolbar > .btn-group,
.cb-widget .btn-toolbar > .input-group {
  margin-left: 5px;
}
.cb-widget .btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0;
}
.cb-widget .btn-group > .btn:first-child {
  margin-left: 0;
}
.cb-widget .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.cb-widget .btn-group > .btn:last-child:not(:first-child),
.cb-widget .btn-group > .dropdown-toggle:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.cb-widget .btn-group > .btn-group {
  float: left;
}
.cb-widget .btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}
.cb-widget .btn-group > .btn-group:first-child:not(:last-child) > .btn:last-child,
.cb-widget .btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.cb-widget .btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.cb-widget .btn-group .dropdown-toggle:active,
.cb-widget .btn-group.open .dropdown-toggle {
  outline: 0;
}
.cb-widget .btn-group > .btn + .dropdown-toggle {
  padding-left: 8px;
  padding-right: 8px;
}
.cb-widget .btn-group > .btn-lg + .dropdown-toggle {
  padding-left: 12px;
  padding-right: 12px;
}
.cb-widget .btn-group.open .dropdown-toggle {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.cb-widget .btn-group.open .dropdown-toggle.btn-link {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.cb-widget .btn .caret {
  margin-left: 0;
}
.cb-widget .btn-lg .caret {
  border-width: 5px 5px 0;
  border-bottom-width: 0;
}
.cb-widget .dropup .btn-lg .caret {
  border-width: 0 5px 5px;
}
.cb-widget .btn-group-vertical > .btn,
.cb-widget .btn-group-vertical > .btn-group,
.cb-widget .btn-group-vertical > .btn-group > .btn {
  display: block;
  float: none;
  width: 100%;
  max-width: 100%;
}
.cb-widget .btn-group-vertical > .btn-group > .btn {
  float: none;
}
.cb-widget .btn-group-vertical > .btn + .btn,
.cb-widget .btn-group-vertical > .btn + .btn-group,
.cb-widget .btn-group-vertical > .btn-group + .btn,
.cb-widget .btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}
.cb-widget .btn-group-vertical > .btn:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.cb-widget .btn-group-vertical > .btn:first-child:not(:last-child) {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.cb-widget .btn-group-vertical > .btn:last-child:not(:first-child) {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.cb-widget .btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}
.cb-widget .btn-group-vertical > .btn-group:first-child:not(:last-child) > .btn:last-child,
.cb-widget .btn-group-vertical > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.cb-widget .btn-group-vertical > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.cb-widget .btn-group-justified {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
}
.cb-widget .btn-group-justified > .btn,
.cb-widget .btn-group-justified > .btn-group {
  float: none;
  display: table-cell;
  width: 1%;
}
.cb-widget .btn-group-justified > .btn-group .btn {
  width: 100%;
}
.cb-widget .btn-group-justified > .btn-group .dropdown-menu {
  left: auto;
}
.cb-widget [data-toggle="buttons"] > .btn input[type="radio"],
.cb-widget [data-toggle="buttons"] > .btn-group > .btn input[type="radio"],
.cb-widget [data-toggle="buttons"] > .btn input[type="checkbox"],
.cb-widget [data-toggle="buttons"] > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
